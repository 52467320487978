// import React from "react";
// import Deactivate from "../deactivate/Deactivate";

export const profileConstants = {
    PERSONAL_INFO_SECTION_ADOBE_KEY: 'PersonalInfo',
    COMPANY_INFO_SECTION_ADOBE_KEY: 'CompanyInfo',
    EMAIL_AND_PASSWORD_INFO_ADOBE_KEY: 'EmailAndPasswordInfo',
    PERSONAL_INFO: "personalInfo",
    COMPANY_INFO: "companyInfo",
    PASSWORD_INFO: "passwordInfo",
    EMAIL_INFO: "emailInfo",
    LIGHT_USER_ORG: 9999,
    PERSONAL_BLOCK_HEADING: 'Personal information',
    COMPANY_BLOCK_HEADING: 'Company information',
    PASSWORD_BLOCK_HEADING: 'Change password',
    EMAIL_BLOCK_HEADING: 'Email',
    FIELD_ID: {
        FORGOT_PASSWORD: 'Forgot password?',
        CURRENT_PWD: 'currentPassword',
        NEW_PWD: 'newPassword',
        VERIFY_PWD: 'verifyPassword',

        FIRST_NAME: 'firstName',
        LAST_NAME: 'lastName',
        PHONE_NUMBERS: 'phoneNumbers(s)',
        MOBILE: 'mobile:',
        OFFICE: 'office:',
        JOB_TITLE: 'jobTitle',
        JOB_FUNCTION: 'jobFunction',
        FORUM_ALIAS: 'forumAlias',

        COMPANY_UNIVERSITY: 'companyOrUniversity',
        STREET_ADD_1: 'streetAddress1',
        STREET_ADD_2: 'streetAddress2',
        COUNTRY: 'Country',
        CITY: 'City',
        STATE_PROVINCE_REGION: 'state',
        POSTAL_CODE: 'postalCode',
        CUSTOMER_ID: 'customerID'
    },
    FIELD_LABELS: {
        EMAIL: 'Your email',
        FORGOT_PASSWORD: 'Forgot password?',

        CURRENT_PWD: 'Current password',
        NEW_PWD: 'New password',
        VERIFY_PWD: 'Verify password',

        FIRST_NAME: 'First name',
        LAST_NAME: 'Last name',
        PHONE_NUMBERS: 'Phone numbers(s)',
        MOBILE: 'Mobile:',
        OFFICE: 'Office:',
        JOB_TITLE: 'Job title',
        JOB_FUNCTION: 'Job function',
        FORUM_ALIAS: 'Forum alias',

        COMPANY_UNIVERSITY: 'Company or university',
        STREET_ADD_1: 'Street address 1',
        STREET_ADD_2: 'Street address 2',
        COUNTRY: 'Country or Location',
        CITY: 'City',
        STATE_PROVINCE_REGION: 'State / province / region',
        POSTAL_CODE: 'Postal code',
        CUSTOMER_ID: 'Customer ID',
        EXPORT_ID: 'Export ID'
    },
    REGEX: {
        FIRST_LAST_NAME: /^[a-zA-Z0-9- "()']*$/,
        PHONE_NUMBER: /^[a-zA-Z0-9 +\-()#:]*$/,
        ADDRESS: /^[a-zA-Z0-9 ,.\-()#&:;/]*$/,
        STATE_OR_REGION: /^[a-zA-Z0-9 ,.\-()'/]*$/,
        CITY: /^[a-zA-Z0-9 ,.\-()'/]*$/,
        POSTAL_CODE: /^[a-zA-Z0-9 ,.\-/]*$/,
        COMPANY: /^[a-zA-Z0-9 ,.\-()'/&]*$/,
        JOB_TITLE_FUNCTION: /^[a-zA-Z0-9 ,.\-()'/&]*$/,
        FORUM_ALIAS: /^[a-zA-Z0-9-_.]*$/,
        INITIAL_CHECK: /^[^\s]{2,}?([\s]*[^\s]{2,}?)*$/,
    },
    SYSTEM_ERROR: "Problem processing request",
    RESPONSIVE_PROFILE_DEFAULT_PATH: 'profile',
    APP_TABS_STATE: {
        PROFILE_TAB: {
            LABEL: 'Profile',
            GLYPH: 'profile',
            TAB_PATH: 'profile',
            MOBILE_VIEW: {
                DESK_PATH: 'profile',
                PF_PF_EMAIL: 'pf_pf_email',
                PF_PF_PERSONAL: 'pf_pf_personal',
                PF_PF_COMPANY: 'pf_pf_company',
                PF_PF_PASSWORD: 'pf_pf_password',
                PF_PF_EMAIL_AND_PASSWORD: 'pf_pf_email_and_password',
            }
        },
        PREFERENCES_TAB: {
            LABEL: 'Preferences',
            GLYPH: 'preferences',
            TAB_PATH: 'preferences',
            MOBILE_VIEW: {
                DESK_PATH: 'preferences',
                PF_PREFERENCES_SUBSCR: 'pf_pref_sub',
                PF_PREFERENCES_APP_AREA: 'pf_pref_appArea',
            }
        },
        DEACTIVATE_TAB: {
            LABEL: 'Deactivate',
            GLYPH: 'error',
            TAB_PATH: 'deactivate',
            MOBILE_VIEW: {
                DESK_PATH: 'deactivate',
                PF_DEACTIVATE: 'pf_deactivate'
            }
        },
    },
    minLengthMsg: (field, length) => {
        // return `${field} Should be ${length} Characters`
        return `Please supply at least ${length} characters`
    },
    minLengthIfValueExistMsg: (field, length) => {
        return `Please supply at least ${length} characters`
    },
    maxLengthMsg: (field, length) => {
        return `Please supply at most ${length} characters`
    },
    alphaNumericMsg: (field) => {
        return `${field} Should be alphanumeric`
    },
    isNotValidEmail: (field) => {
        return `Please supply a valid email address`
    },
    isNotEmptyMsg: (field) => {
        return `Please enter value for ${field}`
    },
    isValidPasswordMsg: (field) => {
        return `${field} contains invalid character`
    },
    isValidPasswordMsg2: (field) => {
        return `${field} does not meet specified rules`
    },
    passwordMatchMsg: 'New and Verify Passwords do not match',
    initialCheckMsg: (fields) => {
        return `You have entered a single initial. Please supply your full name`
    },
    regexMsg: (field) => {
        return `You have entered an invalid character`
    },
    termsMsg: () => {
        return `Please agree to the Terms of Use`
    },
    internalDomainMsg: (field) => {
        return `Qualcomm employees please contact ${process.env.REACT_APP_SUPPORT_EMAIL} for access`
    },
};




