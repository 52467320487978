export const breakPointValue = {
    mobile: 375,
    tablet: 550,
    laptop: 950,
    desktop: 1200,
    jumbo: 1440,
    xs: 200,
};

const breakpoints = {
    mobile: '375px',
    tablet: '550px',
    laptop: '950px',
    desktop: '1200px',
    jumbo: '1440px',
    xs:'200px'
};


export const device = {
    mobile: `(min-width: ${breakpoints.mobile})`,
    tablet: `(min-width: ${breakpoints.tablet})`,
    laptop: `(min-width: ${breakpoints.laptop})`,
    desktop: `(min-width: ${breakpoints.desktop})`,
    jumbo: `(min-width: ${breakpoints.jumbo})`,
    xs: `(min-width: ${breakpoints.xs})`,
};