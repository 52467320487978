import React, {useContext, useEffect} from 'react';
import {Cta, SelectComponent, Text} from '@qc-modules/components';
import {
    ComponentTitle,
    defaultParagraphStyle, isValidImpersonation,
    PanelHeader
} from "../util/helper";
import styled from 'styled-components';
import {useMediaQuery} from "react-responsive";
import {getSupportEmail} from '../../utils/Utils';
import {useDeactivate} from "../../hooks/useDeactivate";
import AppContext from "../../context/AppContext";
import {device} from "../../lib/breakpoints";
// import usePreviousPage from "../../hooks/usePreviousPage";

const StyledCta = styled(Cta)`
    align-self: flex-start;
    margin-top: 40px;
    ${({disabled}) => {
        if (disabled) {
            return `opacity: 0.6; cursor: default;`
        }
        return `&:hover {color: #1C37A5}; cursor: pointer;`
    }}
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledHeading = styled(Text)`
    font-size: 24px;
    line-height: 1.125;
    margin: 0 0 8px;
`;

const Deactivate = React.memo((props) => {

    const store = props.appStore ? useDeactivate() : (props.store ? props.store : useDeactivate());
    const userData = props.userData
    const {appStore} = useContext(AppContext);
    const isLaptop = useMediaQuery({query: device.laptop});
    // const previousPage = usePreviousPage();
    const impersonationStatus = appStore.impersonationStatus
    const previousPage = '/login';

    useEffect(() => {
        const visitor = {
            'country_code': userData.country_code,
            'org_id': userData.org.orgId,
            'org_name': userData.org.name,
            'qcguid': userData.qcGuid,
            'user_name': userData.username,
            'loggedIn': "logged_in",
            'user_domain': userData.username && userData.username.indexOf("@") > -1 ? userData.username.slice(userData.username.indexOf("@") + 1) : 'qualcomm.com'
        }
        if (isValidImpersonation(impersonationStatus)) {
            visitor['impersonating'] = true
            visitor['impersonator'] = impersonationStatus.impersonator
        }


        // trackPageLoadEvent('profile', 'Deactivate', previousPage, visitor, {})
        window.pageChange('profile', 'Deactivate', previousPage, visitor, {})

        // trackFormEvent('form view', 'Deactivate', 1)

    }, [userData, previousPage, impersonationStatus]);

    return (
        <StyledContainer>
            <PanelHeader
                hideControls={true}
                appStore={appStore} isLaptop={isLaptop} label={<ComponentTitle text={'Deactivate your Account'}/>}/>
            <StyledHeading as="p">What happens when you deactivate your Qualcomm ID?</StyledHeading>
            <Text as="p" {...defaultParagraphStyle}>When your Qualcomm ID is deactivated, your settings will be saved on
                our
                systems.</Text>
            <StyledHeading as="p">You can reactivate your Qualcomm ID at any time.</StyledHeading>
            <Text as="p" {...defaultParagraphStyle}>To reactivate your Qualcomm ID, simply sign in and reset your
                password. You
                can also <a
                    href={`mailto:${getSupportEmail()}`}>contact our support team</a> for help.</Text>
            <SelectComponent label={"Why you're leaving"}
                             labelPosition={'placeholder'}
                             options={store.deactivateReasons}
                             width={500}
                             required={true}
                             onChange={store.handleChange}
                             value={store.value}
                             success={store.success}
                             error={store.error}
            >

            </SelectComponent>

            <StyledCta disabled={!store.success || isValidImpersonation(appStore.impersonationStatus)}
                       onClick={store.handleSubmit}>
                Deactivate Account
            </StyledCta>

        </StyledContainer>
    )
});

export default Deactivate;