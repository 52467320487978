import React, {useContext} from 'react';
import {a, Box, Flex, Text, TooltipComponent} from '@qc-modules/components';
import {
    ComponentTitle,
    defaultValueFont,
    ErrorText,
    HtmlField,
    LinkBox,
    PanelHeader,
    PrepareFormElements,
} from '../util/helper'
import styled from 'styled-components';
import {getSupportEmail} from '../../utils/Utils';
import {device} from "../../lib/breakpoints";
import {useMediaQuery} from "react-responsive";
import AppContext from "../../context/AppContext";


export const emptyCheck = (value) => {
    return value || '\u2013';
}
export const defaultLabelFont = {
    f: 3,
    lineHeight: 2
};
const ProfileDescription = styled(Box)`
   line-height: 1.5;
   font-weight: 400;
   margin-top: 32px;
   
   @media ${device.laptop} {
        padding: 0;
    }
`;

export const ProfileDataRowFlex = styled(Flex)`
    flex-direction:column;
    margin-top: 32px;
    justify-content: space-around;
    
    
    @media ${device.laptop} {
        flex-direction: row;
        padding: 0;
    }
`;
const ProfileUsageLink = styled(Flex)`
    flex-direction:column;
    align-items: baseline;
    
    @media ${device.laptop} {
        flex-direction: row;
        padding: 0;
    }
`;

export const ProfileMultiRowFlex = styled(Flex)`
    justify-content: space-around;
    margin-top: 25px;
    flex-direction:column;
`;

export const SubTextRow = styled(Text)`
   font-style: italic;
   margin-left: 48px;
   color: #677284;
   
   @media ${device.laptop} {
        text-align: center;
    }
   
    &:after {
    color: #677284;
    content: ${(props) => props.required ? "'*'" : ''}
  }
`;
export const TextRow = styled(Text)`
    color: #677284;
    &:after {
    color: #677284;
    content: ${(props) => props.required ? "'*'" : ''}
    }
  }
`;

const HelpText = styled(Box)`
font-size: 12px;
 color: #bbc0c8;
`;

export const StyledText = styled(Text)`
    word-wrap: break-word;
    color: #222222;
`;

export const StyledCompanyChangeHeaderText = styled(Text)`
  height: 48px;
  width: 504px;
  color: #E11529;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 24px;
`;

export const StyledCompanyChangeText = styled.li`
  color: #E11529;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 19px;
`;

export const StyledCompanyChangeList = styled.ul`
  color: #E11529;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 19px;
  margin-left: 17px;
  margin-top: 10px;
`;
export const ProfileEmailDescription = () => {
    return (
        <ProfileDescription {...defaultValueFont}>
            Your email is taken at registration and cannot be changed. Your
            email is your username. If you need to change your email, please <a
            href={`mailto:${getSupportEmail()}`}>contact our support team</a>.
        </ProfileDescription>
    )
};

export const ProfileAccountDescription = (props) => {
    return (
        <ProfileDescription {...defaultValueFont} {...props}>
        </ProfileDescription>
    )
};


export const ProfileDataRow = (props) => {
    // const {actionCallBack,isToolTip,isMultiRow,value,errorCallBack, isError,component,visible,isFieldEditable,htmlTag,handleChange,...rest} = props
    const HelpTextComponent = props.helpTextComponent;


    return (
        <ProfileDataRowFlex justify="space-around" mt={25} align={'baseline'}>
            <Box width={0.45}>
                {
                    props.isMultiRow ?
                        <SubTextRow as={"p"} {...defaultLabelFont}
                                    required={props.requiredAsterisk}>{props.label}
                        </SubTextRow> :
                        <TextRow align={'left'} {...defaultLabelFont}
                                 required={props.requiredAsterisk}>{props.label}</TextRow>
                }

            </Box>

            <Box direction={'column'} align={'baseline'} width={0.55}>

                {!props.edit ? (
                    <Box>
                        <StyledText data-testid={`${props.name}-label`} {...defaultValueFont}>
                            {(props.value) ? props.value : '\u2013'}
                        </StyledText>

                    </Box>
                ) : (
                    <Box>
                        <HtmlField {...props}/>
                        {HelpTextComponent && <HelpTextComponent></HelpTextComponent>}
                        {
                            props.errorMessages && props.errorMessages.showErrors && props.errorMessages[props.name] &&
                            <Box>{props.errorMessages[props.name].map(err => (
                                <ErrorText>{err}</ErrorText>)
                            )}</Box>
                        }

                    </Box>
                )
                }
            </Box>
        </ProfileDataRowFlex>
    )
};


export const CustomerIdRow = (props) => {
    return (
        <ProfileDataRowFlex justify="space-around" mt={25} align={'baseline'}>
            <Box width={0.45}>
                {
                    props.isMultiRow ?
                        <SubTextRow {...defaultLabelFont}
                                    required={props.requiredAsterisk}>{props.label}
                        </SubTextRow> :
                        <TextRow align={'left'} {...defaultLabelFont} required={props.requiredAsterisk}
                                 mr={2}>{props.label}</TextRow>
                }

            </Box>
            <Box direction={'column'} ailgn={'baseline'} width={0.55}>
                <Flex row align={'center'}>
                    <Text {...defaultValueFont}>
                        {
                            (props.isLightUser) ?
                                (<Box> You do not have a Customer ID
                                    {props.edit &&
                                    <TooltipComponent
                                        isLarge={true}>
                                        {'A Qualcomm Customer ID is a unique identifier we use to align our systems to your company. If you have interacted with our Sales or Support teams in the past, you are likely to have a Qualcomm Customer ID.'}
                                    </TooltipComponent>
                                    }
                                </Box>)
                                : (props.value)
                        }
                    </Text>
                </Flex>
            </Box>
        </ProfileDataRowFlex>
    )
};

export const ExportIdRow = (props) => {
    return (
        <ProfileDataRowFlex justify="space-around" mt={25} align={'baseline'}>
            <Box width={0.45}>
                {
                    props.isMultiRow ?
                        <SubTextRow {...defaultLabelFont}
                                    required={props.requiredAsterisk}>{props.label}
                        </SubTextRow> :
                        <TextRow align={'left'} {...defaultLabelFont} required={props.requiredAsterisk}
                                 mr={2}>{props.label}</TextRow>
                }

            </Box>
            <Box direction={'column'} ailgn={'baseline'} width={0.55}>
                <Flex row align={'center'}>
                    <Text {...defaultValueFont}>
                        {
                            (props.isLightUser) ?
                                (<Box> You do not have a Export ID
                                    {props.edit &&
                                    <TooltipComponent
                                        isLarge={true}>
                                        {'A Qualcomm Export ID is a unique identifier we use to align our systems to your company. If you have interacted with our Sales or Support teams in the past, you are likely to have a Qualcomm Export ID.'}
                                    </TooltipComponent>
                                    }
                                </Box>)
                                : (props.value)
                        }
                    </Text>
                </Flex>
            </Box>
        </ProfileDataRowFlex>
    )
};

export const ProfileHeaderRow = (props) => {

    return (
        <ComponentTitle text={props.label}>
        </ComponentTitle>
    )
};


export const ProfileMultiPhoneNumber = (props) => {
    return (
        <ProfileMultiRowFlex justify="space-around" mt={25}>
            <Box mr={2} width={3.5 / 8}>
                <Text {...defaultLabelFont} mr={2}>{props.label}</Text>
            </Box>
            <Box>
                {
                    <PrepareFormElements fields={props.children} isEditPage={props.edit}/>
                }
            </Box>

            <Box m={0} width={1 / 8}/>
        </ProfileMultiRowFlex>
    )
};
export const ProfileFormControls = (props) => {
    const isLaptop = useMediaQuery({query: device.laptop});
    const context = useContext(AppContext);
    let appStore;
    if (context) {
        appStore = context.appStore;
    }
    return (
        <Box>
            <Box direction={'column'} mt={20} mb={10}>

                <PanelHeader
                    handleEditButton={() => props.handleEditButton(!(props.isFormInEditState))}
                    handleSaveButton={(event) => props.handleSubmit(event)}
                    handleCancelButton={(event) => props.handleEditButton(!(props.isFormInEditState))}
                    hideControls={props.hideControls}
                    isEdit={props.isFormInEditState}
                    appStore={appStore} isLaptop={isLaptop} {...props}/>

            </Box>
        </Box>
    )
};
export const UsageLink = (props) => {
    return (
        <ProfileUsageLink align='center' mt={20} mb={10}>
            <Box width={0.45}></Box>
            <LinkBox width={0.55} to={props.url}> {props.content}</LinkBox>
        </ProfileUsageLink>
    )
};

export const PasswordHelpText = () => {
    return (
        <HelpText tag={'span'}>
            Password must be at least 8 characters, contain at least one uppercase letter, contain at least one
            lowercase letter and at least one number.
        </HelpText>
    )
}

