import {Checkbox, Flex, Text} from "@qc-modules/components";
import React, {useContext, useEffect, useState} from "react";
import {savePreferences} from "../../api/profile-api";
import {PreferencesConfig} from "../util/appConstants2";
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import {device} from "../../lib/breakpoints";
import AppContext from "../../context/AppContext";
import {defaultParagraphStyle, defaultTextHeaderStyle, PanelHeader} from "../util/helper";
import {colors, themeDarken} from "@qc-modules/styles";
import Loading from "../util/Loading";
import {trackCtaEvent} from "../../utils/Utils";


const NoteLink = styled.a`
    text-decoration: underline;
    color: ${props => colors("body-grey", props)};

    &:visited {
        color: ${props => colors("body-grey", props)};
        text-decoration: none;
    }

    &:hover {
        color: ${themeDarken(0.15, "body-grey")};
    }
`;
const StyledNoteCheckbox = styled(Checkbox)`
    margin-bottom: 32px;
    font-size: 12px;
`;

const defaultNoteCheckboxStyle = {
    mb: 50,
    f: 9
};


const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

const CheckboxWrapper = ({item, changeHandler, checkedProperty, disabledProperty}) => {
    return (
        <StyledCheckbox
            key={item.id}
            checkBoxWidth={15}
            isChecked={checkedProperty}
            disabled={disabledProperty || false}
            label={item.title}
            checkboxOnChange={changeHandler}
            value={item.marketo}
        />
    );
};

const StyledCheckbox = styled(Checkbox)`
    margin-bottom: 16px;
    color: "body-grey",
`;

const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledSubSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const PreferencesHeading = (props) => {
    const isLaptop = useMediaQuery({query: device.laptop});
    const {appStore} = useContext(AppContext);
    return (
        <StyledSection>
            <PanelHeader
                handleEditButton={() => props.handleToggleEdit(!isLaptop ? true : props.type)}
                handleSaveButton={(event) => props.handleSave(props.type)}
                handleCancelButton={() => props.handleToggleEdit(!isLaptop ? false : '')}
                handleReset={() => props.handleReset()}
                disableEdit={props.disableEdit}
                disableSave={props.disableSave}
                isEdit={true}
                label={props.content.heading} appStore={appStore} isLaptop={isLaptop} {...props}
                section={'editQwaSub'}/>
            <Text {...defaultParagraphStyle} as="p">{props.content.descr}</Text>
        </StyledSection>
    )
};


export const PreferencesData = (props) => {

    const viewItems = props.selectedItems && props.selectedItems.filter(i => !!i.checked);
    return (
        <>
            {!props.isEdit &&
                <StyledSubSection align={'top'} width={1}>
                    <Text {...defaultTextHeaderStyle} width={1 / 3}>{props.content.subheading}</Text>
                    <Text {...defaultParagraphStyle} mt={0} width={2 / 3} color={'#222222'}>


                        {viewItems && viewItems.length ? ((viewItems.map(ob => ob.title)).join(', ')) : '\u2013'}
                    </Text>
                </StyledSubSection>
            }
            {props.isEdit &&
                <StyledSubSection row width={1}>
                    <CheckboxContainer>
                        {props.selectedItems.filter(ob => ob.row === 0).map(item => (
                            <CheckboxWrapper
                                key={item.id}
                                item={item}
                                disabledProperty={props.type === PreferencesConfig.newsLetters && !props.areaOfInterestOptIn}
                                changeHandler={() => props.toggleCheckbox(item)}
                                checkedProperty={item.checked}/>
                        ))}
                    </CheckboxContainer>
                    <CheckboxContainer>
                        {props.selectedItems.filter(ob => ob.row === 1).map(item => (
                            <CheckboxWrapper
                                key={item.id}
                                item={item}
                                disabledProperty={props.type === PreferencesConfig.newsLetters && !props.areaOfInterestOptIn}
                                changeHandler={() => props.toggleCheckbox(item)}
                                checkedProperty={item.checked}/>
                        ))}
                    </CheckboxContainer>


                </StyledSubSection>
            }
        </>
    )
};
const checkboxLabel = () => {
    return (
        <Text as="p">
            As per your rights outlined in our <NoteLink href='https://www.qualcomm.com/site/privacy' target='_blank'
                                                         rel='noopener noreferrer'>privacy policy</NoteLink>,
            please fill out this <NoteLink href='https://www.qualcomm.com/site/access-request/contact' target='_blank'
                                           rel='noopener noreferrer'>form</NoteLink> for requests pertaining to web
            tracking and cookie management, data export and transfer, data breach notification, and right to data
            erasure.
        </Text>
    )
};

export const ViewEditPreferences = (props) => {
    return (
        <StyledSection>
            <PreferencesHeading {...props}/>
            {props.type === PreferencesConfig.newsLetters &&
                props.isEdit &&
                <StyledNoteCheckbox {...defaultNoteCheckboxStyle}
                                    label={checkboxLabel()}
                                    isChecked={props.areaOfInterestOptIn}
                                    checkboxOnChange={props.notesChange}/>}
            <PreferencesData {...props}/>
        </StyledSection>
    )
};


export const PreferencesSection = ({
                                       toggleEdit,
                                       disableSave,
                                       disableEdit,
                                       selectedApiData,
                                       refetch,
                                       isEdit,
                                       type,
                                       areaOfInterestOptIn,
                                       dataUpdatedAt,
                                       userData
                                   }) => {
        const [selectedItems, setSelectedItems] = useState([])
        const [isOptIn, setIsOptIn] = useState(areaOfInterestOptIn)
        const [isLoading, setIsLoading] = useState(false)

        // const previousPage = usePreviousPage();
        useEffect(() => {
            setSelectedItems(selectedApiData)
        }, [dataUpdatedAt, type, selectedApiData]);


        useEffect(() => {
            setIsOptIn(areaOfInterestOptIn)
        }, [areaOfInterestOptIn])

        const toggleCheckbox = (event) => {
            // console.log({event})
            const updatedItems = selectedItems.map((item) =>
                item.id === event.id ? {...item, checked: !item.checked} : item
            )
            setSelectedItems(updatedItems)
        };

        const save = async () => {
            setIsLoading(true)
            let data = selectedItems && selectedItems.filter(ob => !!ob.checked).map(ob => ob.id);
            let payload = {[type]: ""};
            if (type === PreferencesConfig.newsLetters) {
                payload = {areaOfInterestOptIn: isOptIn}
            }
            if (data && data.length) {
                payload = {...payload, [type]: data.join(',')}
            }
            try {
                trackCtaEvent('/www-preferences', 'Newsletters Preferences')
                await savePreferences(payload);
                // trackEventAdobe({'preferences_updated': 'preferences_updated'})

                // trackFormEvent('form submit', 'PreferenceSection', PreferencesConfig[type]['adobeType'], 'Profile')

            } catch (e) {
                // trackFormEvent('form submit error', 'PreferenceSection', e.message)
            }
            setIsLoading(false)
            refetch();
            toggleEdit(false)
        };

        const handleToggleEdit = (val) => {
            trackCtaEvent('PreferenceSection', val === '' ? 'Cancel' : 'Edit')
            setSelectedItems(selectedApiData)
            setIsOptIn(areaOfInterestOptIn)
            toggleEdit && toggleEdit(val)
        };
        const handleReset = () => {
            setSelectedItems(selectedApiData)
            setIsOptIn(areaOfInterestOptIn)
            toggleEdit(true)
        };

        const notesChange = () => {
            setSelectedItems(selectedApiData.map(ob => {
                return {...ob, checked: false}
            }));
            setIsOptIn(!isOptIn)
        };

        return <>

            <Flex column>
                {isLoading && <Loading/>}
                <ViewEditPreferences type={type} content={PreferencesConfig[type]} isEdit={isEdit}
                                     selectedItems={selectedItems}
                                     toggleCheckbox={toggleCheckbox}
                                     handleSave={save}
                                     disableEdit={disableEdit}
                                     disableSave={disableSave}
                                     areaOfInterestOptIn={isOptIn}
                                     notesChange={notesChange}
                                     handleReset={handleReset}
                                     handleToggleEdit={handleToggleEdit}/>
            </Flex>
        </>
    }
;
