import React from 'react';
import {ComponentTitle,ComponentParagraphText, StyledCta, SALES_CENTER_CONTACT_LINK} from "../../util/helper";


export const ResponseText = {
    title: 'Access to Qualcomm Sales Center requires additional agreements with Qualcomm Technologies, Inc.',
    text: ['Based on the information you entered, we cannot grant you access to Qualcomm Sales Center at this time.'],
    buttonText: 'Contact sales to request access',
};

const redirectToSalesHelp = () => {
    window.location = SALES_CENTER_CONTACT_LINK
}

// const StyledComponentParagraphText = styled(Text)`
//  font-size: 16px;
//     letter-spacing: .3px;
//     margin-bottom: 30px;
//     margin-top: 33px;
//     line-height: 24px;
//     color: #677283;
//     font-weight: 400;
//     word-wrap: break-word;
//
// `;

const SalesAccessRequestLanding = () => {
    return (
        <>
            <ComponentTitle text={ResponseText.title}/>
            <ComponentParagraphText mb={'60px'} mt={'30px'} tag='p' >{ResponseText.text}</ComponentParagraphText>
            <StyledCta onClick={redirectToSalesHelp}>{ResponseText.buttonText}</StyledCta>
        </>
    )
}

export default SalesAccessRequestLanding;