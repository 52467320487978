import axios from 'axios';
import {apiEndpoint} from "../config";

export const getCountries = async () => {
    let countries;
    const response = await getConfigData('countries');// await axios.get('/api/configData', {params: {data: 'countries'}});
    const data = response && response['countries'];
    countries = data && data.map((country) => ({
        id: country.code,
        value: country.code,
        label: country.name
    }));

    return countries || [];
};

export const getUsStates = async () => {
    let usStates;
    const response = await getConfigData('usStates');//await axios.get('/api/configData', {params: {data: 'countries'}});
    const data = response && response['usStates'];
    usStates = data && data.map((state) => ({
        id: state.code,
        label: state.name,
        value: state.code
    }));
    usStates && usStates.unshift({id: 'selectOne', value: '', label: 'Select One'});

    return usStates || [];
};

export const getConfigData = async (keys) => {
    const response = await axios.get(apiEndpoint.configDataEndpoint, {params: {query: keys}});
    return response && response.data;
};

export const getQnsInfo = async (keys) => {
    const response = await axios.get(apiEndpoint.qnsInfoEndpoint, {params: {query: keys}});
    /*let response = {"qnsFrequencies":[
            {"id":"immediate","name":"immediate"},
            {"id":"monthly","name":"Monthly"},
            {"id":"weekly","name":"Weekly"},
            {"id":"daily","name":"Daily"}],
        "qnsDocTypes":[
            {"name":"Hardware Documents","id":"hwDocs"},
            {"name":"Software Tool Documents","id":"toolDocs"},
            {"name":"Software Code Documents","id":"codeDocs"}],
        "qnsDocClassifications":[
            {"name":"Announcements","id":"announcemnets"},
            {"name":"Data Sheets","id":"dataSheets"},
            {"name":"Process Change Notification","id":"processChange"},
            {"name":"Device Revision Guides","id":"deviceGuides"},
            {"name":"Security Bulletins","id":"securityBulletins"},
            {"name":"Software Release Notes","id":"releaseNotes"},
            {"name":"All Other Document Classifications","id":"otherDocClassifications"}]};*/
    return response && response.data;
    //return response;
}
