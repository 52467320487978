import React from 'react';
import styled from 'styled-components';
import {Flex, Text, Glyph} from '@qc-modules/components';

const DataRow = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const LabelText = styled(Text)`
color: #677283;
font-size: 16px;
`;

const StyledGlyph = styled(Glyph)`
    display: inline;
    height: 16px;
`;


export const ProfileMobileDataRow = ({label, id}) => {
    return (
        <DataRow id={id}>
            <LabelText>{label}</LabelText>
            <StyledGlyph id={'caret-right'}
                   width={'16px'}
                   fill={'#677283'}
            />
        </DataRow>
    )
};