import {Form, withFormik} from "formik";
import React from "react";
import {ErrorText, PanelHeader} from '../HelperComponents';
import PasswordInfoFormFields from "./PasswordInfoFormFields";
import * as Yup from 'yup';
import {passwordRegex} from "../../util/validation/ValidationSchema";
import {profileConstants} from "../../util/profileConstants2";
import {trackCtaEvent} from "../../../utils/Utils";

const validationSchema = Yup.object().shape({
    currentPassword: Yup
        .string()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.CURRENT_PWD)),
    newPassword: Yup
        .string()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.NEW_PWD))
        .max(50, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.NEW_PWD, 50))
        .matches(passwordRegex, profileConstants.isValidPasswordMsg(profileConstants.FIELD_LABELS.NEW_PWD)),
    confirmPassword: Yup
        .string()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.VERIFY_PWD))
        .test('passwords-match', profileConstants.passwordMatchMsg, function (value) {
            return this.parent.newPassword === value;
        }),
});
const PasswordInfoBasicForm = ({...props}) => {

    return (
        <>

            <Form onSubmit={props.handleSubmit} className="Bizible-Exclude">
                <PanelHeader
                    name={'passwordInfo'}
                    isLaptop={props.isLaptop}
                    label={'Change password'}
                    isEdit={props.status.isEditMode}
                    disableEdit={props.disableEdit}
                    hideControls={false}
                    handleEditButton={() => {
                        props.setStatus({isEditMode: true});
                        props.toggleOtherSectionsEditBtn();
                    }}
                    handleCancelButton={() => {
                        props.toggleOtherSectionsEditBtn();
                        props.resetForm();
                        props.setStatus({isEditMode: false});
                    }}/>


                {/*API Error Messages*/}
                {props.status && props.status.isEditMode && props.status.isError && props.status.errorMsg &&
                props.status.errorMsg.map((err, key) => (
                    <ErrorText key={key} w={100}>{err}</ErrorText>)
                )}


                {(props.status.isEditMode) && <PasswordInfoFormFields {...props} />}

            </Form>
        </>
    )
};

export const PasswordInfoForm = withFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({formValues}) => {
        return formValues;
    },
    mapPropsToStatus: ({isLaptop,isInternalUser}) => {
        if(isInternalUser){
            return {isEditMode: false}
        }
        return {isEditMode: !isLaptop}
    },
    handleSubmit: async (values, {props, setStatus}) => {
        const {currentPassword, newPassword, username} = values;
        trackCtaEvent('', 'EmailAndPasswordInfo')
        const resp = await props.saveData({
            currentPassword,
            newPassword,
            username
        }, profileConstants.PASSWORD_INFO_SECTION_ADOBE_KEY);
        if (resp.isError) {
            setStatus({isEditMode: true, isError: true, errorMsg: resp.errorMsg})
            // trackFormEvent('form submit error', 'EmailAndPasswordInfo', resp.errorMsg)
        } else {
            setStatus({isEditMode: false});
            props.toggleOtherSectionsEditBtn();
            // trackFormEvent('form submit', 'EmailAndPasswordInfo', 'Save', 'Profile')
        }
    }
})(PasswordInfoBasicForm);