import React from 'react';
import {Cta, iconMachineNameMap, Text, Flex} from "@qc-modules/components";
import styled from 'styled-components';
import {colors} from "@qc-modules/styles";

const LogoQualcommIcon = iconMachineNameMap["logo-qualcomm"];

const Logo = styled(LogoQualcommIcon)`
  fill: ${props => colors("blue", props)};
  width: 149px;
`;

const ModalContainer = styled(Flex)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    align-items: center;
    justify-content: center;
    z-index: 200;
    min-width: 375px;
    &:hover {cursor: pointer};
`;

const Modal = styled(Flex)`
    background-color: #FFF;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 775px;
    border: 1px solid #677283;
    border-radius: 12px;
    padding: 20px;
    min-width: 450px;
    &:hover {cursor: pointer};
    z-index: 250;
    opacity: 1;
`;

const StyledText = styled(Text)`
    color: #677283;
    margin-top: 2px;
    margin-bottom: 35px;
    min-width: 450px;
    width: 600px;
    max-width: 650px;
    &:hover {cursor: pointer};
`;

const StyledCta = styled(Cta)`
    background-color: ${props => colors("blue", props)};
    color: white;
    border-radius: 30px;
    margin-bottom: 55px;
`;

export const OldBrowserModal = ({showModal, setShowModal}) => {
    const buttonClickHandler = () => {
        window.location = 'https://browser-update.org/update-browser.html#3.2.14:www.qualcomm.com';
    };

    const closeModal = () => {
        setShowModal(!showModal);
    };

    return (
        <ModalContainer onClick={closeModal}>
            <Modal>
                <Logo/>

                <StyledText f={3}>
                    Qualcomm.com leverages the latest web technologies to deliver a
                    faster, safer, and more advanced experience. Unfortunately your
                    current browser may not support these technologies, and as a result
                    certain features may not function or display properly. For the best site
                    experience possible, we recommend updating to one of the latest browsers here:
                </StyledText>

                <StyledCta onClick={buttonClickHandler}>Update your browser</StyledCta>
            </Modal>
        </ModalContainer>
    )
};
