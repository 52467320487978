import React, {useEffect, useState} from "react";
import {PanelHeader} from "../profile2/HelperComponents";
import {device} from "../../lib/breakpoints";
import {useMediaQuery} from "react-responsive";
import {Box, Cta, Flex, Glyph, Modal, Text} from "@qc-modules/components";
import styled from "styled-components";
import {deleteSubscription, fetchSubscriptions} from "../../api/profile-api";
import {ComponentTitle, defaultValueFont, requestWrapper} from "../util/helper";
import {colors} from "@qc-modules/styles"
import Loading from "../util/Loading";
import ErrorPage from "../common/ErrorPage";

const AlertMessage = styled(Box)`
font-size: 18px;
margin: 30px 0px 34px 0px;
color: ${colors("body-grey")};
letter-spacing: 0.5px;
line-height: 27px

`;

const ModalHeading = styled(Text)`

 height: 40px;
  color: #222222;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 40px;
`;
const StyledModalCta = styled(Cta)`
height: 30px;
display: inline-block;
`;

export const StyledProductLabel = styled.div`
   width: 45%;
   height: 24px;
  
  color: #3253DC;
  
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
`;
const ProductGrid = styled(Flex)`
flex-direction: column;
`;
const ProductRowGrid = styled.div`
flex-direction: row;
margin-bottom: 24px;
display: flex;
justify-content: flex-start;
    @media ${device.mobile} {
        justify-content: space-between;
    }
    @media ${device.tablet} {
        justify-content:space-between ;
    }
    @media ${device.laptop} {
        justify-content: flex-start;
    }
     @media ${device.desktop} {
        justify-content: flex-start;
    }
    

`;

const ProductRow = ({product, removeHandler}) => {
    return (
        <ProductRowGrid>
            <StyledProductLabel data-testid={`${product.title}-test`}>{product.title}</StyledProductLabel>

            <Cta iconSize={0.1} data-testid={`${product.id}-remove`} fontSize={'10px'}
                 glyphLeft={<Glyph height="10px" id={'remove-minus'}/>}
                 onClick={() => removeHandler(product)} size={'small'} version={'tertiary'}>
                REMOVE ALERT</Cta>
        </ProductRowGrid>
    )
};


const Description = styled(Box)`
    line-height: 1.5;
   font-weight: 400;
   margin: 31px 0px 24px 0px;
`
export const Alerts = () => {
    const [isLoading, setIsLoading] = useState(true);
    const isLaptop = useMediaQuery({query: device.laptop});
    const [products, setProducts] = useState([]);

    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState();
    const [apiError, setApiError] = useState(false);
    const fetch = async () => {

        await requestWrapper(async () => {
            try {
                const resp = await fetchSubscriptions();
                setProducts(resp);
                setApiError(false)
            } catch (e) {
                setApiError(true)
            }
        }, setIsLoading)();


    };

    useEffect(() => {
        fetch()
    }, []);

    const openModal = (product) => {
        console.log(product);
        setShowRemoveModal(true);
        setSelectedProduct(product)
    };
    const removeSubscription = async () => {
        await requestWrapper(async () => {
            try {
                await deleteSubscription(selectedProduct.id);
                // await fetch();
                //remove entry from local memory var
                setProducts(products.filter(product => product.id !== selectedProduct.id));
            } catch (e) {
                setApiError(true)
            } finally {
                setShowRemoveModal(false);
                setSelectedProduct()
            }
        }, setIsLoading)();
    };
    const closeModal = () => {
        setShowRemoveModal(false);
        setSelectedProduct()
    };

    const  isProductsAvailable =()=>{
        return products && Array.isArray(products) && products.length >0
    };
    return (
        <>
            {apiError && <ErrorPage></ErrorPage>}
            {!apiError && <>
                {
                    !isLoading ?
                        <>
                            <PanelHeader
                                name={"alerts"}
                                isLaptop={isLaptop}
                                label={''}
                                hideControls={true}
                            />
                            <Box>
                                <ComponentTitle text={'Alerts'}></ComponentTitle>

                                {isProductsAvailable() ? (<Description {...defaultValueFont}>
                                    You are receiving security bulletins for the products below.
                                    Security bulletins may include vulnerability details, recommended steps to resolve,
                                    products impacted, or details about specific product security information.

                                    Additional security alerts can be added from product pages.
                                </Description>) : <Description {...defaultValueFont}>
                                    You currently are not receiving any security bulletins.
                                    Security bulletins may include vulnerability details, recommended steps to resolve,
                                    products impacted, or details about specific product security information.

                                    Security alerts can be added from product pages.
                                </Description>
                                }

                                <ProductGrid>
                                    {
                                        isProductsAvailable() && products.map((product, index) => {
                                            return <ProductRow key={index} removeHandler={openModal} product={product}/>
                                        })
                                    }
                                </ProductGrid>

                            </Box>
                        </> : <Loading/>


                }

                {showRemoveModal &&
                <Modal closeModal={closeModal}>
                    <>
                        <ModalHeading>Remove alert</ModalHeading>
                        <AlertMessage data-testid={'alert-remove-message'} {...defaultValueFont}>Are you sure you want
                            to remove all notifications
                            for {selectedProduct.title}?</AlertMessage>

                        <Box>
                            <StyledModalCta data-testid={'remove-modal-yes'} onClick={removeSubscription}
                                            width={'100px'} size={'small'}
                                            version={'tertiary'}>
                                Yes
                            </StyledModalCta>
                            <StyledModalCta data-testid={'remove-modal-cancel'} onClick={closeModal} f={2}
                                            width={'100px'} size={'small'} version={'link'}>
                                Cancel
                            </StyledModalCta>
                        </Box>
                    </>

                </Modal>
                }

            </>}
        </>
    )
};
