import React, {useContext, useEffect, useState} from "react";
import {PanelHeader} from "../profile2/HelperComponents";
import {device} from "../../lib/breakpoints";
import {useMediaQuery} from "react-responsive";
import {a, Box, Flex, Glyph} from "@qc-modules/components";
import styled from "styled-components";
import {ComponentTitle, defaultValueFont, formatDate, isValidImpersonation} from "../util/helper";
import {getSupportEmail, trackCtaEvent} from "../../utils/Utils";
import AppContext from "../../context/AppContext";

const Description = styled(Box)`
    line-height: 1.5;
    font-weight: 400;
    margin: 31px 0px 24px 0px;
`;

export const StyledProductLabel = styled.a`
    height: 24px;
    color: #3253DC;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 24px;
`;
export const StyledProductDate = styled.div`
    height: 24px;
    color: #677283;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 24px;
`;
const ProductGrid = styled(Flex)`
    flex-direction: column;
    margin-bottom: 62px;
`;

const ProductRowGrid = styled.div`
    flex-direction: row;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;;
`;
const ProductRowGridHeading = styled(Description)`
    flex-direction: row;
    margin-bottom: 24px;
    padding-bottom: 25px;
    border-bottom: 1px solid #E9ECEF;
    display: flex;
    justify-content: space-between;
`;
const dateFormat = 'M/D/YYYY';
const deriveDate = (date) => {
    // const dateValue = date && date.substring(0, date.indexOf('T'));
    return formatDate(date, dateFormat)
}
const ProductRow = ({product, removeHandler}) => {
    return (
        <ProductRowGrid>
            <StyledProductLabel href={product['url']}
                                data-testid={`${product.title}-test`} onClick={() => {
                trackCtaEvent(`${product['url']}`, `${product['field_pdp_header_title']}`)
            }}>{product['field_pdp_header_title']}</StyledProductLabel>
            <StyledProductDate
                data-testid={`${product.title}-test`}>{deriveDate(product['entitledOn'])}</StyledProductDate>
        </ProductRowGrid>
    )
};

const StyledInfo = styled(Box)`
    color: #222222;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 27px;
`;

const NoItems = styled(Box)`
    display: flex;
    align-items: end;
`;
const NoItemsText = styled(Box)`
    padding-left: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
`;

const SupportFooter = styled(Box)`
    margin-top: 29px;
`;
export const Products = ({store}) => {
    const isLaptop = useMediaQuery({query: device.laptop});
    const [products] = useState(store.userData && store.userData.products);

    const {appStore} = useContext(AppContext);
    const impersonationStatus = appStore.impersonationStatus
    const previousPage = '/login';
    const [tracked, setTracked] = useState(false)

    useEffect(() => {
        if (!tracked && store.userData !== null && store.userData !== undefined) {
            const visitor = {
                'country_code': store.userData.country_code,
                'org_id': store.userData.org.orgId,
                'org_name': store.userData.org.name,
                'qcguid': store.userData.qcGuid,
                'user_name': store.userData.username,
                'loggedIn': "logged_in",
                'user_domain': store.userData.username && store.userData.username.indexOf("@") > -1 ? store.userData.username.slice(store.userData.username.indexOf("@") + 1) : 'qualcomm.com'

            }
            if (isValidImpersonation(impersonationStatus)) {
                visitor['impersonating'] = true
                visitor['impersonator'] = impersonationStatus.impersonator
            }

            // trackPageLoadEvent('profile', 'Products', previousPage, visitor, {})
            window.pageChange('profile', 'Products', previousPage, visitor, {})
            setTracked(true);

        }
    }, [store.userData, previousPage, impersonationStatus, tracked]);


    const isProductsAvailable = () => {
        return products && Array.isArray(products) && products.length > 0
    };
    return (
        <>
            <PanelHeader
                name={"Products"}
                isLaptop={isLaptop}
                label={''}
                hideControls={true}
            />
            <Box>
                <ComponentTitle text={'Products'}></ComponentTitle>
                <Description {...defaultValueFont}>You have been granted access to the non-public products
                    below</Description><ProductRowGridHeading {...defaultValueFont}>
                <Box>Name</Box>
                <Box>Access Granted</Box>
            </ProductRowGridHeading>
                {isProductsAvailable() ? <ProductGrid>
                    {
                        products.map((product, index) => {
                            return <ProductRow key={index} product={product}/>
                        })
                    }
                </ProductGrid> : <ProductGrid>
                    <NoItems><Glyph id={'info'} height="20px" style={{marginTop: '4px'}}/>
                        <NoItemsText>There are no non-public products in your list. This might be because some have been
                            made public</NoItemsText></NoItems>
                </ProductGrid>
                }
                <StyledInfo>As a reminder, per your signed agreement, these products are non-public information and
                    should be
                    treated confidentially.
                </StyledInfo>
                <SupportFooter>
                    <Description {...defaultValueFont}>
                        Questions? <a
                        href={`mailto:${getSupportEmail()}`} onClick={() => {
                        trackCtaEvent(`mailto:${getSupportEmail()}`, 'Contact support')
                    }}>Contact support</a>.
                    </Description>
                </SupportFooter>

            </Box>
        </>
    )
};
