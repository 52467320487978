import React, {useContext} from "react";
import {getCompanyNameWithOrgId, ProfileBox} from '../../util/helper'
import {CompanyInfoForm} from "./CompanyInfoForm";
import {useMediaQuery} from "react-responsive";
import {device} from "../../../lib/breakpoints";
import AppContext from "../../../context/AppContext";

export const CompanyInfoSection = (props) => {
    const {appStore} = useContext(AppContext);
    const {store} = props;
    const userData = store.userData;
    const isLaptop = useMediaQuery({query: device.laptop});
    const formValues = {
        companyOrUniversity: (userData.orgExportId) || '',
        addressLine1: userData.addressLine1 || '',
        addressLine2: userData.addressLine2 || '',
        country: userData.country,
        city: userData.city || '',
        state: userData.state || '',
        postalCode: userData.postalCode || '',
        customerId: (userData.org && userData.org.orgId) || '',
        orgExportId: userData.orgExportId || '',
        province: userData.province || ''
    };
    if (store.dropDownsData && store.dropDownsData.countries && store.dropDownsData.countries.length > 0) {
        const selectedObj = store.dropDownsData.countries.find(country => country.value === userData.country);
        formValues.countryToDisplay = (selectedObj && selectedObj.label) || '';
    }

    const matchingCountry = store.dropDownsData.countryStateMap && store.dropDownsData.countryStateMap.find(country => country.id === userData.country);
    const matchingState = matchingCountry && matchingCountry.states && matchingCountry.states.find(state => state.value === userData.state)
    formValues.stateToDisplay = (matchingState && matchingState.label) || '';


    formValues.companyNameToDisplay = userData.org && getCompanyNameWithOrgId(userData.org.name, userData.org.orgId);

    return (
        <ProfileBox>
            {<CompanyInfoForm domainOrgs={store.dropDownsData.domainOrgs}
                              isLightUser={userData.orgExportId === 9999}
                              isMultiDomainUser={store.dropDownsData && store.dropDownsData.domainOrgs && store.dropDownsData.domainOrgs.length > 1}
                              countries={store.dropDownsData.countries}
                              countryStateMap={store.dropDownsData.countryStateMap}
                // states={states}
                              isLaptop={isLaptop}
                              isInternalUser={appStore.isInternalUser}
                              formValues={formValues}
                              disableEdit={appStore.disableEdit}
                              saveData={store.saveData}
                              toggleOtherSectionsEditBtn={() => appStore.setDisableEdit(!appStore.disableEdit)}
            />}
        </ProfileBox>
    )

};


// export const CompanyInfoSection = (props) => {
//     retr
// }