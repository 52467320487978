import React from 'react';
import styled, {keyframes} from "styled-components"
import {colors, defaultEase, themeTransparentize} from "@qc-modules/styles"

const LoadingDiv = styled.div`
display: block;
position: fixed;
z-index: 100;
background-repeat: no-repeat;
background-position: center;
top: 0;
width: 100%;
top: 45%;
left:55%
`;

export const LoadingRotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const LoadingIcon = styled.div`
  width: 40px;
  height: 40px;
  border-bottom-color: ${props => themeTransparentize(0.8, "text-mid", props)};
  border-left-color: ${props => themeTransparentize(0.8, "text-mid", props)};
  border-right-color: ${props => themeTransparentize(0.8, "text-mid", props)};
  border-top-color: ${props => colors("blue", props)};
  border-radius: 100%;
  border-style: solid;
  border-width: 2.5px;
  content: "";
  transform-origin: center center;
  transition: opacity 0.5s ${defaultEase}};
  animation: ${LoadingRotateKeyframes} 0.6s linear infinite;
`;

const Loading = () => {
    return (
        <LoadingDiv>
            <LoadingIcon/>
        </LoadingDiv>
    );
};

export default Loading;