// import React from "react";
import Deactivate from "../deactivate/Deactivate";
import {EmailInfoSection} from "../profile2/emailInfo/EmalInfoSection";
import {PersonalInfoSection} from "../profile2/personalInfo/PersonalInfoSection";
import {PasswordlInfoSection} from "../profile2/passwordInfo/PasswordInfoSection";
import {CompanyInfoSection} from "../profile2/companyInfo/CompanyInfoSection";
import {Alerts} from "../alertsManagement/Alerts";
import {EmailAndPasswordInfoSection} from "../profile2/emailAndPasswordInfo/EmailAndPasswordInfoSection";
import {Products} from "../products/Products";
import {PreferencesMobileHOC} from "../preferences/PreferencesMobileHOC";
import {Logout} from "../logout/Logout";
import subscriptionsImage from "../../images/email.svg";
import deactivateImage from "../../images/deactivate.svg";
import profileImage from "../../images/user-icon.svg"

export const profileConstants = {
    APPS: {
        www: [
            'Qualcomm.com',
            'Qualcomm Developer Network',
            'ChipCode'
        ],
        qwapublic:['Qualcomm Wireless Academy'],
        qwacorporate:['Qualcomm Wireless Academy'],
        qan: ['Qualcomm Advantage Network'],
        salescenter: ['Sales Center'],
        qdc: ['Qualcomm Device Cloud'],
    },
    PERSONAL_INFO: "personalInfo",
    COMPANY_INFO: "companyInfo",
    PASSWORD_INFO: "passwordInfo",
    EMAIL_INFO: "emailInfo",
    LIGHT_USER_ORG: 9999,
    PERSONAL_BLOCK_HEADING: 'Personal information',
    COMPANY_BLOCK_HEADING: 'Company information',
    PASSWORD_BLOCK_HEADING: 'Change password',
    EMAIL_BLOCK_HEADING: 'Email',
    FIELD_ID: {
        FORGOT_PASSWORD: 'Forgot password?',
        CURRENT_PWD: 'currentPassword',
        NEW_PWD: 'newPassword',
        VERIFY_PWD: 'verifyPassword',

        FIRST_NAME: 'firstName',
        LAST_NAME: 'lastName',
        PHONE_NUMBERS: 'phoneNumbers(s)',
        MOBILE: 'mobile:',
        OFFICE: 'office:',
        JOB_TITLE: 'jobTitle',
        JOB_FUNCTION: 'jobFunction',
        FORUM_ALIAS: 'forumAlias',

        COMPANY_UNIVERSITY: 'companyOrUniversity',
        STREET_ADD_1: 'streetAddress1',
        STREET_ADD_2: 'streetAddress2',
        COUNTRY: 'Country',
        CITY: 'City',
        STATE_PROVINCE_REGION: 'state',
        POSTAL_CODE: 'postalCode',
        CUSTOMER_ID: 'customerID'
    },
    FIELD_LABELS: {
        EMAIL: 'Your email',
        FORGOT_PASSWORD: 'Forgot password?',

        CURRENT_PWD: 'Current password',
        NEW_PWD: 'New password',
        VERIFY_PWD: 'Verify password',

        FIRST_NAME: 'First name',
        LAST_NAME: 'Last name',
        PHONE_NUMBERS: 'Phone numbers(s)',
        MOBILE: 'Mobile:',
        OFFICE: 'Office:',
        JOB_TITLE: 'Job title',
        JOB_FUNCTION: 'Job function',
        FORUM_ALIAS: 'Forum alias',

        COMPANY_UNIVERSITY: 'Company or university',
        STREET_ADD_1: 'Street address 1',
        STREET_ADD_2: 'Street address 2',
        COUNTRY: 'Country',
        CITY: 'City',
        STATE_PROVINCE_REGION: 'State / province / region',
        POSTAL_CODE: 'Postal code',
        CUSTOMER_ID: 'Customer ID',
        EXPORT_ID: 'Export ID'
    },
    REGEX: {
        FIRST_LAST_NAME: /^[a-zA-Z0-9 "()']*$/,
        PHONE_NUMBER: /^[a-zA-Z0-9 +\-()#:]*$/,
        ADDRESS: /^[a-zA-Z0-9 ,.\-()#&:;/]*$/,
        STATE_OR_REGION: /^[a-zA-Z0-9 ,.\-()'/]*$/,
        CITY: /^[a-zA-Z0-9 ,.\-()'/]*$/,
        POSTAL_CODE: /^[a-zA-Z0-9 ,.\-/]*$/,
        COMPANY: /^[a-zA-Z0-9 ,.\-()'/&]*$/,
        JOB_TITLE_FUNCTION: /^[a-zA-Z0-9 ,.\-()'/&]*$/,
        FORUM_ALIAS: /^[a-zA-Z0-9-_.]*$/,
        INITIAL_CHECK: /^[^\s]{2,}?([\s]*[^\s]{2,}?)*$/,
    },
    SYSTEM_ERROR: "Problem processing request",
    RESPONSIVE_PROFILE_DEFAULT_PATH: 'profile',
    APP_TABS_STATE: {
        PROFILE_TAB: {
            ID: 'profile',
            LABEL: 'Profile',
            GLYPH: 'profile',
            IMAGE: profileImage,
            TAB_PATH: 'profile',
            MOBILE_VIEW: {
                DESK_PATH: 'profile',
                PF_PF_EMAIL: 'pf_pf_email',
                PF_PF_PERSONAL: 'pf_pf_personal',
                PF_PF_COMPANY: 'pf_pf_company',
                PF_PF_PASSWORD: 'pf_pf_password',
                PF_PF_EMAIL_AND_PASSWORD: 'pf_pf_email_and_password',
            }
        },
        PREFERENCES_TAB: {
            ID: 'subscr',
            LABEL: 'Subscriptions',
            GLYPH: 'email',
            IMAGE: subscriptionsImage,
            TAB_PATH: 'preferences',
            MOBILE_VIEW: {
                DESK_PATH: 'preferences',
                PF_PREFERENCES_SUBSCR: 'pf_pref_sub',
                PF_PREFERENCES_APP_AREA: 'pf_pref_appArea',
                PF_PREFERENCES_QWA: 'pf_pref_qwa',
                PF_PREFERENCES_QNS: 'pf_pref_qns',
            }
        },
        ALERTS_TAB: {
            ID: 'alerts',
            LABEL: 'Alerts',
            GLYPH: 'warning',
            TAB_PATH: 'alerts',
            MOBILE_VIEW: {
                DESK_PATH: 'alerts',
                PF_ALERTS: 'pf_alerts',
            }
        },
        PRODUCTS_TAB: {
            ID: 'products',
            LABEL: 'Products',
            GLYPH: 'chip',
            TAB_PATH: 'products',
            MOBILE_VIEW: {
                DESK_PATH: 'products',
                PF_PRODUCTS: 'pf_products',
            }
        },
        DEACTIVATE_TAB: {
            ID: 'deactivate',
            LABEL: 'Deactivate your account',
            GLYPH: 'deactivate',
            IMAGE: deactivateImage,
            TAB_PATH: 'deactivate',
            MOBILE_VIEW: {
                DESK_PATH: 'deactivate',
                PF_DEACTIVATE: 'pf_deactivate'
            }
        },
        LOGOUT_TAB: {
            ID: 'logout',
            LABEL: 'Sign out',
            TAB_PATH: 'logout',
            MOBILE_VIEW: {
                DESK_PATH: 'logout',
                PF_LOGOUT: 'pf_logout'
            }
        }
    },
    minLengthMsg: (field, length) => {
        // return `${field} Should be ${length} Characters`
        return `Please supply at least ${length} characters`
    },
    minLengthIfValueExistMsg: (field, length) => {
        return `Please supply at least ${length} characters`
    },
    maxLengthMsg: (field, length) => {
        return `Please supply at most ${length} characters`
    },
    alphaNumericMsg: (field) => {
        return `${field} Should be alphanumeric`
    },
    isNotValidEmail: (field) => {
        return `Please supply a valid email address`
    },
    isNotEmptyMsg: (field) => {
        return `Please enter value for ${field}`
    },
    isValidPasswordMsg: (field) => {
        return `${field} contains invalid character`
    },
    passwordMatchMsg: 'New and Verify Passwords do not match',
    initialCheckMsg: (fields) => {
        return `You have entered a single initial. Please supply your full name`
    },
    regexMsg: (field) => {
        return `You have entered an invalid character`
    },
    termsMsg: () => {
        return `Please agree to the Terms of Use`
    },
    internalDomainMsg: (field) => {
        return `Qualcomm employees please contact ${process.env.REACT_APP_SUPPORT_EMAIL} for access`
    },
};


export const PreferencesConfig = {
  wirelessAcademy: 'qwaSubscriptions',
  updates: 'applicationAreas',
  newsLetters: 'areasOfInterest',
  alerts: 'notificationsInfo',
  qwaSubscriptions: {
    descr: `Get updates about new and updated courses from Qualcomm to build your wireless expertise today. Our courses cover a range of technology material to empower the ecosystem with a better understanding of cellular technologies and promote the successful adoption and deployment of wireless networks and devices worldwide.`,
    heading: `Wireless Academy`,
    apiKey: 'wirelessAcademy',
    subheading: 'Interested in',
    adobeType:'courses of interest'
  },
  applicationAreas: {
    descr: `Select the updates you’d like to receive and we’ll make
                sure you get periodic updates on product announcements, news, and events.`,
    heading: `Updates`,
    apiKey: 'applicationAreas',
    subheading: 'I work on',
    adobeType:'area of interest'
  },
  areasOfInterest: {
    descr: `Get innovation delivered to your inbox. Select the email newsletters
                you’d like to receive and we’ll make sure you stay on top of the very latest from Qualcomm.`,
    heading: `Newsletters`,
    apiKey: 'areasOfInterest',
    subheading: 'Subscribed to',
    adobeType:'subscriptions'
  },
  notificationsInfo: {
      descr: `Receive email notifications for all new and updated product documents.  Simply select the products and types of documents you’re interested in and we’ll notify you via email whenever there is an update, at the frequency of your choice.`,
      heading: `Products`,
      apiKey: 'qnsSettings',
      subheading1: 'Frequency',
      subheading2: 'Subscriptions for',
      subheading3: 'Subscribed to',
      adobeType: 'subscriptions'
  }
};


export const panelMappingConstants = {
    PanelMapping:
            [
              {
                tabPath: profileConstants.APP_TABS_STATE.PROFILE_TAB.MOBILE_VIEW.PF_PF_COMPANY,
                component: CompanyInfoSection,
                toggleEditConstant: profileConstants.COMPANY_INFO,
                store: 'profileStore'
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.PROFILE_TAB.MOBILE_VIEW.PF_PF_EMAIL_AND_PASSWORD,
                component: EmailAndPasswordInfoSection,
                toggleEditConstant: profileConstants.PASSWORD_INFO,
                store: 'profileStore'
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.PROFILE_TAB.MOBILE_VIEW.PF_PF_EMAIL,
                component: EmailInfoSection,
                toggleEditConstant: null,
                store: 'profileStore'
              },

              {
                tabPath: profileConstants.APP_TABS_STATE.PROFILE_TAB.MOBILE_VIEW.PF_PF_PASSWORD,
                component: PasswordlInfoSection,
                toggleEditConstant: profileConstants.PASSWORD_INFO,
                store: 'profileStore'
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.PROFILE_TAB.MOBILE_VIEW.PF_PF_PERSONAL,
                component: PersonalInfoSection,
                toggleEditConstant: profileConstants.PERSONAL_INFO,
                store: 'profileStore'
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.ALERTS_TAB.MOBILE_VIEW.PF_ALERTS,
                component: Alerts,
                toggleEditConstant: profileConstants.PERSONAL_INFO,
                store: 'profileStore'
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.PRODUCTS_TAB.MOBILE_VIEW.PF_PRODUCTS,
                component: Products,
                toggleEditConstant: profileConstants.PERSONAL_INFO,
                store: 'profileStore'
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.MOBILE_VIEW.PF_PREFERENCES_SUBSCR,
                component: PreferencesMobileHOC,
                args: {type: PreferencesConfig.newsLetters},
                toggleEditConstant: null
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.MOBILE_VIEW.PF_PREFERENCES_APP_AREA,
                component: PreferencesMobileHOC,
                args: {type: PreferencesConfig.updates},
                toggleEditConstant: null
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.MOBILE_VIEW.PF_PREFERENCES_QNS,
                component: PreferencesMobileHOC,
                args: {type: 'qns'},
                toggleEditConstant: null
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.MOBILE_VIEW.PF_DEACTIVATE,
                component: Deactivate,
                toggleEditConstant: null,
                store: 'deactivateStore'
              },
              {
                tabPath: profileConstants.APP_TABS_STATE.LOGOUT_TAB.MOBILE_VIEW.PF_LOGOUT,
                toggleEditConstant: null,
                component: Logout
              }
            ]
};

export const tabsConfig = [
    {
      glyphId: profileConstants.APP_TABS_STATE.PROFILE_TAB.GLYPH,
      image: profileConstants.APP_TABS_STATE.PROFILE_TAB.IMAGE,
      path: profileConstants.APP_TABS_STATE.PROFILE_TAB.TAB_PATH,
      pathLabel: profileConstants.APP_TABS_STATE.PROFILE_TAB.LABEL,
      tabRef: profileConstants.APP_TABS_STATE.PROFILE_TAB,
      id: profileConstants.APP_TABS_STATE.PROFILE_TAB.ID,
      order: 1,
      mobileLinks: [
        {
          link: profileConstants.APP_TABS_STATE.PROFILE_TAB.MOBILE_VIEW.PF_PF_EMAIL_AND_PASSWORD,
          label: 'Email and Password',
          id: 'emailandpassword'
        },
        {
          link: profileConstants.APP_TABS_STATE.PROFILE_TAB.MOBILE_VIEW.PF_PF_PERSONAL,
          label: 'Personal Information',
          id: 'personalInfo'
        },
        {
          link: profileConstants.APP_TABS_STATE.PROFILE_TAB.MOBILE_VIEW.PF_PF_COMPANY,
          label: 'Company Information',
          id: 'companyInfo'
        }
      ]
    },
    {
      glyphId: profileConstants.APP_TABS_STATE.PRODUCTS_TAB.GLYPH,
      path: profileConstants.APP_TABS_STATE.PRODUCTS_TAB.TAB_PATH,
      pathLabel: profileConstants.APP_TABS_STATE.PRODUCTS_TAB.LABEL,
      tabRef: profileConstants.APP_TABS_STATE.PRODUCTS_TAB,
      id: profileConstants.APP_TABS_STATE.PRODUCTS_TAB.ID,
      order: 2,
      mobileLinks: [
        {
          link: profileConstants.APP_TABS_STATE.PRODUCTS_TAB.MOBILE_VIEW.PF_PRODUCTS,
          label: 'Non-public products',
          id: 'nonPublicProducts'
        }
      ]
    },
    {
      glyphId: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.GLYPH,
      image: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.IMAGE,
      path: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.TAB_PATH,
      pathLabel: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.LABEL,
      tabRef: profileConstants.APP_TABS_STATE.PREFERENCES_TAB,
      id: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.ID,
      order: 3,
      mobileLinks: [
        {
          link: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.MOBILE_VIEW.PF_PREFERENCES_SUBSCR,
          label: 'Newsletters',
          id: 'Newsletters'
        },

        {
          link: profileConstants.APP_TABS_STATE.PREFERENCES_TAB.MOBILE_VIEW.PF_PREFERENCES_QNS,
          label: 'Products',
          id: 'qns'
        }
      ]
    },
    {
      glyphId: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.GLYPH,
      image: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.IMAGE,
      path: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.TAB_PATH,
      pathLabel: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.LABEL,
      tabRef: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB,
      id: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.ID,
      noGlyphInDesk:true,
      position:'end',

      order: 5,
      mobileLinks: [
        {
          link: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.MOBILE_VIEW.PF_DEACTIVATE,
          label: 'Deactivate your account',
          id: 'deactivateAccount'
        }
      ]
    },
    {
      glyphId: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.GLYPH,
      image: profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.IMAGE,
      path: profileConstants.APP_TABS_STATE.LOGOUT_TAB.TAB_PATH,
      pathLabel: profileConstants.APP_TABS_STATE.LOGOUT_TAB.LABEL,
      tabRef: profileConstants.APP_TABS_STATE.LOGOUT_TAB,
      id: profileConstants.APP_TABS_STATE.LOGOUT_TAB.ID,
      noGlyphInDesk:true,
      hideInMobile:true,
      order: 4,
      mobileLinks: [
        {
          link: profileConstants.APP_TABS_STATE.LOGOUT_TAB.MOBILE_VIEW.PF_LOGOUT,
          label: 'Sign out',
          id: 'logout'
        }
      ]
    }
];


