import React, {Fragment} from "react";
import {ComponentTitle, InfoText, SupportComponent} from "../util/helper";

const ErrorPage = () => {
    return (
        <Fragment>
            <ComponentTitle text={"Oops, something went wrong!"}/>
                <InfoText as="p">It looks like your account is having trouble. To report this issue, email our support team
                    at <SupportComponent/>
                </InfoText>
        </Fragment>
    )
};

export default ErrorPage;