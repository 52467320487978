import {useState} from 'react';
import {trackCtaEvent} from "../utils/Utils";
import history from "../context/History";
import {saveDeactivateAccount} from "../api/profile-api";

export const useDeactivate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        setValue(e.target.value);
        setSuccess(e.target.value !== '');
        setError(e.target.value === '');
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);

        e.preventDefault();

        if (success) {
            const data = {reason: value};

            trackCtaEvent('/deactivate', 'Deactivate',{'detail': value})
            saveDeactivateAccount(data)
                .then((response) => {
                    history.push("/logout");
                })
                .catch((e) => {
                    // trackFormEvent('form submit error', 'Deactivate', e.message)
                    history.push('/oops');
                }).finally(() => {
                setIsLoading(false);
            });
        }
    };


    return {
        isLoading, setIsLoading,
        value, setValue,
        success, setSuccess,
        error, setError,
        // deactivateReasons, setDeactivateReasons,

        handleChange,
        handleSubmit
    }
};