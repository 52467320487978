import React from 'react';
import {Link} from 'react-router-dom';
import {StyledParagraphText} from "./RegistrationStyles";
import {getSupportEmail, trackCtaEvent} from "../../../utils/Utils";
import styled from 'styled-components';
import {Box, Flex} from "@qc-modules/components";
import {
    ComponentDescription,
    ComponentParagraphText,
    ResponseComponentTitle,
    StyledAccessBox,
    StyledComponentParagraphLabel,
    StyledDivider
} from "../../util/helper";
import {profileConstants} from "../../util/appConstants2";

export const errorRegistrationResponse = (props) => {
    return (
        <>
            <ResponseComponentTitle testid="registration-header"
                                    text='There seems to be a problem with your registration.'/>

            <ComponentParagraphText mt={'20px'}>
                To resolve your registration issue, please contact <a
                href={`mailto:${getSupportEmail()}`}>{getSupportEmail()}</a>.
            </ComponentParagraphText>
            <RegDetailsComp props={props}>
            </RegDetailsComp>
        </>
    )
};


const Tdd = styled(Flex)`
    color: #222222;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 15px;
    flex-direction: row;
    margin-bottom: 25px;
`
const LabelFlex = styled(Box)`
    flex: 0.20;
    color: #222222;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 15px;
`

const LabelFlexDetails = styled(Box)`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    color: #677283;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 15px;


`

const InfoHeading = styled(Box)`
    color: #022347;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.5px;
    margin-bottom: 22px;
    line-height: 19px;
`

const StyledApp = styled(Box)`
    margin-bottom: 10px;
`

const RegDetailsComp = ({props}) => {
    const requestedAppName = (props && props.location && props.location.state && props.location.state && props.location.state.app);
    const email = props && props.location && props.location.state && props.location.state && props.location.state.email;
    const apps = profileConstants.APPS[requestedAppName];
    return (
        <>
            {email && apps && <>  <StyledDivider></StyledDivider>
                <InfoHeading>Registration Information</InfoHeading>
                <Tdd>
                    <LabelFlex>Username:</LabelFlex>
                    <LabelFlexDetails>{email}</LabelFlexDetails>
                </Tdd>
                <Tdd>
                    <LabelFlex>Applications:</LabelFlex>
                    <LabelFlexDetails>
                        {apps.map(app => {
                            return <StyledApp>{app}</StyledApp>
                        })}

                    </LabelFlexDetails>
                </Tdd>
            </>
            }

        </>
    )
}

export const inProgressRegistrationResponse = (props) => {
    return (
        <>
            <ResponseComponentTitle testid="registration-header" text='Your registration is in progress.'/>
            <ComponentParagraphText mt={'20px'}>You will receive an email when your registration request is approved,
                and your
                Qualcomm ID is ready.</ComponentParagraphText>
            <RegDetailsComp props={props}>
            </RegDetailsComp>
        </>
    )
};

export const RegistrationClickThrough = ((props) => {
    return (
        <>
            <ResponseComponentTitle testid="registration-header" text='You are requesting additional access.'/>
            <ComponentDescription>
                <ComponentParagraphText>If the information below is correct, please confirm your details and continue
                    with your registration application.</ComponentParagraphText>

                <StyledAccessBox>
                    <ComponentParagraphText>
                        <StyledComponentParagraphLabel>First name:</StyledComponentParagraphLabel>
                        {props.firstName}
                    </ComponentParagraphText>

                    <ComponentParagraphText>
                        <StyledComponentParagraphLabel>Last name:</StyledComponentParagraphLabel>
                        {props.lastName}
                    </ComponentParagraphText>

                    <ComponentParagraphText>
                        <StyledComponentParagraphLabel>Email:</StyledComponentParagraphLabel>
                        {props.email}
                    </ComponentParagraphText>

                    <ComponentParagraphText>
                        <StyledComponentParagraphLabel>Country or Location:</StyledComponentParagraphLabel>
                        {props.countries[0].label}
                    </ComponentParagraphText>

                    {props.orgs[0] &&
                        <ComponentParagraphText>
                            <StyledComponentParagraphLabel>Org:</StyledComponentParagraphLabel>
                            {props.orgs[0].label}
                        </ComponentParagraphText>
                    }
                </StyledAccessBox>
            </ComponentDescription>
        </>
    )
});

export const HasRoleResponse = (props) => {
    const url = props.location.state && props.location.state.targetUrl ? props.location.state.targetUrl : 'https://www.qualcomm.com';
    const onLogin = () => {
        trackCtaEvent("/", url)
        window.location.reload()
    }
    return (
        <>
            <ResponseComponentTitle testid="registration-header" text='You already have access.'/>
            <ComponentParagraphText mt={'20px'}>
                You do not need to register again, please <Link onClick={onLogin}
                                                                to={{
                                                                    pathname: '/',
                                                                    state: url
                                                                }}
            >login</Link>.
            </ComponentParagraphText>
            <RegDetailsComp props={props}>
            </RegDetailsComp>
        </>
    )
};

export const ProcessingRegistrationResponse = () => {
    return (
        <>
            <StyledParagraphText>Your Qualcomm ID account is being created. This might take a
                few minutes. In the meantime, please leave this window open.
            </StyledParagraphText>

            <StyledParagraphText>
                If you are not redirected to a new screen, please contact <a
                href={`mailto:${getSupportEmail()}`}>support</a>.
            </StyledParagraphText>
        </>
    )
};

export const AvailableRegistrationResponse = (props) => {
    const url = props.location.state && props.location.state.targetUrl ? props.location.state.targetUrl : 'https://www.qualcomm.com';
    const onLogin = () => {
        trackCtaEvent("/", url)
        window.location.reload()
    }

    return (
        <>
            <ResponseComponentTitle testid="registration-header" text='Your Qualcomm ID is ready!'/>
            <ComponentParagraphText>
                <Link onClick={onLogin}
                      to={{
                          pathname: '/',
                          state: url
                      }}
                >Login</Link> to get started.
            </ComponentParagraphText>
        </>
    )
};