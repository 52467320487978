import React from "react"
import styled from "styled-components"
import {Button, Flex, Box, Glyph} from "@qc-modules/components";
import {device} from "../../../lib/breakpoints";

const StyledNextButton = styled(Button)`
  cursor: pointer;
  ${props => (props.disabled ? `display: none;` : ``)} position: absolute;
  
  right: 0;
  @media ${device.laptop} {
     right: -40px;
  }
  
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(74,90,117,0.08), 0 20px 20px -12px rgba(74,90,117,0.24);
  height: 80px;
  width: 50px;
  z-index: 100;
`;

const StyledPreviousButton = styled(Button)`
  cursor: pointer;
  ${props => (props.disabled ? `display: none;` : ``)} position: absolute;
  
  left: 0;
  @media ${device.laptop} {
  left: -40px;
  }
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(74,90,117,0.08), 0 20px 20px -12px rgba(74,90,117,0.24);
  height: 80px;
  width: 50px;
  z-index: 100;
`;

const IconContainer = styled(Flex)`
    align-items: center;
    justify-content: center;
`;

const IconBox = styled(Box)`
    height: 32px;
    width: 32px;
    display: block;
`;

export const NextButton = ({className, onClick}) => (
    <StyledNextButton
        disabled={className.includes('slick-disabled') ? true : false}
        onClick={onClick}
    >
        <IconContainer>
            <IconBox>
                <Glyph id='caret-right' fill='#BBC0C8'/>
            </IconBox>
        </IconContainer>
    </StyledNextButton>
);


export const PreviousButton = ({className, onClick}) => (
    <StyledPreviousButton
        disabled={className.includes('slick-disabled') ? true : false}
        onClick={onClick}
    >
        <IconContainer>
            <IconBox>
                <Glyph id='caret-left' fill='#BBC0C8'/>
            </IconBox>
        </IconContainer>
    </StyledPreviousButton>
);