import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import history from "../context/History";

import Routes from "./Routes";

const AppRouter = () => {
    return (
        <Router history={history}>
            <Routes/>
        </Router>
    )
};

export default AppRouter;