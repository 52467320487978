import React from 'react';
import {Field, ErrorMessage} from "formik";
import {WrappedFormComponents} from "../../util/FormComponents/FormikWrappedComponents";
import {RegisterCheckboxLabels, StyledFormFieldDiv} from "../../util/FormComponents/CommonStyles";
import {ValidationFunctions} from "../../util/validation/ValidationFunctions";
import {
    getValidGlyph,
    MessageWrapper,
    StyledPasswordMessage,
    StyledValidMessage
} from "../../util/validation/ValidationStyles";
import {PASSWORD_SUCCESS_MESSAGE} from "../../util/validation/PasswordValidationConstants";

export const RegistrationFormFields = ({touched, errors, ...props}) => {
    return(
    <>
        <StyledFormFieldDiv>
            <Field name="firstName"
                   id="firstName"
                   placeholder="First Name"
                   label='First Name*'
                   labelPosition='placeholder'
                   type='text'
                   width={1}
                   disabled={props.fieldDataNeeded.length>0 && !props.fieldDataNeeded.includes('firstName')}
                   component={WrappedFormComponents.InputWrapper}
                   validate={ValidationFunctions.validateFirstName}
            />
            <MessageWrapper><ErrorMessage name="firstName"/></MessageWrapper>
        </StyledFormFieldDiv>

        < StyledFormFieldDiv>
            < Field name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    label='Last Name*'
                    labelPosition='placeholder'
                    type='text'
                    width={1}
                    disabled={props.fieldDataNeeded.length>0 && !props.fieldDataNeeded.includes('lastName')}
                    component={WrappedFormComponents.InputWrapper}
                    validate={ValidationFunctions.validateLastName}
            />
            <MessageWrapper><ErrorMessage name="lastName"/></MessageWrapper>
        </StyledFormFieldDiv>

        {props.countries.length === 1 &&
        <StyledFormFieldDiv>
            <Field
                as="text"
                name="countryDisplay"
                id="countryDisplay"
                label="Country or Location*"
                labelPosition='placeholder'
                component={WrappedFormComponents.InputWrapper}
                width={1}
                disabled={true}
                value={props.countries[0].label}/>
        </StyledFormFieldDiv>
        }

        {props.countries.length > 1 &&
        <StyledFormFieldDiv>
            <Field as="select"
                   name="country"
                   id="country"
                   label="Country or Location*"
                   labelPosition='placeholder'
                   component={WrappedFormComponents.SelectWrapper}
                   width={1}
                   options={props.countries}
                   validate={ValidationFunctions.validateCountry}
            />
            <MessageWrapper><ErrorMessage name="country"/></MessageWrapper>
        </StyledFormFieldDiv>
        }

        {props.orgs.length === 1 &&
        <StyledFormFieldDiv>
            <Field
                as="text"
                name="exportIdDisplay"
                id="exportIdDisplay"
                label="Organization*"
                labelPosition='placeholder'
                component={WrappedFormComponents.InputWrapper}
                width={1}
                disabled={true}
                value={props.orgs[0].label}/>
        </StyledFormFieldDiv>
        }

        {props.orgs.length > 1 &&
        <StyledFormFieldDiv>
            <Field as="select"
                   name="exportId"
                   id="exportId"
                   label="Organization*"
                   labelPosition='placeholder'
                   component={WrappedFormComponents.SelectWrapper}
                   width={1}
                   options={props.orgs}
                   validate={ValidationFunctions.validateExportId}
            />
            <MessageWrapper><ErrorMessage name="exportId"/></MessageWrapper>
        </StyledFormFieldDiv>
        }

        {(props.userStatus.state === 'NEW_USER') &&
        <StyledFormFieldDiv>
            <Field name="password"
                   id="password"
                   placeholder="Password"
                   label='Password*'
                   labelPosition='placeholder'
                   type='password'
                   width={1}
                   component={WrappedFormComponents.InputWrapper}
                   validate={ValidationFunctions.validatePassword}
            />
            <MessageWrapper>
                <StyledPasswordMessage>
                <ErrorMessage name="password"/>
                {(touched.password && !errors.password) && <StyledValidMessage
                    key={'success'}>{getValidGlyph()} {PASSWORD_SUCCESS_MESSAGE}</StyledValidMessage>}
                </StyledPasswordMessage>
            </MessageWrapper>
        </StyledFormFieldDiv>
        }


        {!props.termsOfService &&
        <StyledFormFieldDiv>
            <Field as="checkbox"
                   name="termsOfService"
                   id="termsOfService"
                   component={WrappedFormComponents.CheckboxWrapper}
                   width={1}
                   validate={ValidationFunctions.validateTermsOfService}
                   label={
                       <RegisterCheckboxLabels>
                           I agree to the <a href="https://www.qualcomm.com/site/terms-of-use"
                                             target="_blank"
                                             rel="noopener noreferrer">Qualcomm Terms of Use</a> and <a
                           href="https://www.qualcomm.com/site/privacy"
                           target="_blank"
                           rel="noopener noreferrer">Privacy Policy</a>
                       </RegisterCheckboxLabels>
                   }
            />
            <MessageWrapper><ErrorMessage name="termsOfService"/></MessageWrapper>
        </StyledFormFieldDiv>
        }

        {!props.optIn &&
        <StyledFormFieldDiv>
            <Field as="checkbox"
                   name="optIn"
                   id="optIn"
                   component={WrappedFormComponents.CheckboxWrapper}
                   width={1}
                   label={
                       <RegisterCheckboxLabels>Keep me informed about Qualcomm products and solutions with
                           emails from Qualcomm Technologies, Inc.
                       </RegisterCheckboxLabels>
                   }
            />
        </StyledFormFieldDiv>
        }
    </>
    )
};