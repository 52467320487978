import React from 'react';
import styled from 'styled-components';
import {Flex} from '@qc-modules/components'
import {MobileTabHeaderRow} from "./ProfileMobileHeaderRow";
import {MobileTabLinkBlock} from "./ProfileMobileLinkBlock";

const SectionBlock = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;


export const MobileTabBlock = (props) => {
  return (
    <SectionBlock>
      <MobileTabHeaderRow {...props}/>
      <MobileTabLinkBlock {...props}/>
    </SectionBlock>
  )
};