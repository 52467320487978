import React from 'react';
import {ComponentTitle, ErrorInfoComponent, InfoText, PrepareFormElement, PrepareFormElements} from "../util/helper";
import {Link} from "react-router-dom";
import {trackCtaEvent, trackSignUpCtaEvent} from "../../utils/Utils";

export const LoginView = (props) => {

    const {blockData} = props;

    return (
        <>
            {
                blockData &&
                <>
                    <ComponentTitle text={"Login"}/>
                    {blockData.loginFailed && <ErrorInfoComponent text={"Incorrect email or password"}/>}
                    {blockData.emailDomainError && <ErrorInfoComponent text={"Type in your Qualpass username only"}/>}

                    <PrepareFormElements fields={blockData.fields} isEditPage={true}/>

                    <PrepareFormElement field={blockData.formControl}
                                        isEditPage={true}
                                        index={blockData.fields.length}/>

                    <InfoText as="p"><Link to={"/forgotPassword"} onClick={() => {
                        trackCtaEvent('/forgotPassword', 'Reset your password')
                    }}>Reset your password</Link></InfoText>

                    <InfoText as="p">Don't have a Qualcomm ID? <Link data-testid='signup'
                                                                     to={`/register?target=${blockData.targetUrl}`}
                                                                     onClick={trackSignUpCtaEvent}>Sign up here.</Link></InfoText>

                    <input type="hidden" name="TARGET" value="$$target$$"/>
                    <input type="hidden" name="USER" value=""/>
                    <input type="hidden" name="smagentname" value="$$smagentname$$"/>
                    <input type="hidden" name="SMENC" value="ISO-8859-1"/>
                    <input type="hidden" name="SMLOCALE" value="US-EN"/>
                    <input type="hidden" name="PASSWORD" value=""/>
                </>
            }
        </>
    )
};

