const {
    REACT_APP_DEFAULT_REGISTRATION_TARGET_URL: DEFAULT_REGISTRATION_TARGET_URL = '',
    REACT_APP_DEFAULT_REGISTRATION_APP: DEFAULT_REGISTRATION_APP = '',
    REACT_APP_TARGET_URL_ENDPOINT: TARGET_URL_ENDPOINT = '',
    REACT_APP_REGISTRATION_SIGNUP_ENDPOINT: REGISTRATION_SIGNUP_ENDPOINT = '',
    REACT_APP_REGISTRATION_SUBMIT_ENDPOINT: REGISTRATION_SUBMIT_ENDPOINT = '',
    REACT_APP_REGISTRATION_VALIDATE_TOKEN_ENDPOINT: REGISTRATION_VALIDATE_TOKEN_ENDPOINT = '',
    REACT_APP_REGISTRATION_STATUS_ENDPOINT: REGISTRATION_STATUS_ENDPOINT = '',
    REACT_APP_DOMAIN_CHECK_ENDPOINT: DOMAIN_CHECK_ENDPOINT = '',
    REACT_APP_USER_PROFILE_ENDPOINT: USER_PROFILE_ENDPOINT = '',
    REACT_APP_USER_PREFERENCES_ENDPOINT: USER_PREFERENCES_ENDPOINT = '',
    REACT_APP_USER_QNS_SETTINGS_ENDPOINT: USER_QNS_SETTINGS_ENDPOINT = '',
    REACT_APP_AREA_OPTIONS_ENDPOINT: AREA_OPTIONS_ENDPOINT = '',
    REACT_APP_AOI_OPTIONS_ENDPOINT: AOI_OPTIONS_ENDPOINT = '',
    REACT_APP_USER_WELCOME_DETAILS_ENDPOINT: USER_WELCOME_DETAILS_ENDPOINT = '',
    REACT_APP_USER_PASSWORD_CHANGE_ENDPOINT: USER_PASSWORD_CHANGE_ENDPOINT = '',
    REACT_APP_DROPDOWN_DATA_ENDPOINT: DROPDOWN_DATA_ENDPOINT = '',
    REACT_APP_USER_DOMAIN_ORGS_ENDPOINT: USER_DOMAIN_ORGS_ENDPOINT = '',
    REACT_APP_CONFIG_DATA_END_POINT: CONFIG_DATA_END_POINT ='',
    REACT_APP_QNS_INFO_ENDPOINT: QNS_INFO_ENDPOINT = '',
    REACT_APP_SIGNUP_DATA_ENDPOINT: SIGNUP_DATA_ENDPOINT = '',
    REACT_APP_SUBSCRIPTION_END_POINT:SUBSCRIPTION_END_POINT ='',
    REACT_APP_USER_TARGET_END_POINT:USER_TARGET_END_POINT ='',
    REACT_APP_DEACTIVATE_END_POINT: DEACTIVATE_END_POINT ='',
    REACT_APP_PRODUCTS_END_POINT:PRODUCTS_END_POINT =''
} = process.env;

export const registrationDefaults = {
    defaultTargetUrl: DEFAULT_REGISTRATION_TARGET_URL,
    defaultApp: DEFAULT_REGISTRATION_APP,
};

export const apiEndpoint = {
    targetUrlEndpoint: TARGET_URL_ENDPOINT,
    registrationSignupEndpoint: REGISTRATION_SIGNUP_ENDPOINT,
    registrationSubmitEndpoint: REGISTRATION_SUBMIT_ENDPOINT,
    registrationValidateTokenEndpoint: REGISTRATION_VALIDATE_TOKEN_ENDPOINT,
    registrationStatusEndpoint: REGISTRATION_STATUS_ENDPOINT,
    domainCheckEndpoint: DOMAIN_CHECK_ENDPOINT,
    userProfileEndpoint: USER_PROFILE_ENDPOINT,
    userPreferencesEndpoint: USER_PREFERENCES_ENDPOINT,
    userQnsSettingsEndpoint: USER_QNS_SETTINGS_ENDPOINT,
    areOptionsEndpoint: AREA_OPTIONS_ENDPOINT,
    aoiOptionsEndpoint: AOI_OPTIONS_ENDPOINT,
    userWelcomeDetailsEndpoint: USER_WELCOME_DETAILS_ENDPOINT,
    userPasswordChangeEndpoint: USER_PASSWORD_CHANGE_ENDPOINT,
    dropDownsDataEndpoint: DROPDOWN_DATA_ENDPOINT,
    userDomainOrgsEndpoint: USER_DOMAIN_ORGS_ENDPOINT,
    configDataEndpoint:CONFIG_DATA_END_POINT,
    qnsInfoEndpoint:QNS_INFO_ENDPOINT,
    signUpDataEndpoint: SIGNUP_DATA_ENDPOINT,
    subscriptionEndpoint:SUBSCRIPTION_END_POINT,
    userTargetEndpoint:USER_TARGET_END_POINT,
    deactivateEndpoint:DEACTIVATE_END_POINT,
    productsEndpoint:PRODUCTS_END_POINT
};
