import React, {Fragment, useContext} from 'react';

import {ThemeProvider} from "@qc-modules/components";
import {defaultTheme} from "@qc-modules/styles";
import {default as GlobalStyles} from "./lib/global-styles";
import AppContext from './context/AppContext';
import {AppLayout} from "./components/layout/app/AppLayout";
import {ProfileLayout} from "./components/layout/profile/ProfileLayout";

const App = () => {
    const {auth} = useContext(AppContext);
    const isProfileSection = (auth.user && window.location.href.includes('/profile'));

    return (
        <Fragment>
            <GlobalStyles/>
            <ThemeProvider theme={defaultTheme}>
                {!isProfileSection && (
                    <AppLayout/>
                )}

                {isProfileSection && (
                    <ProfileLayout/>
                )}
            </ThemeProvider>
        </Fragment>
    )
};

export default App;