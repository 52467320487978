import React, {Fragment} from "react";
import {ComponentTitle, InfoText, SupportComponent} from "../util/helper";

const Oops = () => {
    return (
        <Fragment>
            <ComponentTitle text={"Oops, something went wrong!"}/>

            <InfoText as="p">It looks like your account is having trouble logging into one of our applications. To clear up
                this issue, email our support team at <SupportComponent/></InfoText>

            <InfoText as="p">To speed up our response time to resolve this issue, please let us know the link you used when
                trying to login.</InfoText>

        </Fragment>)
};

export default Oops;