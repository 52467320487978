export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/i;

export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export const qDomainRegex = /@(qualcomm|qti.qualcomm)\.com$/;

export const alphaNumeric = /^[a-z0-9\d\s]+$/i;


export const isNotEmpty = (value) => {
    return value !== "";
}

export const isEqual = (valueOne, valueTwo, callBackForValueTwo) => {
    const result = valueOne === valueTwo;
    callBackForValueTwo && callBackForValueTwo(!result)
    return result
}


export const isMinLength = (value, length) => {
    return value && (value.length >= length);
}
export const isMaxLength = (value, length) => {
    if (value === "" || value === undefined) {
        return true;
    } else {
        return value && (value.length <= length);
    }

}

export function isValidPassword(value) {
    return (passwordRegex.test(String(value)))
}

export const isAlphaNumeric = (value) => {
    if (value === "" || value === undefined) {
        return true
    } else {
        return alphaNumeric.test(String(value))
    }

}
export const minLengthIfValueExist = (value, length) => {
    if (value === "" || value === undefined) {
        return true
    } else {
        return isMinLength(value, length)
    }
}


export function isValidEmail(value) {
    return emailRegex.test(String(value).toLowerCase())
}

export function isTrue(value) {
    return value
}

export const regexMatch = (pattern, value) => {
    return pattern.test(String(value))
}
export const initialCheck = (value) => {
    return true;
}

export const isInternalDomain=(emailDomain, internalDomains)=>{
    return !!internalDomains &&  !(internalDomains.some(domain=> new RegExp(domain,'i').test(emailDomain)))
}





