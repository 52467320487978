//TMS-Tag Management System
export const trackEventAdobe = (eventData) => {
    window.data = eventData;
    window.otConsentCookie = decodeURIComponent((document.cookie.match(/^(?:.*;)?\s*OptanonConsent\s*=\s*([^;]+)(?:.*)?$/) || [undefined, 'not-set'])[1]);
    if (window.otConsentCookie != null && window.otConsentCookie.indexOf(",C0002:1") > -1) {
        if (!window.data) {
            console.log("return as the data is empty")
            return
        }
        // window.lastPageView = window.path
        window.adobeDataLayer = window.adobeDataLayer || []

        // console.log(`adobe-> ${JSON.stringify(window.data)}`)
        window.adobeDataLayer.push(window.data)
    } else {
        console.log("non consent")
    }

}