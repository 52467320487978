import {createGlobalStyle} from "styled-components";

export default createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *,
  *:before,
  *:after {
      box-sizing: inherit;
      font: inherit;
      padding: 0;
      margin: 0;
      border: 0;
      vertical-align: baseline;
      text-decoration: none;
  }

  *:focus {
    outline: none;
  }

  body {
    font-family: 'QualcommNext', Arial, Sans-Serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    margin: 0;
  }
  
  a {
    color: #3253DC;
    text-decoration: none;
  }

  input {
    font-family: 'QualcommNext', Arial, Sans-Serif;
  }
  //
  //input[type="text"] {
  //  padding-top: 5px;
  //}

@font-face {
  font-family: 'QualcommNext';
  font-style: normal;
  font-weight: 200;
  src: url(/fonts/QualcommNext-Thin.woff) format('woff'),
  url(/fonts/QualcommNext-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'QualcommNext';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/QualcommNext-Regular.woff') format('woff'),
  url('/fonts/QualcommNext-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'QualcommNext';
  font-style: normal;
  font-weight: 600;
  src:
  url(/fonts/QualcommNext-Medium.woff) format('woff'),
  url(/fonts/QualcommNext-Medium.ttf) format('truetype');
}
`;