import React, {useContext} from 'react';
import {Flex, Glyph, Text} from "@qc-modules/components";
import {colors} from "@qc-modules/styles";
import styled from 'styled-components';
import AppContext from "../../../context/AppContext";
import {trackCtaEvent} from "../../../utils/Utils";


export const HeaderLabel = (props) => {
  const {appStore} = useContext(AppContext);
  let extraSpace = 0;
  if(props.noGlyphInDesk){
    extraSpace =24
  }

  const StyledGlyph = styled(Glyph)`
    align-items: center;
    justify-content: center;
    fill: ${
    () => props.isActive ?
      colors("blue") :
      colors("white")
  }
`;

  const StyledLabelText = styled(Text)`
    margin-left:${
      () => props.position === "end" ?
          "0px" :
          "12px"
    };
    opacity:${
      () => props.setOpacity ?
          props.setOpacity :
          ""
    };
    cursor: pointer;
    color: ${
    () => props.isActive ?
      colors("blue") :
      colors("white")
  };
`;

  const AuthHeaderLabel = styled(Flex)`
cursor: pointer;
height: 48px;
border-radius: 50px 0 0 50px;
align-items: center;
width: 100%;
margin-bottom: 17px;
padding-left:${
    ()=> `${42+extraSpace}px`
  } ;
margin-top: ${
    ()=>props.position === 'end'?'auto':'0px'
  };
background-color: ${
    () => props.isActive ?
      "white" :
      "transparent"
  };
    &:hover {cursor: pointer};
`;

  return (
    <AuthHeaderLabel data-test-id={props.id} onClick={() => {
      if (window.location.pathname !== '/profile') {
        window.location = '/profile'
        return
      }
      trackCtaEvent(props.path,  props.pathLabel, {} )
      appStore.setProfileTabPath(props.path);
      appStore.setDisableEdit(false)
    }}>

      {
        !props.noGlyphInDesk &&  <StyledGlyph
          id={props.glyphId}
          height="24px"
        />
      }
      <StyledLabelText f={3}>{props.pathLabel}</StyledLabelText>
    </AuthHeaderLabel>
  )
};
