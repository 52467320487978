import React, {Fragment} from "react";
import Slider from 'react-slick';
import styled from "styled-components";
import {Text} from '@qc-modules/components';
import {device} from "../../../lib/breakpoints";
import {NextButton, PreviousButton} from './Arrows';
import Card from "./Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled(Slider)`
   @media ${device.xs} {
    width: 500px;
    }
   @media ${device.mobile} {
    width: 500px;
    }
    @media ${device.tablet} {
    width: 700px;
    }
   
    @media ${device.laptop} {
    width: 110%;
    }
    @media ${device.jumbo} {
    width: 110%;
    }
    

.slick-track {
  display: flex !important;
  flex-direction: row !important;
}

.slick-slide {
    margin: 5px 10px;
    
    &:first-child {
        margin-left: 6px;
    }
    
    @media ${device.laptop} {
       margin: 5px 5px;
    }
  }
  

  
.indicator__dots {
    margin: 0;
    vertical-align: middle;
    height: 25px;
    text-align: center;
}

ul.indicator__dots {
    padding-inline-start: 0;
    padding: 0;
    margin-top: 10px;

    & li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    padding: 0;

        & button {
            cursor: pointer;
            display: inline-block;
            border-radius: 50%;
            border-width: 1px;
            border-style: solid;
            border-color: #3253DC;
            color: transparent;
            font-size: 5px;
            margin: 0 2px;
            height: 14px;
            width: 14px;
            background-color: #fff;
        }
    }
}

.indicator__dots li.slick-active button {
    background-color: #3253DC;
    outline: none;
}

.indicator__dots li button:hover,
.indicator__dots li button:focus {
    background-color: #3253DC;
    outline: 0;
}
`;


const getApplicationCards = (items, clickHandler) => {
    const cards = items.map((item) => {
        return (
            <Card key={item.id} title={item.displayText} description={item.description}
                  onClick={() => clickHandler(item.url, item.app)}/>
        )
    });
    return cards;
};

const SectionHeader = styled(Text)`
    margin-top: 24px;  
    margin-bottom: 20px;  
    color: #222222;
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 1.125;
    font-weight: 600;

    
 @media ${device.tablet} {
  font-size: 32px
  }
@media ${device.mobile} {
  font-size: 24px;
        letter-spacing: 0.0px;
        line-height: 30px;
  }
    @media ${device.laptop} {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.3px;
    }
`;

export const StyledComponentTitle = styled(Text)`
    
  color: #222222;
      font-size: 24px;
    font-weight: 600;
  letter-spacing: .3px;
  line-height: 35px;
  margin-bottom: 26px;
  @media ${device.mobile} {
  font-size: 24px
  }
  @media ${device.tablet} {
  font-size: 32px
  }
   @media ${device.laptop} {
   font-size: 36px;
        letter-spacing: 0.0px;
        line-height: 30px;
    }
`;

const ApplicationSlider = ({items, clickHandler, afterChangeHandler}) => {
    const settings = {
        infinite: false,
        dots: true,
        dotsClass: "indicator__dots",
        nextArrow: <NextButton />,
        prevArrow: <PreviousButton />,
        afterChange: afterChangeHandler,
        slidesToShow: items.length > 2 ? 3 : items.length,
        responsive: [
            {
                breakpoint: 1042,
                settings: {
                    slidesToShow: items.length > 1 ? 2 : items.length,
                    dots: true,
                }
            },
            {
                breakpoint: 949,
                settings: {
                    slidesToShow: items.length > 1 ? 2 : items.length,
                    dots: false,
                    nextArrow: false,
                    prevArrow: false,
                }
            },
        ]
    };

    return (
        <Fragment>
            <SectionHeader as="h4">Your Qualcomm applications</SectionHeader>
                <StyledSlider {...settings}>
                    {getApplicationCards(items, clickHandler)}
                </StyledSlider>
        </Fragment>
    );
};

export default ApplicationSlider;
