import React from "react"
import {array, arrayOf, node, bool, func, number, oneOfType, shape, string} from "prop-types"
import {map, not} from "ramda"
import styled from "styled-components"
import {Primitives} from "@qc-modules/components"

import {defaultFontSize} from "./utils"
import RadioButton from "./RadioButton"
import {device} from "../../lib/breakpoints";

export const RadioGroup = styled(Primitives.Flex)`
  border: none;
  margin: 0;
  padding: 0;
  @media ${device.mobile} {
  flex-direction:column
  }
  @media ${device.laptop} {
  flex-direction:row
  }
`

const propTypes = {
    // Font parameters for each Radio Button
    buttonFont: shape({
        fontSize: oneOfType([string, number, array])
    }),
    containerDirection: string,
    containerProps: shape({}),
    /** A boolean to represent whether the input field should be disabled */
    disabled: bool,
    /** Label for the group */
    groupLabel: node,
    // Name of radio button group
    groupName: string,
    // Font parameters for group label
    labelFont: shape({
        fontSize: oneOfType([string, number, array])
    }),
    /** radioButtonOnChange event handler - used to process the event */
    radioButtonOnChange: func,
    radioContainerProps: shape({}),
    // The currently selected sort option
    selectedOption: string,
    /** List of sort options, either array or object  */
    sortOptions: arrayOf(
        shape({
            disabled: bool,
            id: string,
            label: string,
            value: string
        })
    )
}

const RadioSort = ({
                       buttonFont,
                       containerProps,
                       labelFont = defaultFontSize,
                       disabled,
                       radioButtonOnChange,
                       groupLabel,
                       groupName,
                       radioContainerProps,
                       sortOptions,
                       selectedOption
                   }) => (
    <RadioGroup
        disabled={disabled}
        my={2}
        tag="fieldset"
        {...containerProps}
    >
        {groupLabel ? (
            <Primitives.Text px={[3, 3, 0]} tag="legend" {...labelFont}>
                {groupLabel}
            </Primitives.Text>
        ) : null}
            {map(
                sortOption => (
                    <RadioButton
                        key={sortOption.id}
                        disabled={disabled || sortOption.disabled}
                        fontObject={buttonFont}
                        groupName={groupName}
                        id={sortOption.id}
                        isChecked={selectedOption === sortOption.value}
                        label={sortOption.label}
                        onChange={event =>
                            not(sortOption.disabled) &&
                            radioButtonOnChange({event, ...sortOption})
                        }
                        value={sortOption.value}
                    />
                ),
                sortOptions
            )}
    </RadioGroup>
)

RadioSort.propTypes = propTypes
RadioSort.displayName = "Modules.RadioSort"

export default RadioSort
