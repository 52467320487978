import React from 'react';
import styled from 'styled-components';
import {Box, Flex} from '@qc-modules/components'
import {colors} from "@qc-modules/styles";
import {ProfileMobileDataRow} from "./ProfileMobileDataRow";

const DataBlock = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0px;
  margin-right: 0px;
  margin-top; 13px;
`;


const StyledLink = styled(Box)`
    font-size: 16px;
    text-decoration: none;
    background-color: white;
    cursor: ${props => props.disabled ? 'cursor' : 'pointer'};
    color: ${props => props.disabled ? "#6f7aa9" : colors("blue", props)};
    &:hover {color:${props => props.disabled ? "#6f7aa9" : "#1C37A5"}};
    &:active {color: ${props => colors("blue", props)};
    &:visited {color: ${props => colors("blue", props)};
`;


export const MobileTabLinkBlock = ({mobileLinks, panelHandler}) => {
  return (
    <DataBlock>
      {mobileLinks && mobileLinks.map(mobileLink => {
        return <StyledLink onClick={() => panelHandler(mobileLink.link)}>
          <ProfileMobileDataRow label={mobileLink.label} id={mobileLink.id}/>
        </StyledLink>
      })}
    </DataBlock>
  )
};



