import { useState } from 'react';
import auth from '../services/authService';

const useAuth = () => {
    const [user, setUser] = useState(auth.getCurrentUser());
    const [actingUser, setActingUser] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const login = async (username, password) => {
        setIsLoading(true);
        setError(null);
        try {
            await auth.login(username, password);
            setUser(auth.getCurrentUser());
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    };

    const loginWithToken = token => {
        auth.loginWithToken(token);
        setUser(auth.getCurrentUser());
    };

    const readTokenFromParams = (props) => {
        let token = auth.readTokenFromParams(props)

        return token;
    };
    const isExternalUser = ()=>{
        return auth.isExternalUser()
    };


    return {
        user,
        isLoading,
        error,
        login,
        loginWithToken,
        isExternalUser,
        readTokenFromParams,
        setActingUser,
        actingUser
    };
};

export default useAuth;
