import React, {useEffect} from 'react'
import {logoutHandler} from "../profile2/UserDetails";

export const Logout = () => {
    useEffect(() => {
        logoutHandler()
    },[])


    return (<></>);
}
