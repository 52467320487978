import * as Yup from 'yup';
import {profileConstants} from "../profileConstants2";

import {emailRegex, minLengthIfValueExist} from "../validations";
import {validateInternalEmailDomains} from "./ValidationFunctions";

const MIN_NAME_CHARACTERS = 2;
const MAX_NAME_CHARACTERS = 64;
const MAX_PASSWORD_CHARACTERS = 50;
const MIN_PASSWORD_CHARACTERS = 8;
const MAX_EMAIL_CHARACTERS = 80;

const ALLOWED_PASSWORD_SPECIAL_CHARACTERS_REGEX = /^[\w:!@#$%^*?_~]+$/;
export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

const emailSchemaDefinition = {
    email: Yup.string().trim()
        .required('Your email address is required to continue.')
        .email('The email address you entered is not valid.')
        .max(MAX_EMAIL_CHARACTERS, `Your email address must be less than ${MAX_EMAIL_CHARACTERS} characters.`)
        .matches(emailRegex, 'The email address you entered is not valid.')
        .test('test-email-internal-domain', 'You cannot register with an internal domain.', (value) => validateInternalEmailDomains(value))
};

const firstNameSchemaDefinition = {
    firstName: Yup.string().trim()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.FIRST_NAME))
        .min(MIN_NAME_CHARACTERS, profileConstants.minLengthMsg(profileConstants.FIELD_LABELS.FIRST_NAME, MIN_NAME_CHARACTERS))
        .max(MAX_NAME_CHARACTERS, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.FIRST_NAME, MAX_NAME_CHARACTERS))
        .matches(profileConstants.REGEX.INITIAL_CHECK, profileConstants.initialCheckMsg(profileConstants.FIELD_LABELS.FIRST_NAME))
        .matches(profileConstants.REGEX.FIRST_LAST_NAME, profileConstants.regexMsg(profileConstants.FIELD_LABELS.FIRST_NAME))
};

const lastNameSchemaDefinition = {
    lastName: Yup.string().trim()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.LAST_NAME))
        .min(MIN_NAME_CHARACTERS, profileConstants.minLengthMsg(profileConstants.FIELD_LABELS.LAST_NAME, MIN_NAME_CHARACTERS))
        .max(MAX_NAME_CHARACTERS, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.LAST_NAME, MAX_NAME_CHARACTERS))
        .matches(profileConstants.REGEX.INITIAL_CHECK, profileConstants.initialCheckMsg(profileConstants.FIELD_LABELS.LAST_NAME))
        .matches(profileConstants.REGEX.FIRST_LAST_NAME, profileConstants.regexMsg(profileConstants.FIELD_LABELS.LAST_NAME))
};

const passwordSchemaDefinition = {
    password: Yup.string().trim()
        .required('required_check')
        .min(MIN_PASSWORD_CHARACTERS, 'min_check')
        .max(MAX_PASSWORD_CHARACTERS, 'max_check')
        .matches(/(?=.*[a-z])/, 'lowercase_check')
        .matches(/(?=.*[A-Z])/, 'uppercase_check')
        .matches(/(?=.*\d)/, 'number_check')
        .matches(ALLOWED_PASSWORD_SPECIAL_CHARACTERS_REGEX, 'special_character_check')
};


const countrySchemaDefinition = {
    country: Yup.string()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.COUNTRY))
};

const exportIdSchemaDefinition = {
    exportId: Yup.string()
        .required(profileConstants.isNotEmptyMsg('Organization'))
};

const termsOfServiceDefinition = {
    termsOfService: Yup.boolean().oneOf([true], 'Please agree to the Qualcomm Terms of Use and Privacy Policy').required()
};
const validateAddressLineOneSchemaDefinition = {
    addressLine1: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.STREET_ADD_1, 2), (value) => minLengthIfValueExist(value, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.STREET_ADD_1, 128))
    // .matches(profileConstants.REGEX.ADDRESS, profileConstants.regexMsg(profileConstants.FIELD_LABELS.STREET_ADD_1))
};
const validateAddressLineTwoSchemaDefinition = {
    addressLine2: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.STREET_ADD_2, 2), (value) => minLengthIfValueExist(value, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.STREET_ADD_2, 128))
        .matches(profileConstants.REGEX.ADDRESS, profileConstants.regexMsg(profileConstants.FIELD_LABELS.STREET_ADD_2))
};
const validateCountrySchemaDefinition = {
    city: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.CITY, 2), (value) => minLengthIfValueExist(value, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.CITY, 128))
        .matches(profileConstants.REGEX.CITY, profileConstants.regexMsg(profileConstants.FIELD_LABELS.CITY))
};

const validateUsStateSchemaDefinition = {
    state: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.STATE_PROVINCE_REGION, 2), (value) => minLengthIfValueExist(value, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.STATE_PROVINCE_REGION, 128))
        .matches(profileConstants.REGEX.STATE_OR_REGION, profileConstants.regexMsg(profileConstants.FIELD_LABELS.STATE_PROVINCE_REGION))
};

const validateNonUsStateSchemaDefinition = {
    state: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.STATE_PROVINCE_REGION, 2), (value) => minLengthIfValueExist(value, 2))
        .matches(profileConstants.REGEX.STATE_OR_REGION, profileConstants.regexMsg(profileConstants.FIELD_LABELS.STATE_PROVINCE_REGION))
};
const validatePostalCodeSchemaDefinition = {
    postalCode: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.POSTAL_CODE, 2), (value) => minLengthIfValueExist(value, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.POSTAL_CODE, 128))
        .matches(profileConstants.REGEX.POSTAL_CODE, profileConstants.regexMsg(profileConstants.FIELD_LABELS.POSTAL_CODE))
};
const companyOrUniversitySchemaDefinition = {
    companyOrUniversity: Yup.string().trim()
        .min(2, profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.COMPANY_UNIVERSITY, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.COMPANY_UNIVERSITY, 128))
        .matches(profileConstants.REGEX.COMPANY, profileConstants.regexMsg(profileConstants.FIELD_LABELS.COMPANY_UNIVERSITY))
};
const validateMobileSchemaDefinition = {
    mobileNumber: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.MOBILE, 2), (value) => minLengthIfValueExist(value, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.MOBILE, 128))
        .matches(profileConstants.REGEX.PHONE_NUMBER, profileConstants.regexMsg(profileConstants.FIELD_LABELS.MOBILE))
};
const validateOfficeSchemaDefinition = {
    telephoneNumber: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.OFFICE, 2), (value) => minLengthIfValueExist(value, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.OFFICE, 128))
        .matches(profileConstants.REGEX.PHONE_NUMBER, profileConstants.regexMsg(profileConstants.FIELD_LABELS.OFFICE))
};
const validateJobTitleSchemaDefinition = {
    jobTitle: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.JOB_TITLE, 2), (value) => minLengthIfValueExist(value, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.JOB_TITLE, 128))
        .matches(profileConstants.REGEX.JOB_TITLE_FUNCTION, profileConstants.regexMsg(profileConstants.FIELD_LABELS.JOB_TITLE))
};

const validateForumAliasSchemaDefinition = {
    forumAlias: Yup.string().trim()
        .test('min_length_if_exist', profileConstants.minLengthIfValueExistMsg(profileConstants.FIELD_LABELS.FORUM_ALIAS, 2), (value) => minLengthIfValueExist(value, 2))
        .max(128, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.FORUM_ALIAS, 128))
        .matches(profileConstants.REGEX.FORUM_ALIAS, profileConstants.regexMsg(profileConstants.FIELD_LABELS.FORUM_ALIAS))
};


export const validations = {
    emailSchema: Yup.object().shape(emailSchemaDefinition),
    termsOfServiceSchema: Yup.object().shape(termsOfServiceDefinition),
    firstNameSchema: Yup.object().shape(firstNameSchemaDefinition),
    lastNameSchema: Yup.object().shape(lastNameSchemaDefinition),
    countrySchema: Yup.object().shape(countrySchemaDefinition),
    exportIdSchema: Yup.object().shape(exportIdSchemaDefinition),
    passwordSchema: Yup.object().shape(passwordSchemaDefinition),

    validateAddressLineOneSchema: Yup.object().shape(validateAddressLineOneSchemaDefinition),
    validateAddressLineTwoSchema: Yup.object().shape(validateAddressLineTwoSchemaDefinition),
    validateCountrySchema: Yup.object().shape(validateCountrySchemaDefinition),
    validateUsStateSchema: Yup.object().shape(validateUsStateSchemaDefinition),
    validateNonUsStateSchema: Yup.object().shape(validateNonUsStateSchemaDefinition),
    validatePostalCodeSchema: Yup.object().shape(validatePostalCodeSchemaDefinition),


    validateMobileSchema: Yup.object().shape(validateMobileSchemaDefinition),
    validateOfficeSchema: Yup.object().shape(validateOfficeSchemaDefinition),
    validateJobTitleSchema: Yup.object().shape(validateJobTitleSchemaDefinition),
    validateForumAliasSchema: Yup.object().shape(validateForumAliasSchemaDefinition),
    validateCompanySchema: Yup.object().shape(companyOrUniversitySchemaDefinition)

};

