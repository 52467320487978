import React, {useEffect, useState} from 'react';
import RegistrationForm from "./RegistrationForm";
import {ComponentTitle, EmailIdTextDescription} from "../../util/helper";
import {getUrlParams} from "../../../utils/Utils";
import {getSignUpData, validateToken} from "../../../api/register-api";
import history from "../../../context/History";
import Loading from "../../util/Loading";
import LruWarningModal from "../response/LruWarningModal";

const Registration = () => {
    const [userData, setUserData] = useState({});
    const [userStatus, setUserStatus] = useState('');
    const [fieldsToEdit, setFieldsToEdit] = useState([]);
    const [countries, setCountries] = useState([]);
    const [orgs, setOrgs] = useState([]);
    const [validToken, hasValidToken] = useState(false);
    const [loading, setLoading] = useState(true);
    const [clickThrough, setClickThrough] = useState(false);
    const [showLruWarningModal, setShowLruWarningModal] = useState(false);
    const [serverApp, setServerApp] = useState({});


    const urlParams = () => {
        const params = getUrlParams();

        return {
            app: params.app || '',
            email: params.email || '',
            token: params.token || '',
        }
    };

    const handleCountryData = (countries) => {
        let countryOptions = [];
        if (countries && countries.length > 0) {
            countryOptions = countries.map((country) => ({id: country.code, value: country.code, label: country.name}));

            if (countries.length > 1) {
                countryOptions.unshift({id: 'selectOne', value: '', label: 'Select One'});
            }

            setCountries(countryOptions);
        }
    };

    const handleOrgData = (orgs) => {
        let orgOptions = [];
        if (orgs && orgs.length > 0) {
            orgOptions = orgs.map((org) => ({
                id: org.exportId,
                value: org.exportId,
                label: `${org.name} (${org.orgId})`,
                tier: org.tier
            }));

            if (orgs.length > 1) {
                orgOptions.unshift({id: 'selectOne', value: '', label: 'Select One'});
            }

            setOrgs(orgOptions);
        }
    };

    const requiredParamsExist = (params) => {
        return (params && params.email && params.app && params.token)
    };

    const checkRequiredFieldData = (data) => {
        let dataNeededForFields = [];

        if (!data.countries || data.countries.length > 1) {
            dataNeededForFields.push('country')
        }

        /*
        if there are orgs, then they have not selected and they need to choose
        no orgs - lru
        one org, they've selected already
        */
        if (data.orgs && data.orgs.length > 1) {
            dataNeededForFields.push('exportId')
        }
        if (!data.termsOfService) {
            dataNeededForFields.push('termsOfService')
        }
        if (!data.firstName) {
            dataNeededForFields.push('firstName')
        }
        if (!data.lastName) {
            dataNeededForFields.push('lastName')
        }

        setFieldsToEdit(dataNeededForFields);

        return dataNeededForFields;
    };
    const closeModal = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setShowLruWarningModal(false);
    };


    useEffect(() => {
        async function fetchData() {
        try {

            const params = urlParams();
            if (!requiredParamsExist(params)) {
                history.push('/registration/error');
            }

            const {data} = await validateToken(urlParams());

            const {status} = data;
            setUserStatus(status);
            const {countries, orgs, ...userData} = data;

            handleCountryData(countries);
            handleOrgData(orgs);

            if (status.state !== 'NEW_USER') {
                const {state} = status;
                const appDetails = {email: params.email, app: params.app};
                if (state === 'DORMANT_USER') {
                    console.log('DORMANT_USER')
                } else if (state === 'IN_PROGRESS') {
                    history.push('/registration/inprogress', {...appDetails});
                } else if (state === 'HAS_APP') {
                    history.push('/registration/access', {...appDetails})
                } else if (state === 'CONTACT_SUPPORT' || state === 'BLOCKED') {
                    history.push('/registration/error', {...appDetails});
                }

                const needUserData = checkRequiredFieldData(data);
                if (needUserData.length === 0) {
                    setClickThrough(true)
                }
            }


            const signUpData = await getSignUpData(params.app, params.targetUrl);
            const appData = signUpData.data.app;
            setServerApp(appData);

            //NO orgs means, then its LRU - will be showing warning on load Main Reg form
            if (appData && appData.hasRoleCheck) {
                setShowLruWarningModal(status.lightOrg);
            }
            setUserData(userData);
            hasValidToken(true);

        } catch (err) {
            const params = urlParams();

            if (!requiredParamsExist(params)) {
                history.push('/registration/error');
                return;
            }

            const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            history.push(`/registration/token?${queryString}`);

        } finally {
            setLoading(false);
        }
    }

        fetchData();

    }, []);

    return (
        <>
            {showLruWarningModal && <LruWarningModal closeModal={closeModal}/>}
            {loading && <Loading/>}
            {validToken &&
            <>
                {!clickThrough &&
                <>
                    <ComponentTitle testid="registration-header" text="Complete your Qualcomm ID registration."/>
                    <EmailIdTextDescription text="Your Qualcomm ID will be " email={urlParams().email}/>
                </>
                }
                <RegistrationForm app={urlParams().app}
                                  email={urlParams().email}
                                  token={urlParams().token}
                                  countries={countries}
                                  orgs={orgs}
                                  userStatus={userStatus}
                                  fieldDataNeeded={fieldsToEdit}
                                  clickThrough={clickThrough}
                                  serverApp={serverApp}
                                  {...userData}
                />
            </>
            }
        </>
    )
};

export default Registration;