import React, {useContext} from "react";
import {PersonalInfoForm} from "./PersonalInfoForm";
import {useMediaQuery} from "react-responsive";
import {device} from "../../../lib/breakpoints";
import AppContext from "../../../context/AppContext";
import {ProfileBox} from "../../util/helper";

export const PersonalInfoSection = (props) => {
    const {store} = props;
    const userData = store.userData;
    const isLaptop = useMediaQuery({query: device.laptop});
    const {appStore} = useContext(AppContext);

    const formValues = {
        firstName: (userData.firstName) || '',
        lastName: (userData.lastName) || '',
        jobTitle: (userData.jobTitle) || '',
        forumAlias: (userData.forumAlias) || '',
        telephoneNumber: (userData.telephoneNumber) || '',
        mobileNumber: (userData.mobileNumber) || '',
        jobFunction: (userData.jobFunction) || ''
    };

    return (
        <ProfileBox>
            <PersonalInfoForm formValues={formValues} jobFunctions={store.dropDownsData.jobFunctions}
                              saveData={store.saveData}
                              isInternalUser={appStore.isInternalUser}
                              isLaptop={isLaptop}
                              disableEdit={appStore.disableEdit}
                              toggleOtherSectionsEditBtn={() => appStore.setDisableEdit(!appStore.disableEdit)}
            />
        </ProfileBox>
    )
};

