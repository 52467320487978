import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {
    ComponentTitle,
    ContactSupportComponent, ContactSupportComponent2,
    InfoText,
    LoginHrefComponent
} from "../util/helper";
import {trackCtaEvent} from "../../utils/Utils";

export const ResetPasswordResponseComponent = (props) => {
    let configData = resetPasswordResponse[props.statusCode];
    let resetPasswordResponseConfig = configData ? configData : resetPasswordResponse["SYSTEM_ERROR"];
    const SupportComponent = resetPasswordResponseConfig.supportComponent ? resetPasswordResponseConfig.supportComponent : ContactSupportComponent;

    return (
        <Fragment>
            {
                resetPasswordResponseConfig.title && (<ComponentTitle text={resetPasswordResponseConfig.title}/>)
            }
            {
                resetPasswordResponseConfig.message && (resetPasswordResponseConfig.message)
            }
            {
                (!resetPasswordResponseConfig.loginBtnPlacement || resetPasswordResponseConfig.loginBtnPlacement !== 'center') && resetPasswordResponseConfig.loginButton &&
                <LoginHrefComponent/>
            }
            {<SupportComponent/>}
            {
                resetPasswordResponseConfig.loginBtnPlacement === 'center' && resetPasswordResponseConfig.loginButton &&
                <LoginHrefComponent/>
            }
        </Fragment>
    )
};


const ResetPasswordSuccessMessage = () => {
    return (

        <InfoText as="p">Your Qualcomm ID has been activated. You may now sign in to your account with your email
            address and
            password.</InfoText>
    )
};

const PasswordAlreadyUsedErrorMessage = () => {
    return (
        // eslint-disable-next-line
        <InfoText as="p">The password you attempted to use has been used before. Please use <a href="#" onClick={() => {trackCtaEvent('/resetPassword', 'The password you attempted to use has been used before.')}}>Click Here</a> to choose a unique, never-before used password.</InfoText>
    )
};

const ResetLinkAlreadyUsedErrorMessage = () => {
    return (
        <InfoText as="p">The password reset link has already been used. <Link to="/forgotPassword" onClick={() => {
            trackCtaEvent('/forgotPassword', 'The password reset link has already been used.')
        }}>Click Here</Link> to
            initiate a new password reset email.</InfoText>
    )
};

const ResetPasswordGenericErrorMessage = () => {
    return (
        <InfoText as="p">There is an issue with the system. Please try again later.</InfoText>
    )
};
const ResetPasswordTimeoutErrorMessage = () => {
    return (
        <InfoText as="p">The system needs a little more time to process your request. Please try login again after 60
            seconds to allow completion of your account password reset.</InfoText>
    )
};

const resetPasswordResponse = {
    200: {
        title: "You’ve successfully reset your password.",
        message: <ResetPasswordSuccessMessage/>,
        loginButton: true
    },
    8014: {title: "Reset Password Status", message: <PasswordAlreadyUsedErrorMessage/>, loginButton: false},
    8013: {title: "Reset Password Status", message: <ResetLinkAlreadyUsedErrorMessage/>, loginButton: false},
    500: {title: "Reset Password Status", message: <ResetPasswordGenericErrorMessage/>, loginButton: true},
    504: {
        title: "Reset Password Status",
        message: <ResetPasswordTimeoutErrorMessage/>,
        supportComponent: ContactSupportComponent2,
        loginButton: true,
        loginBtnPlacement: 'center'
    },
    SYSTEM_ERROR: {title: `Reset Password Status`, message: <ResetPasswordGenericErrorMessage/>, loginButton: true}
};