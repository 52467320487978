import React, {Fragment, useEffect, useState} from 'react';
import {Cta, InputComponent} from "@qc-modules/components";
import Loading from "../util/Loading";
import {ComponentTitle, InfoText, validatePassword} from "../util/helper";
import {ResetPasswordResponseComponent,} from "./ResetPasswordResponse";
import {getUrlParams, trackCtaEvent} from "../../utils/Utils";
import passwordService from "../../services/passwordService";
import usePreviousPage from "../../hooks/usePreviousPage";
import {trackEventAdobe} from "../../adobe/eventTrack";

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordSuccess, setPasswordSuccess] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordSuccess, setConfirmPasswordSuccess] = useState(false);

    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [responseStatusCode, setResponseStatusCode] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const previousPage = usePreviousPage();


    const handlePassword = (event) => {
        const password = event.target.value;
        const valid = validatePassword(password);

        setPassword(password);
        setPasswordError(!valid);
        setPasswordSuccess(valid);

        if (valid && confirmPassword) {
            const enableSubmission = validatePassword(confirmPassword) && password === confirmPassword;
            setConfirmPasswordError(!enableSubmission);
            setConfirmPasswordSuccess(enableSubmission);
        }
    };

    const handleConfirmPassword = (event) => {
        const confirmPassword = event.target.value;
        const valid = validatePassword(confirmPassword) && password === confirmPassword;

        setConfirmPassword(confirmPassword);
        setConfirmPasswordError(!valid);
        setConfirmPasswordSuccess(valid);
    };


    const requestWrapper = (callbackFunction) => async () => {
        setIsLoading(true);
        try {
            await callbackFunction();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const sendRequest = requestWrapper(async () => {
        try {
            const params = getUrlParams();
            const token = decodeURIComponent(params.token);
            const email = params.email
            trackCtaEvent('/resetPassword', 'ResetPassword')
            const responseCode = await passwordService.resetPassword({password, confirmPassword, email, token});
            trackEventAdobe({'reset_password': 'reset_password'})
            setResponseStatusCode(responseCode);
            setRequestSubmitted(true);
            // trackFormEvent('form submit', 'ResetPassword', 'Continue', '/resetPassword')

        } catch (e) {
            setResponseStatusCode("SYSTEM_ERROR");
            setRequestSubmitted(true);
            // trackFormEvent('form submit error', 'ResetPassword', e.message)

        }
    });
    useEffect(() => {
        const metaTags = [{
            name: 'googlebot',
            content: "noindex"
        },
            {
                name: 'robots',
                content: "noindex"
            }];
        metaTags.forEach(tag => {
            var m = document.createElement('meta');
            m.name = tag.name;
            m.content = tag.content;
            document.head.appendChild(m);
        })

        // trackPageLoadEvent('resetPassword', 'ResetPassword', previousPage, {}, {})
        window.pageChange('resetPassword', 'ResetPassword', previousPage, {}, {})
        // trackFormEvent('form view', 'ResetPassword', 2)
    }, [previousPage]);

    const submitHandler = (event) => {
        event.preventDefault();
        passwordSuccess && confirmPasswordSuccess && sendRequest()
    };

    return (
        <Fragment>
            {isLoading && <Loading/>}
            {!requestSubmitted
                ?
                (
                    <Fragment>
                        <ComponentTitle text={"Reset your password"}/>
                        <InfoText as="p"> Create a new password below. You will be redirected to the login
                            page.</InfoText>
                        <form onSubmit={submitHandler} className="Bizible-Exclude">

                            <InputComponent
                                error={passwordError}
                                id={'password'}
                                label={'Password*'}
                                labelPosition={'placeholder'}
                                onChange={handlePassword}
                                required={true}
                                success={passwordSuccess}
                                type={'password'}
                                value={password}
                                width={1}
                                bottomLabel={"Password must be at least 8 characters, contain at least one uppercase letter, contain at least one lowercase letter and at least one number."}
                                mt={4}
                            />

                            <InputComponent
                                error={confirmPasswordError}
                                id={'confirmPassword'}
                                label={'Confirm Password*'}
                                labelPosition={'placeholder'}
                                onChange={handleConfirmPassword}
                                required={true}
                                success={confirmPasswordSuccess}
                                type={'password'}
                                value={confirmPassword}
                                width={1}
                                mt={4}
                            />

                            <Cta id="continuebtn"
                                 disabled={!(passwordSuccess && confirmPasswordSuccess)}
                                 my={4}
                            >
                                Continue
                            </Cta>

                        </form>
                    </Fragment>
                )
                :
                <ResetPasswordResponseComponent statusCode={responseStatusCode}/>
            }
        </Fragment>
    )
};

export default ResetPassword;