import React from 'react';
const RegistrationSuccess = (responseMessage) => {

    const continueUrl = responseMessage.location.state.continue;

    return (
        <>
            <h3>Your registration request was submitted</h3>

            {continueUrl && <a href={continueUrl} target="_blank" rel="noopener noreferrer">Continue</a>}
            {!continueUrl && <h1>Your registration was submitted and your account is being evaluated.</h1>}
        </>
    )
};

export default RegistrationSuccess;