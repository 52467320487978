import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
// import useFetchPreferences from "./useFetchPreferences";
import {
  useFetchAvailableQnsSettings,
  useFetchAvailableSubscriptions,
  useFetchUserOptedQnsSettings,
  useFetchUserOptedSubscriptions,
  useFetchUserQnsSubscriptions
} from "./useFetchPreferences";
import Loading from "../util/Loading";
import {PreferencesSection} from "./PreferencesSection";
import {ErrorText, isValidImpersonation, processPreferences, processQnsProductPreferences} from "../util/helper";
import {PreferencesConfig, profileConstants} from "../util/appConstants2";
import {ProductPreferencesSection} from "./ProductPreferencesSection";
import AppContext from "../../context/AppContext";


const StyledPreferencesSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Preferences2 = ({userData}) => {

    const {data: userOptedSubscriptions, error: userOptedError, refetch: refreshUserData, isFetching: isOptedFetching, dataUpdatedAt: dataUpdatedAtOptedSubscr} = useFetchUserOptedSubscriptions();
    const {data: userAvailableSubscriptions, error: userAvailError, isFetching: isAvailableFetching} = useFetchAvailableSubscriptions();
    const {data: userOptedQnsSettings, error: userOptedQnsSettingsError, refetch: refreshUserQnsSettings, isFetching: isOptedQnsSettingsFetching, dataUpdatedAt: dataUpdatedAtOptedQnsSettings} = useFetchUserOptedQnsSettings();
    const {data: userAvailableQnsSettings, error: userAvailQnsSettingsError, isFetching: isAvailableQnsSettingsFetching} = useFetchAvailableQnsSettings();
    const {data: userQnsSubscriptions, error: userQnsSubscriptionsError, refetch: refreshUserSubscriptions, isFetching: isUserQnsSubscriptionsFetching} = useFetchUserQnsSubscriptions();
    const context = useContext(AppContext);
    let appStore;
    if(context) {
      appStore = context.appStore;
    }
    const previousPage = '/profile';

    const impersonationStatus = appStore.impersonationStatus ?? '';
    useEffect(() => {
        let visitor = {}
        if (userData !== null && userData !== undefined) {
            visitor = {
                'country_code': userData.country_code ?? 'US',
                'org_id': userData.org.orgId ?? '',
                'org_name': userData.org.name ?? '',
                'qcguid': userData.qcGuid ?? '',
                'user_name': userData.username ?? '',
                'loggedIn': "logged_in",
                'user_domain': userData.username && userData.username.indexOf("@") > -1 ? userData.username.slice(userData.username.indexOf("@") + 1) : 'qualcomm.com'
            }
            if (isValidImpersonation(impersonationStatus)) {
                visitor['impersonating'] = true
                visitor['impersonator'] = impersonationStatus.impersonator
            }
            // trackPageLoadEvent('profile', 'Subscriptions', previousPage, visitor, {})
            window.pageChange('profile', 'Subscriptions', previousPage, visitor, {})
        }

    }, [userData, previousPage, impersonationStatus]);


    const refreshWholeData = () => {
      refreshUserQnsSettings()
      refreshUserSubscriptions()
    }

    const [activeSection, setActiveSection] = useState();
    let processedData, processedQnsData;

    if(userOptedSubscriptions && userAvailableSubscriptions) {
      processedData = processPreferences({
        userOptedSubscriptions,
        userAvailableSubscriptions
      }, [PreferencesConfig.wirelessAcademy, PreferencesConfig.newsLetters, PreferencesConfig.updates])
    }

    if(userOptedQnsSettings && userAvailableQnsSettings) {
      processedQnsData = processQnsProductPreferences({userOptedQnsSettings, userAvailableQnsSettings})
    }
    const toggleEdit = (value) => {
      setActiveSection(value)
    };

    return <>
      {(isOptedFetching || isAvailableFetching || isOptedQnsSettingsFetching || isAvailableQnsSettingsFetching || isUserQnsSubscriptionsFetching) &&
      <Loading/>}

      {(userOptedError || userAvailError || userOptedQnsSettingsError || userAvailQnsSettingsError || userQnsSubscriptionsError) &&
      <ErrorText w={100}>{profileConstants.SYSTEM_ERROR}</ErrorText>}

      {!userOptedError && !userAvailError && !userAvailQnsSettingsError && !userAvailQnsSettingsError && !userQnsSubscriptionsError &&
      <StyledPreferencesSection>
        <PreferencesSection type={PreferencesConfig.newsLetters}
                            isEdit={((appStore.isInternalUser && isValidImpersonation(appStore.impersonationStatus)) || !appStore.isInternalUser) && activeSection === PreferencesConfig.newsLetters}
                            disableEdit={appStore.isInternalUser?(isValidImpersonation(appStore.impersonationStatus)?false:true):false  }
                            refetch={refreshUserData}
                            disableSave={isValidImpersonation(appStore.impersonationStatus)}
                            areaOfInterestOptIn={processedData && processedData.areaOfInterestOptIn}
                            selectedApiData={processedData ? processedData[PreferencesConfig.newsLetters] : []}
                            dataUpdatedAt={dataUpdatedAtOptedSubscr || new Date().getTime()}
                            toggleEdit={toggleEdit}
                            userData={userData}/>
        <ProductPreferencesSection type={PreferencesConfig.alerts}
                                   isEdit={activeSection === PreferencesConfig.alerts}
                                   refetch={refreshWholeData}
                                   disableSave={isValidImpersonation(appStore.impersonationStatus)}
                                   availableQnsSettings={processedQnsData}
                                   optedQnsSettings={processedQnsData}
                                   userData={userData}
                                   userQnsSubscriptions={userQnsSubscriptions}
                                   isDataLoading={isOptedQnsSettingsFetching && isAvailableQnsSettingsFetching && isUserQnsSubscriptionsFetching}
                                   dataUpdatedAt={dataUpdatedAtOptedQnsSettings || new Date().getTime()}
                                   toggleEdit={toggleEdit}/>
      </StyledPreferencesSection>}
    </>


  }
;
