import {useQuery,} from 'react-query'
import {fetchSubscriptions, getPreferences, getQnsSettings} from "../../api/profile-api";
import {getConfigData, getQnsInfo} from "../../api/common-api";


const preferencesApi = async () => {
  const data = await Promise.all([getPreferences(), getConfigData('areaOfInterest, applicationArea,wirelessAcademy')])
  return data
}
export const useFetchPreferences = () => useQuery('user-preferences', preferencesApi,);


const preferencesApiAvailableSubscriptions = async () => {
 const data = await  getConfigData('areaOfInterest, applicationArea,wirelessAcademy')
 return data
};
export const useFetchAvailableSubscriptions = () => useQuery('available-subscriptions', preferencesApiAvailableSubscriptions);

const preferencesApiUserOptedSubscriptions = async () => {
 const data = await  getPreferences()
 return data
};

export const useFetchUserOptedSubscriptions = () => useQuery('user-opted-subscriptions', preferencesApiUserOptedSubscriptions);

const preferencesApiAvailableQnsSettings = async () => {
 const data = await getQnsInfo('qnsDocTypes, qnsDocClassifications, qnsFrequencies')
 return data
};
export  const useFetchAvailableQnsSettings = () => useQuery('available-qns-settings', preferencesApiAvailableQnsSettings);

const preferencesApiUserQnsSubscriptions = async () => {
  const data = await fetchSubscriptions()
  return data
};
export  const useFetchUserQnsSubscriptions = () => useQuery('user-qns-subscriptions', preferencesApiUserQnsSubscriptions)


const preferencesApiUserOptedQnsSettings = async () => {
 const data = await getQnsSettings()
 return data
};
export const useFetchUserOptedQnsSettings = () => useQuery('user-opted-qns-settings', preferencesApiUserOptedQnsSettings);
