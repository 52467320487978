import React from 'react';
import styled from "styled-components";
import {Box, Flex} from "@qc-modules/components";
import Loading from "../util/Loading";

export const StyledPanelHeader = styled(Flex)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom:  ${props => !props.isLaptop ? "40px" : "0px"} ;
    width: 100%;
`;

const SlidePanelWrapper = styled(Box)`

@keyframes show {
  0% {
    display: none;
  }
  1% {
    display: block;
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes hide {
  100% {
    transform: translateX(100%);
  }
}
    padding: 20px; 
    background: white;
    height: 100vh;
    box-shadow: 2px 0 7px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 2000;
    overflow-y: scroll;
    ${({show}) => {
    if (show === 'show') {
        return `
        display:block;
        animation: show .3s ease-in;
        `
    } else {
        return `
        display:none;
        animation: hide .3s ease-out;
    `
    }
}}
`;



export const ProfileSlidePanel = ({component: ComponentToWrap, ...rest}) => {
    const {store, ...routeProps} = rest;

    return (
        <SlidePanelWrapper {...rest} {...routeProps}>
            {store && store.isLoading && <Loading/>}
            <ComponentToWrap {...rest} {...routeProps} />
        </SlidePanelWrapper>
    );
};