import React, {Fragment, useContext, useEffect} from 'react';
import styled from 'styled-components';
import {iconMachineNameMap} from "@qc-modules/components";

import { device} from '../../../lib/breakpoints'
import {FooterCopyright, FooterLinks} from "./ProfileFooter";
import {FooterLegalText} from "../common";
import AppRouter from "../../../routers/AppRouter";
import shield from "../../../images/icon-shield-person.svg";
import {colors} from "@qc-modules/styles";
import history from "../../../context/History";
import {HeaderLabel} from "./AuthHeaderLabel";
import AppContext from "../../../context/AppContext";
import {
    EmailContainer,
    LogoContainer,
    OrgContainer,
    ShieldContainer,
    TitleContainer
} from "./ProfileHeader";
import {
    MobileEmailContainer,
    MobileLogoContainer,
    MobileLogoutButton,
    MobileShieldContainer,
    MobileTitleContainer
} from "./ProfileHeaderMobile";
import {getTabs, isActiveTab, isValidImpersonation} from "../../util/helper";
import {trackCtaEvent, trackScrollEvent} from "../../../utils/Utils";

const LogoQualcommIcon = iconMachineNameMap["logo-qualcomm"];

const BackgroundBox1 = styled.div`
        background-color: #3253DC;
        grid-column: 1/9;
        grid-row: 1/2;
        display:block;
        
   @media ${device.tablet} {
   grid-column: 1/11;
   }

   @media ${device.laptop} {
    display: none;
    }
`;

const BackgroundBox2 = styled.div`
        background-color: #3253DC;
        grid-column: 1/9;
        grid-row: 2/6;
        display:block;
        
   @media ${device.tablet} {
   grid-column: 1/11;
   }
   
   @media ${device.laptop} {
    display:none;
    }
`;


const AppContainer = styled.div`
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:  hidden;
    
    overflow: auto;
    height: 100vh;
    display: grid;
    grid-template-columns: 24px repeat(6, 1fr) 24px;
    grid-template-rows: 85px 30px fit-content(85px) fit-content(60px) 30px auto auto fit-content(175px);
    
    @media ${device.tablet} {
        grid-template-columns: 48px repeat(8, 1fr) 48px;
    }

    @media ${device.laptop} {
        margin-left: 310px;
        grid-template-columns: 48px 48px repeat(12, 1fr) 48px 48px;
        grid-template-rows: 90px 90px 100px fit-content(60px) fit-content(60px) auto auto fit-content(175px);
    }
`;


const Logo = styled(LogoQualcommIcon)`
  fill: ${props => colors("white", props)};
   width: 120px;
`;

const ContentContainer = styled.div`
    grid-row: 7/8;
    grid-column: 2/8;
    
   @media ${device.tablet} {
   grid-column: 3/9;
   }

   @media ${device.laptop} {
   grid-column: 3/15;
   grid-row: 2/8;
   margin-bottom: 90px;
   max-width: 750px;
   }

   @media ${device.desktop} {
   grid-column: 4/14;
   }
`;

const FooterContainer = styled.div`
    grid-area: footer;
    display: flex;
    flex-direction: column;
    
    grid-column: 2/8;
    grid-row: 8/9;
    align-self: end;
    
   @media ${device.tablet} {
   grid-column: 3/9;
   }

   @media ${device.laptop} {
    grid-row: 8/9;
   grid-column: 3/15;
   margin-bottom: 24px;
   padding: 0;
   max-width: 750px;
   }

    @media ${device.desktop} {
   grid-column: 4/14;
   }
`;

const AuthHeaderLabelsContainer = styled.div`
    display: none;
    
     @media ${device.laptop} {
   display: flex;
   margin-top: 48px;
   width: 85%;
   flex-direction: column;
   align-self: flex-end;
  }

`;

const HeaderContainer = styled.div`
display: none;

 @media ${device.laptop} {
    display: block;
    width: 310px;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3253DC;
    }
`;

const HeaderContentContainer = styled.div`
    width: 310px;
    height: 100vh;
    position: fixed;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
     background-color: #3253DC;
`;


export const ProfileLayout = () => {


    const {appStore} = useContext(AppContext);
    const {auth} = useContext(AppContext);
    const logoutHandler = () => {
        trackCtaEvent('/logout', 'Sign out', {})
        const isvalid = isValidImpersonation(appStore.impersonationStatus)
        if(!isvalid){
            history.push("/logout")
        }
    };
    useEffect(() => {

        const handleScroll = (e) => {
            trackScrollEvent(e.target, "Profile")
        };
        document.getElementById("appConttainer").addEventListener('scroll', handleScroll, true);
    },[]);



    return (
        <Fragment>
            <HeaderContainer>
                <HeaderContentContainer>
                    <LogoContainer><Logo/></LogoContainer>
                    <ShieldContainer><img src={shield} alt="Qualcomm ID Shield"/></ShieldContainer>
                    { auth.actingUser && auth.actingUser  &&<TitleContainer>{auth.actingUser.firstName} {auth.actingUser.lastName}</TitleContainer>}
                    {auth.actingUser &&<EmailContainer>{auth.actingUser.username}</EmailContainer>}
                    <OrgContainer></OrgContainer>


                    <AuthHeaderLabelsContainer>
                        {getTabs(appStore).filter(obj=>obj.position!=='end').map(tab => {
                            return <HeaderLabel {...tab} isActive={isActiveTab(appStore, tab.tabRef)}/>
                        })}
                    </AuthHeaderLabelsContainer>
                     {getTabs(appStore).filter(obj=>obj.position==='end').map(tab => {
                        return  <HeaderLabel {...tab} isActive={isActiveTab(appStore, tab.tabRef)} setOpacity={0.75}/>
                    })}

                </HeaderContentContainer>
            </HeaderContainer>
            <AppContainer id={"appConttainer"}>
                <BackgroundBox1/>
                <BackgroundBox2/>


                <MobileLogoContainer><Logo/></MobileLogoContainer>
                <MobileLogoutButton
                    onClick={logoutHandler}>
                    Sign Out
                </MobileLogoutButton>

                <MobileShieldContainer><img src={shield} alt="Qualcomm ID Shield"/></MobileShieldContainer>
                { auth.actingUser && auth.actingUser  && <MobileTitleContainer>{auth.actingUser.firstName} {auth.actingUser.lastName}</MobileTitleContainer>}
                {auth.actingUser && <MobileEmailContainer>{auth.actingUser.username}</MobileEmailContainer>}

                <ContentContainer>
                    <AppRouter/>
                </ContentContainer>

                <FooterContainer>
                    <FooterLinks/>
                    <FooterCopyright/>
                    <FooterLegalText/>
                </FooterContainer>
            </AppContainer>
        </Fragment>
    )
};
