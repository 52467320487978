import React, {Fragment, useContext, useState} from 'react';
import {Box} from "@qc-modules/components";
import {panelMappingConstants} from "../util/appConstants2";
import {ProfileSlidePanel} from "./ProfileSlidePanel";
import Loading from "../util/Loading";
import AppContext from "../../context/AppContext";
import {ProfileMobileContainer} from "./ProfileMobileContainer";
import {ErrorText} from "../util/helper";
import {breakPointValue, device} from "../../lib/breakpoints";
import styled from "styled-components";
import AppSwitcher from "./appSwitcher2/AppSwitcher";


const EmptyComponent = () => {
    return (
        <div></div>
    )
};

export const CollapsedContainer = (props) => {
    const {appStore} = useContext(AppContext);

    const {profileStore, deactivateStore} = props;
    const defaultGridColumn = '3/11';
    const fullWidthGridColumn = '1/11';
    const defaultSliderWidth = '950px';

    const [gridColumn, setGridColumn] = useState('');
    const [divWidth] = useState(defaultSliderWidth);
    const [flexGrowSize, setFlexGrowSize] = useState(0);

    const panelMapping = panelMappingConstants.PanelMapping;

    const panelHandler = (componentName) => {
        appStore.setComponentPathInfo(componentName);
        appStore.setShowPanel('show');
        appStore.setProfileTabPath(componentName);
        appStore.setIsMobileNonHeaderView(true);

        //Open panel in edit mode -> get the Panel that need to be turn to EDIT mode
        // const panelInfo = panelMapping.find((panelItem) => panelItem.tabPath === componentName);
        //  profileStore.toggleEditMode(panelInfo.toggleEditConstant, true)
    };

    let storeToUse = profileStore;
    let compArgs = {};
    const findComponentToRender = () => {
        let panelComponent = EmptyComponent;

        if (appStore.componentPathInfo) {
            const panelInfo = panelMapping.find((panelItem) => panelItem.tabPath === appStore.componentPathInfo);
            panelComponent = panelInfo.component;
            storeToUse = panelInfo.store && panelInfo.store === 'profileStore' ? profileStore : deactivateStore;
            compArgs = panelInfo.args || {}
        }
        return panelComponent;
    };

    const PanelComponentToRender = findComponentToRender();
    const afterChangeCallback = (current) => {
        if (current !== 0 && window.innerWidth > breakPointValue.tablet) {
            setFlexGrowSize(1);
            setGridColumn(fullWidthGridColumn);
        } else {
            setFlexGrowSize(0);
            updateGrid();
        }
    };
    const updateGrid = () => {
        if (window.innerWidth < breakPointValue.tablet) {
            setGridColumn(fullWidthGridColumn)
        } else {
            setGridColumn(defaultGridColumn)
        }
    };

    window.addEventListener("resize", updateGrid);
    const AppSwitcherContainerWrapper = styled.div`
    grid-row: 6/7;
    grid-column: ${gridColumn};
    min-width: 0;
    min-height: 350px;
    display: flex;
    justify-content: flex-start;
    
    @media ${device.tablet} {
        min-height: 300px;
    }
`;

    const StyledAppSwitcherContainer = styled(Box)`
    flex-direction: column;
    flex-basis: ${divWidth};
    flex-grow: ${flexGrowSize};
    transition: flex-grow .9s ease-in-out
    min-width: 0;
    min-height: 0;
  
    @media ${device.laptop} {
        display: none;
    }
`;

    return (
        <Fragment>
            {profileStore.isLoading && <Loading/>}

            {profileStore.isProfileFailedToLoad ?
                (
                    <Box>
                        {profileStore.apiErrorMessages.common && profileStore.apiErrorMessages.common.map(err => (
                            <ErrorText w={100}>{err}</ErrorText>)
                        )}
                    </Box>
                )
                :
                (
                    <Fragment>

                        {profileStore.userData &&

                        <AppSwitcherContainerWrapper>
                            <StyledAppSwitcherContainer>
                                <AppSwitcher userData={profileStore.userData}
                                             applicationData={profileStore.userData.appDetails}
                                             gridHandler={afterChangeCallback}/>

                            </StyledAppSwitcherContainer>
                        </AppSwitcherContainerWrapper>
                        }
                        <ProfileMobileContainer appStore={appStore} panelHandler={panelHandler}/>

                        {PanelComponentToRender &&
                        <ProfileSlidePanel showSave={false} component={PanelComponentToRender} show={appStore.showPanel}
                                           userData={profileStore && profileStore.userData}
                                           store={storeToUse} {...compArgs}
                        />}

                    </Fragment>
                )}
        </Fragment>
    )
};