import axios from 'axios';
import {registrationDefaults, apiEndpoint} from '../config';
import {validateDomainsListForPartnerDomains} from "../components/util/validation/ValidationFunctions";

const SALESCENTER_APP = 'salescenter';

export const submitSignUp = async (data) => {
    if(data.app === SALESCENTER_APP) {
        const emailIsPublicDomain = !validateDomainsListForPartnerDomains(data.email, data.domains);
        if (emailIsPublicDomain) {
            data.app = 'www'
        }
    }

    const requestConfig = {
        method: 'post',
        url: apiEndpoint.registrationSignupEndpoint,
        data
    };

    return await axios(requestConfig);
};

export const submitRegistration = async (data) => {
    const requestConfig = {
        method: 'post',
        url: apiEndpoint.registrationSubmitEndpoint,
        data
    };

    return await axios(requestConfig);
};

export const checkRegistrationStatus = async (data) => {
    const NUM_RETRIES = 15;
    const DELAY_BETWEEN_RETRIES_MS = 5000;

    const {email, app, token} = data;
    let statusResponse;

    for (let i = 0; i < NUM_RETRIES; ++i) {
        await new Promise(resolve => setTimeout(resolve, DELAY_BETWEEN_RETRIES_MS));
        statusResponse = await axios.get(`${apiEndpoint.registrationStatusEndpoint}/${email}/${app}?token=${token}`);

        if (statusResponse.data.status.toLowerCase() !== 'processing') {
            break;
        }
    }

    return await statusResponse;
};

export const validateToken = async (data) => {
    const requestConfig = {
        method: 'post',
        url: apiEndpoint.registrationValidateTokenEndpoint,
        data
    };

    return await axios(requestConfig);
};

export const retrieveAppForTargetUrl = async (targetUrl) => {
    let app = registrationDefaults.defaultApp;

    if (!targetUrl) {
        return app;
    }

    const response = await axios.get(`${apiEndpoint.targetUrlEndpoint}?target=${targetUrl}`);

    if (response && response.data.app) {
        app = response.data.app;
        return app
    }

    return app;
};

export const getSignUpData = async(app, targetUrl)=> {
    if(app) {
        return await axios.get(`${apiEndpoint.signUpDataEndpoint}/app/${app}`);
    }

    if(targetUrl) {
        let encodedTarget = encodeURIComponent(targetUrl);
        return await axios.get(`${apiEndpoint.signUpDataEndpoint}/target/${encodedTarget}`);
    }

    return await axios.get(`${apiEndpoint.signUpDataEndpoint}`);
}