import React, {Fragment, useEffect} from 'react';
import {ComponentTitle, InfoText, SupportComponent,} from "../util/helper";
import {trackCtaEvent} from "../../utils/Utils";

export const ForgotPasswordResponse = (props) => {
    let configData = forgotPasswordResponse[props.statusCode];
    let forgotPasswordResponseConfig = configData ? configData : forgotPasswordResponse["SYSTEM_ERROR"];
    useEffect(() => {
        // trackPageLoadEvent('forgotPassword', 'ForgotPassword', 'forgotPassword', {}, {})
        window.pageChange('forgotPassword', 'ForgotPassword', 'forgotPassword', {}, {})

    }, []);

    return (
        <Fragment>
            {
                forgotPasswordResponseConfig.title &&
                (<ComponentTitle text={forgotPasswordResponseConfig.title}/>)
            }
            {
                forgotPasswordResponseConfig.message && (forgotPasswordResponseConfig.message)
            }
        </Fragment>

    )
};


const ForgotPasswordSuccessMessage = () => {
    return (
        <Fragment>
            <InfoText as="p">Please check your inbox for a confirmation email with a link to reset your Qualcomm ID
                password.</InfoText>
            <InfoText as="p">Didn’t receive the email? <a href="/forgotPassword" onClick={() => {
                trackCtaEvent('/forgotPassword', 'Request it again.')
            }}>Request it again.</a></InfoText>
        </Fragment>
    )
};

const ForgotPasswordFailureMessage = () => {
    return (
        <InfoText as="p">If you have any questions about your registration, you can send an email
            to.<SupportComponent/></InfoText>
    )
};
const ForgotPasswordLimitMessage = () => {
    return (
        <InfoText as="p">You have exceeded the number of password resets allowed for the current time period. Please
            attempt resetting your password later.</InfoText>
    )
};

const forgotPasswordResponse = {
    200: {title: `Password Reset email sent`, message: <ForgotPasswordSuccessMessage/>},
    500: {title: `Password Reset Failed`, message: <ForgotPasswordFailureMessage/>},
    400: {title: `Too many password reset attempts`, message: <ForgotPasswordLimitMessage/>},
    SYSTEM_ERROR: {title: `Password Reset Failed`, message: <ForgotPasswordFailureMessage/>}
};