import React, {useContext} from "react";
import {useMediaQuery} from "react-responsive";
import {device} from "../../../lib/breakpoints";
import { ProfileBox} from "../../util/helper";
import {EmailAndPasswordInfoForm} from "./EmailAndPasswordInfoForm";
import AppContext from "../../../context/AppContext";

export const EmailAndPasswordInfoSection = (props) => {
    const {store} = props;
    const userData = store.userData;
    const isLaptop = useMediaQuery({query: device.laptop});
    const {appStore} = useContext(AppContext);
    // const previousPage = usePreviousPage();

    const formValues = {
        email: userData.username,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        username: userData.username
    };



    return (
        <ProfileBox>
            <EmailAndPasswordInfoForm formValues={formValues}
                                      isLaptop={isLaptop}
                                      isInternalUser={appStore.isInternalUser}
                                      saveData={store.savePasswordApi}
                                      disableEdit={appStore.disableEdit}
                                      toggleOtherSectionsEditBtn={() => appStore.setDisableEdit(!appStore.disableEdit)}
            />
        </ProfileBox>
    )

}
