import React from 'react';
import {Glyph} from '@qc-modules/components';
import styled from "styled-components";

const StyledGlyph = styled(Glyph)`
    display: inline;
    height: 16px;
    margin-right: 4px;
    
`;

const StyledMessageWrapper = styled.div`
    font-size: 16px;
    margin: 2px 0;    
`;

const StyledMessage = styled.div`
    display: flex;
    align-items: center; 
    margin-bottom: 10px;
`;

export const StyledPasswordMessage = styled.div`
    min-height: 30px;
`;

export const MessageWrapper = ({children}) => (
    <StyledMessageWrapper>{children}</StyledMessageWrapper>
);

export const StyledErrorMessage = styled(StyledMessage)`
    color: #E0182D;
`;

export const StyledValidMessage = styled(StyledMessage)`
    color: #5AA700;
`;

export const getInvalidGlyph = () => {
    return (
        <StyledGlyph id={'close'} fill={'#E0182D'}/>
    )
};

export const getValidGlyph = () => {
    return (
        <StyledGlyph id={'check'} fill={'#5AA700'}/>
    )
};

