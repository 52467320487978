import React from 'react';
import {InputComponent, SelectComponent, Checkbox} from "@qc-modules/components";

const InputWrapper = ({field, form, ...props}) => {
    return (
        <InputComponent {...field} {...props}
                        error={Boolean(form.touched[field.name]) && Boolean(form.errors[field.name])}
                        success={Boolean(form.touched[field.name]) && Boolean(!form.errors[field.name])}
        />
    )
};

const SelectWrapper = ({field, form, ...props}) => {
    return (
        <SelectComponent {...field} {...props}
                         error={Boolean(form.touched[field.name]) && Boolean(form.errors[field.name])}
                         success={Boolean(form.touched[field.name] && !form.errors[field.name])}
        />

    )
};

const CheckboxWrapper = ({field, form, ...props}) => {
    return (
        <Checkbox {...field} {...props}
                  checkboxOnChange={form.handleChange}
                  isChecked={form.values[field.name]}
                  error={Boolean(form.touched[field.name]) && Boolean(form.errors[field.name])}
                  success={Boolean(form.touched[field.name] && !form.errors[field.name])}
        />


    )
};

export const WrappedFormComponents = {
    InputWrapper: InputWrapper,
    SelectWrapper: SelectWrapper,
    CheckboxWrapper: CheckboxWrapper
};