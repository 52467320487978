import React, {useEffect, useState} from 'react';
import {Redirect, Route, Router, Switch,  useLocation} from "react-router-dom";
import history from "../context/History";
import {getLoginUrl, getTargetUrl, logout, trackScrollEvent} from '../utils/Utils';
import ForgotPassword from "../components/forgotPassword/ForgotPassword";
import ResetPassword from "../components/resetPassword/ResetPassword";
import Login from "../components/login/Login";
import Welcome from "../components/welcome/Welcome";
import ProtectedRoute from '../routers/ProtectedRoute';
import Loading from '../components/util/Loading';
import ErrorPage from "../components/common/ErrorPage";
import SignUp from "../components/register2/signup/SignUp";
import Registration from "../components/register2/registration/Registration";
import ResponsePage from "../components/common/ResponsePage";
import RegistrationSuccess from "../components/common/RegistrationSuccess";
import {SignUpSuccessPage} from "../components/register2/signup/SignUpResponse";
import TokenRequest from "../components/register2/token/TokenRequest";
import {
    AvailableRegistrationResponse,
    errorRegistrationResponse,
    HasRoleResponse,
    inProgressRegistrationResponse
} from "../components/register2/registration/RegistrationResponse";
import {default as UserDetails2} from "../components/profile2/UserDetails";
import SalesAccessRequestLanding from "../components/register2/response/SalesAccessRequestLanding";
import {profileConstants} from "../components/util/appConstants2";
import {getUserTargetUrl} from "../api/profile-api";

const targetUrl = (currentRoute) => {
    const url = `${getLoginUrl()}?state=${currentRoute}`;
    return (url);
};

const redirectToOauth = (returnUrl) => {
    window.location = targetUrl(returnUrl);
};

const redirectToTarget = async () => {
    const userTargetUrl = await getUserTargetUrl()
    if (userTargetUrl && userTargetUrl !== "") {
        window.location.href = userTargetUrl
    } else {
        window.location.pathname = "/"
    }

};

const redirectToLogout = () => {
    logout(getTargetUrl())

};
const Routes = () => {
    const [returnUrl, setReturnUrl] = useState('');
    const location  = useLocation()
    const { pathname } = location

    useEffect(() => {
        const handleScroll = (e) => {
            trackScrollEvent(e.target, pathname)
        };
        window.addEventListener('scroll', handleScroll, true);
    },[pathname]);


    return (
        <Router history={history}>
            <Switch>
                <Route path="/" exact={true}>
                    <Redirect to={'/profile'}/>
                </Route>
                <Route path="/login" component={Login} exact={true}/>

                <Route path="/signup" component={SignUp} exact={true}/>
                <Route path="/signup/success" component={SignUpSuccessPage} exact={true}/>
                <Route path="/signup/error" component={ResponsePage} exact={true}/>
                <Route path="/registration" component={Registration} exact={true}/>
                <Route path="/registration/success" component={RegistrationSuccess} exact={true}/>
                <Route path="/registration/error" component={errorRegistrationResponse} exact={true}/>
                <Route path="/registration/token" component={TokenRequest} exact={true}/>
                <Route path="/registration/inprogress" component={inProgressRegistrationResponse} exact={true}/>
                <Route path="/registration/access" component={HasRoleResponse} exact={true}/>
                <Route path="/registration/confirmation" component={AvailableRegistrationResponse} exact={true}/>
                <Route path="/registration/salescenter/help" component={SalesAccessRequestLanding} exact={true}/>

                <Route path='/registration/continue' render={(props) => {
                    window.location = props.location.state;
                    return <Loading {...props} />
                }}/>


                <ProtectedRoute path="/welcome" component={Welcome} exact={true} setReturnUrl={setReturnUrl}/>

                <ProtectedRoute path="/profile" component={UserDetails2} exact={true} setReturnUrl={setReturnUrl}/>

                <Route path="/forgotPassword" component={ForgotPassword} exact={true}/>
                <Route path="/resetPassword" component={ResetPassword} />
                <ProtectedRoute isRedirect={true} path="/continue" exact={true} redirectToTarget={redirectToTarget}
                                setReturnUrl={setReturnUrl}/>

                <Route path='/auth' render={(props) => {
                    redirectToOauth(returnUrl);
                    return <Loading {...props} />
                }}/>

                <Route path='/logout' render={(props) => {
                    redirectToLogout(returnUrl);
                    return <Loading {...props} />
                }}/>

                <Route path='/static/registration/login.html' render={(props) => (
                    <Redirect to={`/login${props.location.search}`}/>
                )}/>

                <Route path="/static/registration/register.html" exact={true} render={(props) => (
                    <Redirect to={`/signup${props.location.search}`}/>
                )}/>

                <Route path="/register" exact={true} render={(props) => (
                    <Redirect to={`/signup${props.location.search}`}/>
                )}/>
                <Route path="/profile/alerts" exact={true} render={(props) => (
                    <Redirect to={`/notifications${props.location.search}`}/>
                )}/>
                <ProtectedRoute path="/notifications" exact={true} internalRedirect={true} setReturnUrl={setReturnUrl}
                                redirectObj={() => <Redirect to={{
                                    pathname: '/profile',
                                    activeTab: {
                                        desk: profileConstants.APP_TABS_STATE.ALERTS_TAB.TAB_PATH,
                                        mobile: profileConstants.APP_TABS_STATE.ALERTS_TAB.MOBILE_VIEW.PF_ALERTS
                                    },
                                }}/>}/>


                <Route path='/static/registration/multiOrgMatch.html' render={(props) => (
                    <Redirect to={`/multiOrgMatch${props.location.search}`}/>
                )}/>

                <Route path='/static/registration/verifyEmail.html' render={(props) => (
                    <Redirect to={`/verifyEmail${props.location.search}`}/>
                )}/>

                <Route path='/static/registration/confirmNoAccount.html' render={(props) => (
                    <Redirect to={`/confirmNoAccount${props.location.search}`}/>
                )}/>

                <Route path="/static/registration/welcome.html" exact={true}>
                    <Redirect to={'/welcome'}/>
                </Route>

                <Route path="/static/registration/forgotPassword.html" exact={true}>
                    <Redirect to={'/forgotPassword'}/>
                </Route>


                <Route path='/static/registration/resetPassword.html' render={(props) => (
                    <Redirect to={`/resetPassword${props.location.search}`}/>
                )}/>

                <Route path="/static/registration/deactivateAccount.html" render={(props) => (
                    <Redirect to={`/deactivate${props.location.search}`}/>
                )}/>

                <Route component={ErrorPage}/>
            </Switch>
        </Router>
    )
};

export default Routes;