import React, {useEffect, useRef} from 'react';
import styled from "styled-components"
import {Flex} from "@qc-modules/components";
import {colors} from "@qc-modules/styles";
import {ModalContainer} from "./modal";
import {ComponentParagraphText, StyledCta, StyledComponentItalicFooter} from "../../util/helper";
import {ESCAPE_KEY_CODE} from "./RegConstants";

const Modal = styled(Flex)`
    background-color: #FFF;
    flex-direction: column;
    align-items: center;
    width: 665px;
    height: 600px;
    border: 1px solid #677283;
    border-radius: 12px;
    padding: 60px 50px;
    min-width: 650px;
    z-index: 250;
    opacity: 1;
    justify-content: space-between;
`;

const StyledHeading = styled(Flex)`
  height: 60px;
  width: 452px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 30px;
  text-align: center;
  justify-content: center;
  padding: 30px auto;
`;


const StyledText = styled.div`
    color: #677283;
    margin-top: 2px;
    margin-bottom: 15px;
    min-width: 450px;
    width: 600px;
    max-width: 650px;
    &:hover {cursor: pointer};
    text-align: center;
`;

const StyledLink = styled(StyledText)`
    color: ${props => colors("blue", props)};
`;



const WarningModal = ({closeModal, warningText}) => {
    const modalRef = useRef(null);

    useEffect(() => {
        modalRef.current.focus()
    }, [modalRef]);

    const escapeHandle = (e) => {
        if (e.keyCode === ESCAPE_KEY_CODE) {
            closeModal(e);
        }
    };

    return (
        <>
            <ModalContainer onClick={(e)=>closeModal(e)} ref={modalRef} tabIndex={0} onKeyUp={(e) => escapeHandle(e)}>
                <Modal data-testid='warning-modal' onClick={(e)=>e.stopPropagation()}>
                    <StyledHeading>{warningText.title}</StyledHeading>
                    <ComponentParagraphText>{warningText.description}​</ComponentParagraphText>
                    <StyledCta onClick={(e) => closeModal(e)}>Use your company email address</StyledCta>
                    <StyledLink
                        onClick={(e) => closeModal(e, true)}>Continue with the email given</StyledLink>
                    <StyledComponentItalicFooter>Verifying your company for Sales Center access requires you to enter a
                        valid
                        company email address.</StyledComponentItalicFooter>

                </Modal>
            </ModalContainer>
        </>
    )
};

export default WarningModal;