import React from 'react';
import {FooterCopyrightText, FooterLinkConfig} from "../common";
import {FooterLink, FooterLinkContainer, StyledFooterCopyrightContainer} from "./FooterStyle";
import {trackFooterEvent} from "../../../utils/Utils";

export const FooterLinks = () => {
    const opacity = 1;
    const weight = 400;

    const linkOptions = {
        opacity,
        weight,
        tag: 'a',
        target: '_blank',
        rel: 'noopener noreferrer'
    };


    return (
        <FooterLinkContainer>
            {FooterLinkConfig.options.map((option) => {
                if (option.type === 'button') {
                    return <FooterLink id={option.id}
                                       className={option.className} {...linkOptions}/>
                }
                return <FooterLink key={option.id}
                                   href={option.href} {...linkOptions} onClick={()=>trackFooterEvent(option.displayText, option.href)}>{option.displayText}</FooterLink>
            })}
        </FooterLinkContainer>
    )
};


export const FooterCopyright = () => {
    return (
        <StyledFooterCopyrightContainer>
            <FooterCopyrightText/>
        </StyledFooterCopyrightContainer>
    )
};
