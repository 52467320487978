import React from 'react';

import useAuth  from '../hooks/useAuth';
import useAppStore  from '../hooks/useAppStore';
import AppContext from '../context/AppContext';
// import {loadTealiumScript} from "../tealium/tealium";


const AppDataProvider = ({children}) => {
    const auth = useAuth();
    const appStore = useAppStore();
     // loadTealiumScript();

    // const initializeData = () => {
      //  profileStore.getProfile();
      //  profileStore.loadProfileData();
        // profileStore.getJobFunctions();
        // profileStore.getCountries();
        // profileStore.getDeactivateReasons();
        // profileStore.getUsStates();
    // };


    // const resetToInitialState = () => {
    //     //TODO
    // };

    // useEffect(() => {
    //     if (auth.user) {
    //         initializeData();
    //     } else {
    //         resetToInitialState();
    //     }
    // }, [auth.user]);


    return (
        <AppContext.Provider value={{auth,appStore}}>
            {children}
        </AppContext.Provider>
    );
};

export {AppDataProvider};
