import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Flex} from '@qc-modules/components';
import {MobileTabBlock} from "./ProfileMobileBlock";
import {getTabs} from "../util/helper";

const ProfileBlockContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center
  width: 100%;
  padding-bottom: 40px;
`;

export const ProfileMobileContainer = (props) => {
    return (
        <Fragment>
            <ProfileBlockContainer>
                {getTabs(props.appStore).filter(obj=>!obj.hideInMobile).map(tab => {
                    return <MobileTabBlock {...tab} {...props}/>
                })}
            </ProfileBlockContainer>
        </Fragment>
    )
};
