import React, {useEffect} from 'react';
import {Form, withFormik} from "formik";
import {Cta} from "@qc-modules/components";
import {checkRegistrationStatus, submitRegistration} from "../../../api/register-api";
import Loading from "../../util/Loading";
import history from '../../../context/History';
import {ProcessingRegistrationResponse, RegistrationClickThrough,} from "./RegistrationResponse";

import ResponseModal from "../response/modal";
import {RegistrationFormFields} from "./RegistrationFormFields";
import QanModal from "../response/QanModal";
import SalesCenterModal from "../response/SalesCenterModal";
import {trackCtaEvent} from "../../../utils/Utils";
import usePreviousPage from "../../../hooks/usePreviousPage";


const RegistrationBasicForm = ({
                                   values,
                                   errors,
                                   touched,
                                   handleChange,
                                   handleSubmit,
                                   handleBlur,
                                   isSubmitting,
                                   status,
                                   serverApp,
                                   ...props
                               }) => {
    const previousPage = usePreviousPage();


    // const [showTierWarning, setShowTierWarning] = useState(false);
    useEffect(() => {
        // trackPageLoadEvent('/registration', 'Registration', previousPage, {}, {})
        window.pageChange('/registration', 'Registration', previousPage, {}, {})
        // trackFormEvent('form view', 'Registration', 6)
    }, [previousPage]);

    return (
        <>
            {status && status.showModal &&
                <ResponseModal message={ProcessingRegistrationResponse()} loading={status.loading}/>}

            {status && status.qan && <QanModal url={status.redirectUrl}/>}
            {status && status.salescenter && <SalesCenterModal url={status.redirectUrl}/>}
            {isSubmitting && <Loading/>}


            <Form className="Bizible-Exclude">
                {props.clickThrough && <RegistrationClickThrough {...props}/>}

                {!props.clickThrough && <RegistrationFormFields touched={touched} errors={errors} {...props}/>}

                <Cta version="secondary"
                     data-testid='submit-registration-button'
                     role='button'
                     disabled={isSubmitting}>Continue</Cta>

            </Form>
        </>
    )
};

const serverResponseStatus = (serverResponse) => {
    const {data} = serverResponse;
    return data.status ? data.status.toLowerCase() : 'continue'
};

const processStatusResponse = (retryStatus, url, dataVals) => {
    if (retryStatus === 'available') {
        // trackFormEvent('form submit', 'Registration', 'Continue', '/registration/confirmation')
        history.push('/registration/confirmation', {targetUrl: url});
    } else {
        // trackFormEvent('form submit', 'Registration', 'Continue', '/registration/inprogress')
        history.push('/registration/inprogress', {...dataVals});
    }
};

const RegistrationForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => {
        let valuesToMap = {
            firstName: props.firstName || '',
            lastName: props.lastName || '',

            password: props.password || '',
            termsOfService: props.termsOfService || false,
            optIn: props.optIn || false,
            token: props.token || '',
            app: props.app || '',
            email: props.email || '',
        };

        if (props.orgs.length === 1) {
            valuesToMap.exportId = props.orgs[0].value;
        } else if (props.orgs.length > 1) {
            valuesToMap.exportId = props.exportId ? props.exportId : '';
        }

        if (props.countries.length === 1) {
            valuesToMap.country = props.countries[0].value;
        } else if (props.countries.length > 1) {
            valuesToMap.country = props.country ? props.country : '';
        }

        return valuesToMap;
    },
    handleSubmit(values, {resetForm, setSubmitting, setStatus}) {
        const defaultStatus = 'ACCEPTED';
        const defaultUrl = 'https://www.qualcomm.com';

        if (values.exportId) {
            values.exportId = parseInt(values.exportId)
        }

        setStatus(
            {showModal: true, registrationStatus: 'processing', loading: true}
        );
        const dataVals = {app: values.app, email: values.email, token: values.token};
        trackCtaEvent('/submit', 'Registration')
        submitRegistration(values)
            .then((serverResponse) => {

                const {data} = serverResponse;

                if (data.showSalesCenterAccessRequest) {
                    // trackFormEvent('form submit', 'Registration', 'Continue', '/registration/salescenter/help')
                    history.push('/registration/salescenter/help');
                    return;
                }
                const regStatus = data.status ? data.status.toLowerCase() : 'continue';

                let url = data.continue ? data.continue : data.targetUrl;

                url = url ? url : defaultUrl;

                if (data.continue) {
                    // trackFormEvent('form submit', 'Registration', 'Continue', data.continue)
                    setStatus({qan: true, redirectUrl: data.continue});
                    return;
                }

                if (regStatus === 'processing') {

                    checkRegistrationStatus(dataVals).then((response) => {
                        let retryStatus = serverResponseStatus(response);

                        setStatus({showModal: false});
                        setSubmitting(false);
                        resetForm();

                        processStatusResponse(retryStatus, url, dataVals);
                    }).catch((err) => {
                        console.debug(err);
                        setStatus({showModal: false});
                        setSubmitting(false);
                        resetForm();

                        processStatusResponse(defaultStatus, defaultUrl, dataVals);
                    })
                } else {
                    processStatusResponse(regStatus, url, dataVals);
                }
            })
            .catch((err) => {
                console.debug(err);
                setStatus({showModal: false});
                setSubmitting(false);
                resetForm();
                // trackFormEvent('form submit error', 'Registration', err.message)
                history.push('/registration/error');
            })
    }
})(RegistrationBasicForm);

export default RegistrationForm;

