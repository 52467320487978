import React, {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {Button} from "@qc-modules/components";
import {colors} from "@qc-modules/styles";
import {ComponentTitle, InfoText, SupportComponent} from "../util/helper";
import Loading from "../util/Loading";
import Oops from "../common/Oops";
import authService from "../../services/authService";
import AppContext from '../../context/AppContext';
import {getUserAllowedApplications} from "../../api/profile-api";


const Welcome = () => {
        const [responseCode, setResponseCode] = useState();
        const [isLoading, setIsLoading] = useState(false);
        const [firstName, setFirstName] = useState('');
        const [appDetails, setAppDetails] = useState([]);
        const {auth} = useContext(AppContext);

        useEffect(() => {
            fetchUserWelcomeData()
        }, []);

        const ActionLink = styled(Button)`
        font-size: 16px;
        color: ${props => colors("blue", props)};
        
        ${({disabled}) => {
            if (disabled) {
                return `opacity: 0.6; cursor: default;`
            }
            return `&:hover {color: #1C37A5}; cursor: pointer;`
        }}
        
        &:active {color: ${props => colors("blue", props)};
        &:visited {color: ${props => colors("blue", props)};
        `;

        const fetchUserWelcomeData = () => {
            try {
                setIsLoading(true);
                getUserAllowedApplications().then((response) => {
                    const success = response.data && response.status === 200 && response.data.firstName;

                    if (success) {
                        setAppDetails(response.data.appDetails);
                        setFirstName(response.data.firstName);
                        setResponseCode(response.status);
                    } else {
                        setResponseCode("SYSTEM_ERROR");
                    }
                    setIsLoading(false);
                }).catch(function (error) {
                    ReactDOM.unstable_batchedUpdates(() => {
                        setResponseCode(error && error.response && error.response.status);
                        setIsLoading(false);
                    })
                });
            } catch (e) {
                ReactDOM.unstable_batchedUpdates(() => {
                    setResponseCode("SYSTEM_ERROR");
                    setIsLoading(false);
                })
            }
        };

        return (
            <>
                {isLoading && (<Loading/>)}

                {
                    !isLoading &&
                    (
                        responseCode === 200
                            ?
                            (
                                <>
                                    <ComponentTitle text={`Hi ${firstName}`}/>

                                    <InfoText as="p">Your Qualcomm ID gives you access to these websites:</InfoText>

                                    {(appDetails && appDetails.length !== 0) &&
                                    appDetails.map((app) =>
                                        <InfoText as="p" key={app.id}><a href={app.url}>{app.displayText}</a></InfoText>)
                                    }

                                    {authService.isExternalUser(auth.user.username) &&
                                    <InfoText as="p"><ActionLink onClick={() => {
                                        window.location = `/profile`
                                    }}>Profile</ActionLink></InfoText>}

                                    <InfoText as="p">If you are missing access or have any questions, please contact us
                                        at <SupportComponent/></InfoText>
                                </>
                            )
                            :
                            (
                                responseCode && responseCode !== 200 && <Oops/>
                            )
                    )
                }
            </>
        )
    }
;

export default Welcome;