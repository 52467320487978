import styled from 'styled-components';
import {device} from '../../../lib/breakpoints';
import {Button} from "@qc-modules/components";
import {colors} from "@qc-modules/styles";

export const ShieldContainer = styled.div`
      @media ${device.laptop} {
      margin-top: 48px;

       > img {
        width: 80px;
     }
`;



export const TitleContainer = styled.div`
    word-break: break-word;

 @media ${device.laptop} {
    margin-top: 15px;
     color: white;
     text-align: center;
    
        
     }
`;


export const EmailContainer = styled.div`
     
     @media ${device.laptop} {
        font-size: 12px;
        margin-top: 15px;
        color: #ffffff;
     }
`;

export const OrgContainer = styled.div`
     
     @media ${device.laptop} {
        font-size: 12px;
        margin-top: 0px;
        color: #ffffff;
     }
`;



export const LogoutButton = styled(Button)`
   @media ${device.laptop} {
   color: ${colors("white")};
   cursor: pointer;
   font-size: 16px;
   line-height: 2;
   margin-top: auto;
   margin-bottom: 48px;
   }
`;

export const DeactivateButton = styled(Button)`
   @media ${device.laptop} {
   color: ${colors("white")};
   cursor: pointer;
   font-size: 16px;
   line-height: 2;
   margin-top: auto;
   margin-bottom: 48px;
   }
`;

export const LogoContainer = styled.div`
    margin-top: 48px;
    text-align: center;
`;
