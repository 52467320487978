const ALLOWED_PASSWORD_SPECIAL_CHARACTERS = ':, !, @, #, $, %, ^, *, ?, _, ~';

export const PASSWORD_SUCCESS_MESSAGE = 'Password passes';

export const PASSWORD_VALIDATION_MESSAGES = [
    {
        name: 'required_check',
        valid: '',
        invalid: 'Password is required',
        order: 1
    },
    {
        name: 'min_check',
        valid: 'Has at least 8 characters',
        invalid: 'Please enter at least 8 characters',
        order: 2
    },
    {
        name: 'max_check',
        valid: 'Has no more than 50 characters',
        invalid: 'Please enter at most 50 characters',
        order: 3
    },
    {
        name: 'lowercase_check',
        valid: 'Has at least 1 lowercase character',
        invalid: 'Please enter at least 1 lowercase character',
        order: 4
    },
    {
        name: 'uppercase_check',
        valid: 'Has at least 1 uppercase character',
        invalid: 'Please enter at least 1 uppercase character',
        order: 5
    },
    {
        name: 'number_check',
        valid: 'Has at least 1 number',
        invalid: 'Please enter at least 1 number',
        order: 6
    },
    {
        name: 'special_character_check',
        valid: 'No invalid special characters',
        invalid: `You entered an invalid character. You may only use one of ${ALLOWED_PASSWORD_SPECIAL_CHARACTERS}`,
        order: 7
    }
];