import React from 'react';
import {ComponentDescription, ComponentTitle} from "../util/helper";

const ResponsePage = (responseMessage) => {
    const title = responseMessage.location.state.title;
    const description = responseMessage.location.state.text;
    return (
        <>
            <ComponentTitle text={title}/>
            <ComponentDescription>{description}</ComponentDescription>
        </>
    )
};

export default ResponsePage;