import React from 'react';
import {isNotEmpty} from "../util/validations";
import useLogin from '../../hooks/useLogin'
import {Box, Cta} from '@qc-modules/components';
import {HtmlField} from '../util/helper';

export const LoginFields = () => {
    const loginState = useLogin();

    const loginFields = {
        name: "login",
        emailDomainError: loginState.emailDomainError,
        loginFailed: loginState.loginFailed,
        targetUrl:loginState.targetUrl,
        formControl: {
            buttonLabel: 'Success',
            handleSubmit: (e) => {
                e.preventDefault();
                if(!loginState.isFormInvalid){
                    loginState.login()
                }

            },
            component: LoginFormControl,
            isFormInValid: loginState.isFormInvalid,
            visible: true
        },

        fields: [
            {
                name: "email",
                htmlTag: "input",
                label: 'Email*',
                type: "text",
                placeholder: "Email",
                bottomLabel: "Qualcomm employee? Use your Qualpass credentials.",
                isError: loginState.emailError,
                errorCallBack: loginState.setEmailError,
                value: loginState.email,
                actionCallBack: loginState.actionCallBack,
                updateState: loginState.setEmail,
                component: LoginDataRow, order: 1,
                isFieldEditable: true,
                visible: true,
                onBlur: loginState.verifyDomain,
                validationRules: [
                    {method: isNotEmpty, message: ""},
                ]

            },
            {
                name: "PASSWORD_INPUT",
                htmlTag: "input",
                label: 'Password*',
                type: "password",
                placeholder: "Password",
                isError: loginState.passwordError,
                errorCallBack: loginState.setPasswordError,
                value: loginState.password,
                actionCallBack: loginState.actionCallBack,
                updateState: loginState.setPassword,
                component: LoginDataRow, order: 2,
                isFieldEditable: true,
                visible: true,
                validationRules: [
                    {method: isNotEmpty, message: ""}
                ]

            }

        ]
    };
    return {
        loginFields
    }
};

const LoginDataRow = (props) => {
    return (
        <Box mt={4}>
            <HtmlField {...props}/>
        </Box>
    )
};

const LoginFormControl = (props) => {
    return (
        <Cta
             type={'submit'}
             onClick={(event) => props.handleSubmit(event)}
             version="secondary"
             disabled={props.isFormInValid}
             my={4}
        >
            Sign in
        </Cta>
    )
};