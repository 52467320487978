import React from 'react';
import {Flex} from "@qc-modules/components";
import {LoadingIcon} from "../../util/Loading";
import styled from "styled-components"

export const ModalContainer = styled(Flex)`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    align-items: center;
    justify-content: center;
    z-index: 200;
    min-width: 375px;
`;

export const Modal = styled(Flex)`
    background-color: #FFF;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    height: 400px;
    border: 1px solid #677283;
    border-radius: 12px;
    padding: 0 50px;
    min-width: 450px;
    z-index: 250;
    opacity: 1;
`;

export const StyledLoadingIcon = styled(LoadingIcon)`
margin: 35px auto;
`;

export const StyledLoadingContainer = styled.div`
width: 100px;
height: 100px;
`;

const ResponseModal = ({message, loading=false})=> {
    return (
    <ModalContainer>
        <Modal data-testid='response-modal'>
            <StyledLoadingContainer>
            {loading && <StyledLoadingIcon /> }
            </StyledLoadingContainer>
            {message}
        </Modal>
    </ModalContainer>
    )
};

export default ResponseModal;