import React, {useState, useEffect} from 'react';
import SignUpForm from "./SignUpForm";
import {ComponentDescription, ComponentTitle} from "../../util/helper";
import {getUrlParams} from "../../../utils/Utils";
import {getSignUpData} from "../../../api/register-api";

const SignUp = () => {
    const [domainsList, setDomainsList] = useState([]);
    const [serverApp, setServerApp] = useState({});

    const urlParams = async () => {
        const params = getUrlParams();

        return {
            app: params.app || null,
            targetUrl: params.target || null
        }
    };

    useEffect(() => {
        async function fetchData() {
            const params = await urlParams();

            const {data} = await getSignUpData(params.app, params.targetUrl);
            setServerApp(data.app);
            setDomainsList(data.publicDomains)
        }

        fetchData();



    }, []);

    return (
        <>
            <ComponentTitle testid='signup-header' text="Create a Qualcomm ID"/>
            <ComponentDescription text="Once your account is verified, your Qualcomm ID will be your email address."/>

            <SignUpForm appData={serverApp} targetUrl={urlParams().targetUrl} domains={domainsList} app={serverApp && serverApp.name} />
        </>
    )
};

export default SignUp;