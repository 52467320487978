import React from "react";
import {ProfileEmailDescription} from "../ProfileControls";
import {defaultLabelFont, ProfileDataRowFlex, StyledText, TextRow} from "../ProfileControls";
import {Box} from "@qc-modules/components";
import {defaultValueFont} from "../../util/helper";
import {useMediaQuery} from "react-responsive";
import {device} from "../../../lib/breakpoints";
import {PanelHeader} from "../HelperComponents";


export const EmailInfoSection = ({store}) => {
    const email = store.userData.username;
    const isLaptop = useMediaQuery({query: device.laptop});
    return (
        <>
            <PanelHeader
                isLaptop={isLaptop}
                label={'Email and Password'}
                hideControls
            />
            <ProfileEmailDescription/>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}>Your email</TextRow>
                </Box>

                <Box width={0.55}>
                    <StyledText data-testid={'yourEmail-label'} {...defaultValueFont}>
                        {email}
                    </StyledText>
                </Box>
            </ProfileDataRowFlex>
        </>
    )
};
