import React, {useCallback, useState} from "react"
import styled from "styled-components"
import {array, bool, node, number, oneOfType, string} from "prop-types"
import {colors} from "@qc-modules/styles"
import {typography} from "styled-system"
import {Glyph, Primitives} from "@qc-modules/components"

const callIfFunc = (func, ...args) =>
  typeof func === "function" ? func.apply(this, args) : null

const useToggle = (initialValue = false, callback = false) => {
  const [value, setValue] = useState(initialValue)

  const toggle = useCallback(
    (...event) => {
      callIfFunc(callback, ...event, !value)
      setValue(!value)
    },
    [callback, value]
  )

  return [value, toggle]
}

const ToolWrapper = styled(Primitives.Box)`
  border-radius: 50%;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  line-height: 0;
  vertical-align: middle;
`

const ToolGlyph = styled(Glyph)`
  height: 100%;
`

const ToolButton = styled(Primitives.Button)`
  cursor: pointer;
  margin-bottom: 8px;
  &:focus {
    outline: none;
  }
`

const ToolWindow = styled(({isLarge, ...props}) => (
  <Primitives.Box {...props} />
))`
  border: 1px solid ${props => colors("border", props)};
  border-radius: 8px;
  bottom: calc(0.7em + 14px);
  transform: translateX(-50%);
  z-index: 10;
  ${typography};
  &::before {
    content: "";
    background: inherit;
    display: block;
    position: absolute;
    left: 50%;
    bottom: -9px;
    height: 8px;
    width: 8px;
    transform-origin: 0 0;
    border-left: 1px solid ${props => colors("border", props)};
    border-bottom: 1px solid ${props => colors("border", props)};
    transform: translateX(-6px) rotate(-45deg);
  }
`

const propTypes = {
  /** ToolWindow child components */
  children: node,
  /** A boolean to represent whether the tooltip is open */
  isOpen: bool,
  tipHeight: oneOfType([string, number, array]),
  tipWidth: oneOfType([string, number, array])
}

const defaultProps = {
  isOpen: false
}

const TooltipComponent = ({
  children,
  isOpen: shouldShow,
  tipHeight = "70px",
  tipWidth = "200px"
}) => {
  const [isOpen, toggleIsOpen] = useToggle(shouldShow)

  return (
    <ToolWrapper forwardedAs="span" height={16} mx={1} width={16}>
      <ToolButton
        display="inline-block"
        height={1}
        onBlur={toggleIsOpen}
        onClick={toggleIsOpen}
        tabIndex={0}
        width={1}
      >
        <ToolGlyph fill="icon" id="info" />
      </ToolButton>
      {isOpen ? (
        <ToolWindow
          backgroundColor="background"
          color="text-title"
          fontSize={2}
          forwardedAs="span"
          height={tipHeight}
          left="50%"
          lineHeight={3}
          p={2}
          position="absolute"
          width={tipWidth}
        >
          {children}
        </ToolWindow>
      ) : null}
    </ToolWrapper>
  )
}

TooltipComponent.propTypes = propTypes
TooltipComponent.defaultProps = defaultProps
TooltipComponent.displayName = "Modules.TooltipComponent"

export default TooltipComponent
