import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";

import {Flex} from "@qc-modules/components";
import styled from "styled-components"
import {ModalContainer} from "./modal";
import qan from "../../../images/qan-modal-graphic.svg";
import {ComponentParagraphText} from "../../util/helper";

const Modal = styled(Flex)`
    background-color: #FFF;
    flex-direction: column;
    align-items: center;
    width: 665px;
    height: 475px;
    border: 1px solid #677283;
    border-radius: 12px;
    padding: 60px 50px;
    min-width: 650px;
    z-index: 250;
    opacity: 1;
    justify-content: space-between;
`;

const StyledHeading = styled(Flex)`
  height: 60px;
  width: 452px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 30px;
  text-align: center;
  padding: 30px auto;
`;


const QanModal = ({url}) => {
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setRedirect(true);
        }, 5000)
    }, []);

    return (
        <>
            {redirect
                ?
                <Redirect
                    to={{
                        pathname: '/registration/continue',
                        state: url
                    }}
                />
                :
                <ModalContainer>
                    <Modal data-testid='qan-modal'>
                        <img src={qan} alt="Submit QAN Application"/>
                        <StyledHeading>You will be redirected to the Qualcomm Advantage Network shortly.</StyledHeading>
                        <ComponentParagraphText>Your registration request has been approved and your Qualcomm ID has been created, you just need to complete the Qualcomm Advantage Network registration by entering your company information.</ComponentParagraphText>
                    </Modal>
                </ModalContainer>}
        </>
    )
};

export default QanModal;