import styled from "styled-components";
import {Text} from "@qc-modules/components";

export const StyledParagraphText = styled(Text)`
  color: #677283;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  
 &:not(:last-child) {
  margin-bottom: 30px;
  }
`;

export const StyledHeadingText = styled(Text)`
  height: 30px;
  color: #222222;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 75px;
`;