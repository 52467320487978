import React from "react";
import {PasswordInfoForm} from "./PasswordInfoForm";
import {ProfileBox} from "../../util/helper";
import {useMediaQuery} from "react-responsive";
import {device} from "../../../lib/breakpoints";

export const PasswordlInfoSection = (props) => {
    const {store} = props;
    const isLaptop = useMediaQuery({query: device.laptop});

    const formValues = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        username: store.userData.username
    };


    return (
        <ProfileBox>
            <PasswordInfoForm formValues={formValues}
                              isLaptop={isLaptop}
                              isInternalUser={store.isInternalUser}
                              saveData={store.savePasswordApi}
                              disableEdit={store.disableEdit}
                              toggleOtherSectionsEditBtn={() => store.setDisableEdit(!store.disableEdit)}
            />
        </ProfileBox>
    )
};

