import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Cta, InputComponent} from "@qc-modules/components";
import Loading from "../util/Loading";
import {ComponentTitle, InfoText, validateEmail, ErrorText} from "../util/helper";
import {validateInternalEmailDomains} from "../util/validation/ValidationFunctions";
import passwordService from "../../services/passwordService";
import {ForgotPasswordResponse} from "./ForgotPasswordResponse";
import {
    trackCtaEvent,
    trackSignInCtaEvent
} from "../../utils/Utils";
import usePreviousPage from "../../hooks/usePreviousPage";


const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [responseStatusCode, setResponseStatusCode] = useState('');
    const [internalEmail, setInternalEmail] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const errorTextOptions = {
        paddingLeft: '0px'
    }
    const prevPage = usePreviousPage()
    useEffect(() => {
        // trackPageLoadEvent('forgotPassword', 'ForgotPassword', prevPage, {}, {})
        window.pageChange('forgotPassword', 'ForgotPassword', prevPage, {}, {})
    }, [prevPage]);

    const handleEmail = (event) => {
        const currentEmail = event.target.value;
        const regexMatched = validateEmail(currentEmail);
        let internalEmailAddress = false;
        if (regexMatched) {
            const stringEmail = String(currentEmail)
            internalEmailAddress = !validateInternalEmailDomains(stringEmail.substring(stringEmail.indexOf('@') + 1))
        }
        const valid = regexMatched && !internalEmailAddress
        setEmail(currentEmail);
        setEmailError(!valid);
        setEmailSuccess(valid);
        setInternalEmail(internalEmailAddress);
    };


    const requestWrapper = (callbackFunction) => async () => {
        setIsLoading(true);
        try {
            await callbackFunction();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const sendRequest = requestWrapper(async () => {
        try {
            const statusCode = await passwordService.sendForgotPasswordEmail({email, source: "1"});
            setResponseStatusCode(statusCode);
            setRequestSubmitted(true);
            trackCtaEvent('/forgotPassword', 'Forgot password')
            // trackFormEvent('form submit', 'ForgotPassword', 'Submit', '/forgotPassword')
        } catch (e) {
            setResponseStatusCode(e.response.status);
            setRequestSubmitted(true);
            // trackFormEvent('form submit error', 'ForgotPassword', e.message)

        }
    });

    const submitHandler = (event) => {
        event.preventDefault();
        emailSuccess && sendRequest()
    };

    return (
        <>
            {isLoading && <Loading/>}
            {!requestSubmitted
                ?
                (
                    <>
                        <ComponentTitle text="Reset your password"/>
                        <InfoText as="p">Enter the email address you use as your Qualcomm ID and press Submit to request
                            a
                            password reset. You'll receive an email confirmation with a link to create a new
                            password.</InfoText>
                        <form onSubmit={submitHandler} className="Bizible-Exclude">
                            <InputComponent
                                error={emailError}
                                id={'email'}
                                label={'Email*'}
                                labelPosition={'placeholder'}
                                onChange={handleEmail}
                                required={true}
                                success={emailSuccess}
                                type={'email'}
                                value={email}
                                width={1}
                                mt={4}
                            />
                            {
                                internalEmail &&
                                <>
                                    <ErrorText as="p" {...errorTextOptions}>&#10006; You cannot reset password for
                                        internal account. Please follow Qualpass reset rules.</ErrorText>
                                </>
                            }

                            <Cta disabled={!emailSuccess} my={4}> Submit </Cta>

                            <InfoText as="p">Remembered your password? <Link to="/continue" onClick={() => {
                                trackSignInCtaEvent('/login', 'Login from Forgot Password')
                            }}>Login</Link></InfoText>
                        </form>
                    </>
                )
                :
                <ForgotPasswordResponse statusCode={responseStatusCode}/>
            }
        </>
    )
};

export default ForgotPassword;