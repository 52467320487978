import {array, number, oneOfType, shape, string} from "prop-types"

export const fontPropType = shape({
    color: oneOfType([array, string]),
    fontSize: oneOfType([array, number, string]),
    fontWeight: oneOfType([array, number, string]),
    justifyContent: oneOfType([array, string]),
    letterSpacing: oneOfType([array, string]),
    lineHeight: oneOfType([array, number, string]),
    mb: oneOfType([array, number, string]),
    textAlign: oneOfType([array, string])
})

const getColor = ({error, success, hasFocus, hasHover, type}) => {
    if (error) {
        return "error"
    }
    if (success && !error) {
        return "success"
    }
    if (hasFocus) {
        return `${type}-focus`
    }
    if (hasHover) {
        return `border-hover`
    }
    return type
}

export const getBorderColor = props => getColor({type: "border", ...props})

export const getTextColor = props => getColor({type: "text", ...props})

export const defaultFontSize = {
    fontSize: '16px',
    lineHeight: '24px'
}
