import React from 'react';
import {Box} from '@qc-modules/components'

const WebUiForm = (props) => {
    const {blockData} = props;
    const Component = props.component;
    const OutSideFormComps = props.outSideFormComps;
    const onAuthReject = (e) => {
        console.log("onAuthReject wrong pwd::", e);
    }

    return (
        <Box>
            <form onSubmit={blockData.formControl.handleSubmit} onAuthReject={onAuthReject} name={blockData.name}
                  method={'POST'} className="Bizible-Exclude">
                <Component isEdit={true} blockData={blockData}/>
            </form>
            {OutSideFormComps && <OutSideFormComps isEdit={true} blockData={blockData}></OutSideFormComps>}

        </Box>
    )
}

export default WebUiForm
