import {
  useFetchAvailableQnsSettings,
  useFetchAvailableSubscriptions,
  useFetchUserOptedQnsSettings,
  useFetchUserOptedSubscriptions, useFetchUserQnsSubscriptions
} from "./useFetchPreferences";
import React, {useContext, useState} from "react";
import {PreferencesSection} from "./PreferencesSection";
import {ErrorText, isValidImpersonation, processPreferences, processQnsProductPreferences} from "../util/helper";
import Loading from "../util/Loading";
import {PreferencesConfig, profileConstants} from "../util/appConstants2";
import {ProductPreferencesSection} from "./ProductPreferencesSection";
import AppContext from "../../context/AppContext";

export const PreferencesMobileHOC = ({type, userData}) => {

  const {data: userOptedSubscriptions, error: userOptedError, refetch: refreshUserData, isFetching: isOptedFetching, dataUpdatedAt: dataUpdatedAtOptedSubscr} = useFetchUserOptedSubscriptions();
  const {data: userOptedQnsSettings, error: userOptedQnsSettingsError, refetch: refreshUserQnsSettings, isFetching: isOptedQnsSettingsFetching, dataUpdatedAt: dataUpdatedAtOptedQnsSettings} = useFetchUserOptedQnsSettings();
  const {data: userAvailableQnsSettings, error: userAvailQnsSettingsError, isFetching: isAvailableQnsSettingsFetching} = useFetchAvailableQnsSettings();
  const {data: userAvailableSubscriptions, error: userAvailError, isFetching: isAvailableFetching} = useFetchAvailableSubscriptions();
  const {data: userQnsSubscriptions, error: userQnsSubscriptionsError,refetch: refreshUserSubscriptions, isFetching: isUserQnsSubscriptionsFetching} = useFetchUserQnsSubscriptions();
  const context = useContext(AppContext);
  let appStore;
  if (context) {
    appStore = context.appStore;
  }
  const [isEdit, setIsEdit] = useState(true);
  let processedData, processedQnsData;
  const refreshWholeData =()=>{
    refreshUserQnsSettings()
    refreshUserSubscriptions()
  }
  if(userOptedSubscriptions && userAvailableSubscriptions) {
    if(type !== 'qns') {
      processedData = processPreferences({userOptedSubscriptions, userAvailableSubscriptions}, [type]);
    }

  }
  if(userOptedQnsSettings && userAvailableQnsSettings) {
    if(type === 'qns') {
      processedQnsData = userOptedQnsSettings && userAvailableQnsSettings && processQnsProductPreferences({
        userOptedQnsSettings,
        userAvailableQnsSettings
      })
      console.log({processedQnsData})
    }

  }
  const toggleEdit = (value) => {
    setIsEdit(value)
  };

  return <>
    {(isOptedFetching || isAvailableFetching || isOptedQnsSettingsFetching || isAvailableQnsSettingsFetching || isUserQnsSubscriptionsFetching) &&
    <Loading/>}

    {(userOptedError || userAvailError || userOptedQnsSettingsError || userAvailQnsSettingsError || userQnsSubscriptionsError) &&
    <ErrorText w={100}>{profileConstants.SYSTEM_ERROR}</ErrorText>}

    {type !== 'qns' ? <PreferencesSection type={type}
                                          isEdit={((appStore.isInternalUser && isValidImpersonation(appStore.impersonationStatus)) || !appStore.isInternalUser) && isEdit}
                                          disableEdit={appStore.isInternalUser?(isValidImpersonation(appStore.impersonationStatus)?false:true):false  }
                                         dataUpdatedAt={dataUpdatedAtOptedSubscr || new Date().getTime()}
                                          disableSave={isValidImpersonation(appStore.impersonationStatus)}
                                         areaOfInterestOptIn={processedData && processedData.areaOfInterestOptIn}
                                         refetch={refreshUserData}
                                         toggleEdit={toggleEdit}
                                          userData={userData}
                                         selectedApiData={processedData ? processedData[type] : []}/> : <>{
    <ProductPreferencesSection type={PreferencesConfig.alerts}
                               isEdit={isEdit}
                               refetch={refreshWholeData}
                               disableSave={isValidImpersonation(appStore.impersonationStatus)}
                               availableQnsSettings={processedQnsData}
                               optedQnsSettings={processedQnsData}
                               userData={userData}
                               userQnsSubscriptions={userQnsSubscriptions}
                               isDataLoading={isOptedQnsSettingsFetching && isAvailableQnsSettingsFetching && isUserQnsSubscriptionsFetching}
                               dataUpdatedAt={dataUpdatedAtOptedQnsSettings || new Date().getTime()}
                               toggleEdit={toggleEdit}/>}</>}
  </>
}