// import './wdr'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from "@qc-modules/components"
import {defaultTheme} from "@qc-modules/styles"
import {AppDataProvider} from "./store/AppDataProvider";
import {OldBrowserModal} from "./components/common/OldBrowserModal";
import App from "./App";
import '../src/styles/layout/_custom.scss';


const rootElement = document.getElementById('root');

const appInfo = {
    NAME: process.env.REACT_APP_NAME,
    ENV: process.env.REACT_APP_ENV,
    API_URL: process.env.REACT_APP_API_URL,
    VERSION: process.env.REACT_APP_VERSION
};

console.debug(appInfo);

const ContentToRender = () => {
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        let show = false || !!document.documentMode;
        setShowModal(show);
    }, []);

    return (
        <div>
            {showModal && <OldBrowserModal showModal={showModal} setShowModal={setShowModal}/>}

            <ThemeProvider theme={defaultTheme}>
                <AppDataProvider>
                    <App/>
                    {/*<AppRouter/>*/}

                </AppDataProvider>
            </ThemeProvider>
        </div>
    )
};

ReactDOM.render(
    <ContentToRender/>,
    rootElement);