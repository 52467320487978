import React from 'react';
import styled from 'styled-components';
import {Flex, Glyph, Text} from '@qc-modules/components';

const DataRow = styled(Flex)`
  flex-direction: row;
  text-align: center;
  align-items: center;
  width: 100%;
  padding-bottom: 14px;
  border-bottom: 1px solid #BBC0C8;
`;

const LabelText = styled(Text)`
color: #222222;
font-size: 18px;
margin-left: 16px;
`;



export const MobileTabHeaderRow = ({pathLabel, glyphId, image}) => {
  return (
    <DataRow>
      {
          image
              ?
              <img src={image}
                   alt={"header icon"}
                   width={'24px'}
              />
              :
              <Glyph id={glyphId}
                     width={'24px'}
                     fill={'#222222'}//aka body-grey
              />

      }
      <LabelText>{pathLabel}</LabelText>
    </DataRow>
  )
};


// export const ProfileMobileHeaderRow = ({label}) => {
//     return (
//         <DataRow>
//             {/*<StyledImageBox><img src="/Notification-mob.svg" alt="Profile"/></StyledImageBox>*/}
//             <Glyph id={profileConstants.APP_TABS_STATE.PROFILE_TAB.GLYPH}
//                    width={'24px'}
//                    fill={'#222222'}//aka body-grey
//             />
//             <LabelText>Profile</LabelText>
//         </DataRow>
//     )
// };
//
// export const PreferencesMobileHeaderRow = ({label}) => {
//     return (
//         <DataRow>
//             <Glyph id={profileConstants.APP_TABS_STATE.PREFERENCES_TAB.GLYPH}
//                    width={'24px'}
//                    fill={'#222222'}//aka body-grey
//             />
//             <LabelText>{profileConstants.APP_TABS_STATE.PREFERENCES_TAB.LABEL}</LabelText>
//         </DataRow>
//     )
// };
// export const AlertsMobileHeaderRow = ({label}) => {
//     return (
//         <DataRow>
//             <Glyph id={profileConstants.APP_TABS_STATE.ALERTS_TAB.GLYPH}
//                    width={'24px'}
//                    fill={'#222222'}//aka body-grey
//             />
//             <LabelText>Alerts</LabelText>
//         </DataRow>
//     )
// };
// export const ProductsMobileHeaderRow = ({label}) => {
//   return (
//     <DataRow>
//       <Glyph id={profileConstants.APP_TABS_STATE.PRODUCTS_TAB.GLYPH}
//              width={'24px'}
//              fill={'#222222'}//aka body-grey
//       />
//       <LabelText>Product</LabelText>
//     </DataRow>
//   )
// };
//
//
// export const DeactivateMobileHeaderRow = ({label}) => {
//     return (
//         <DataRow>
//             <Glyph id={profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.GLYPH}
//                    width={'24px'}
//                    fill={'#222222'}//aka body-grey
//             />
//             <LabelText>Deactivate</LabelText>
//         </DataRow>
//     )
// };
