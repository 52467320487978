import {useEffect, useRef} from 'react';
import {useLocation, useHistory} from 'react-router-dom';

const usePreviousPage = () => {
    const location = useLocation();
    const prevLocationRef = useRef(null);
    const history = useHistory();


    useEffect(() => {
        const unlisten = history.listen((newLocation, action) => {
            if (action === 'PUSH' || action === 'REPLACE') {
                prevLocationRef.current = location.pathname;
                sessionStorage.setItem('prevLocation', location.pathname);

            }
        });
        // Cleanup the listener on unmount
        return () => unlisten();
    }, [history, location]);

    return sessionStorage.getItem('prevLocation');
};

export default usePreviousPage;
