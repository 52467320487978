import axios from 'axios';
import {logout, getRefreshTokenUrl, getCookie} from '../utils/Utils'
import {isValidImpersonation} from "../components/util/helper";

const jwt = require('jsonwebtoken');
const authAxios = axios.create();

authAxios.interceptors.request.use((config) => {
    return config;
}, function (error) {
    if (error && error.response && error.response.status === 401) {
        logout(window.location)
    } else {
        throw error
    }
});

const tokenKey = `QID-${process.env.REACT_APP_ENV_SN}-JWT-TOKEN`;

export const setSession = (value) => {
    localStorage.setItem(tokenKey, value);
};

export const getSessionToken = () => {
    return localStorage.getItem(tokenKey);
};

export function removeSessionToken() {
    localStorage.removeItem(tokenKey);
}

export const readTokenFromParams = (props) => {
    const search = props.location.search || null;
    let token;
    if (search) {
        const params = new URLSearchParams(search);
        token = params.get('token');
    }
    return token;
};

export const isTokenExpired = (jwtToken) => {
    const token = jwtToken || getSessionToken();
    const user = token && jwt.decode(token);
    let softexp = user && user.exp - 300;
    if (Math.floor(Date.now() / 1000) > softexp) {
        return true
    }
    if (user.impersonationStatus) {
        if (!isValidImpersonation(user.impersonationStatus)) {
            return true
        }
    }
    return false
};

let refreshPromise = null;

export const refreshToken = async (expiredToken) => {
    const decodedToken = jwt.decode(expiredToken);

    if (!refreshPromise) {
        refreshPromise = authAxios.get(`${getRefreshTokenUrl()}?sessionId=${decodedToken.sessionId}`);

        refreshPromise.then((response) => {
            const token = response.data && response.data.jwtToken ? response.data.jwtToken : null;
            if (token) {
                setSession(token);
                return token;
            } else {
                logout(window.location)
            }
        }).catch((error) => {
            throw error
        }).finally(function () {
            refreshPromise = null;
        });
    }

    return refreshPromise;
};

// export const getSessionTokenRefresh = async (token) => {
//     const response = await refreshToken(token);
//     token = response.data ? response.data.jwtToken : null;
//     return token
// };
const isLocalEnv = () => {
    const url = new URL(window.location);
    return url && url.hostname === 'localhost'
};

export const isValidSession = (token) => {
    const user = token && jwt.decode(token);
    const qidSessionCookieName = ('prd' === process.env.REACT_APP_ENV_SN) ? 'QID_SESSION' : `QID_${process.env.REACT_APP_ENV_SN.toUpperCase()}_SESSION`;
    const qidSessionCookieValue = getCookie(qidSessionCookieName);
    const localStorageToken = user && user.sessionId;
    return (!!qidSessionCookieValue && (localStorageToken === qidSessionCookieValue)) || isLocalEnv()
};

export function getCurrentUser() {
    try {
        const token = getSessionToken();
        if (token) {
            if (!isValidSession(token)) {
                localStorage.removeItem(`QID-${process.env.REACT_APP_ENV_SN}-JWT-TOKEN`);
                return null
            } else {
                const user = jwt.decode(token);
                return user;
            }
        } else {
            return null
        }
    } catch (ex) {
        return null
    }
}


export const isJwtExpired = (jwtToken) => {
    const token = jwtToken || getSessionToken();
    const user = token && jwt.decode(token);
    return !user
}


const isExternalUser = (username) => {
    const sessionUser = getCurrentUser();
    const loggedInUserName = username || (sessionUser && sessionUser.username)
    return (loggedInUserName && loggedInUserName.indexOf("@") > -1);

}
const getImpersonationStatus = () => {
    const token = getSessionToken();
    const user = token && jwt.decode(token);
    return (user && user.impersonationStatus)
}

export const checkTokenValidity = (token) => {
    const userToken = token || getSessionToken();
    if (!userToken) {
        window.location.reload()
    } else if (isJwtExpired(userToken)) {
        logout(window.location);
    }
}


export default {
    loginWithToken: setSession,
    logout,
    getCurrentUser,
    readTokenFromParams,
    isExternalUser,
    getImpersonationStatus
};

