import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {ComponentLinkBox, ComponentParagraphText, ComponentTitle} from "../../util/helper";
import {submitSignUp} from "../../../api/register-api";
import history from '../../../context/History';
import Loading from "../../util/Loading";
import {trackCtaEvent} from "../../../utils/Utils";

export const SignUpSuccessResponse = {
    title: 'Confirmation email sent.',
    text: ['Please check your inbox for a confirmation email.', 'The email will have a link to complete your registration.'],
    linkText: 'Re-send confirmation email'
};

export const SignUpErrorResponse = {
    title: 'There was a problem with your request.',
    text: 'Please contact support for help.'
};


export const SignUpSuccessPage = (values) => {

    const [isLoading, setLoading] = useState(false);

    const valuesToSubmit = values.location.state;

    const handleSubmit = () => {
        setLoading(true);
        trackCtaEvent('/signup', 'Sign Up')
        submitSignUp(valuesToSubmit).then((serverResponse) => {
            if (serverResponse.status === 200) {
                // trackFormEvent('form submit', 'SignUp', 'Re-send confirmation email', '/signup/success')
                history.push('/signup/success', {...valuesToSubmit});
            } else {
                // trackFormEvent('form submit error', 'SignUp', 'Unable to re-send confirmation')
                history.push('/signup/error', {...SignUpErrorResponse});
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            {isLoading && <Loading/>}
            <ComponentTitle text={SignUpSuccessResponse.title}/>

            {SignUpSuccessResponse.text.map(response => {
                return <ComponentParagraphText>{response}</ComponentParagraphText>
            })}

            <ComponentLinkBox>
                <Link onClick={handleSubmit}>{SignUpSuccessResponse.linkText}</Link>
            </ComponentLinkBox>
        </>
    )
};