import React, {useContext} from "react";
import AppContext from "../../context/AppContext";
import {Box, Flex, Glyph, Text} from "@qc-modules/components";
import {StyledPanelHeader} from "../layout/profile/ProfileSlidePanel";
import {ActionLink, defaultHeaderRowFont, LinkExtenderBox, PanelHeadingLabel} from "../util/helper";
import styled from "styled-components";
import {device} from "../../lib/breakpoints";


export const ErrorText = styled(Text)`
    color: #E0182D;
    font-size: 16px;
    margin-bottom:4px;
    margin-top: 2px;
    padding-left: 14px;
`;

export const ColoredLine = styled(Box)`
    color: #E9ECEF;
    height: 1px;
    border: 1px solid;
    margin-top: 16px;
    margin-bottom: 32px;
    
`;

export const PanelHeader = (props) => {
    const context = useContext(AppContext);
    const {isLaptop} = props
    let appStore;
    if (context) {
        appStore = context.appStore;
    }
    return (

        <Box direction={'column'} mt={20} mb={10}>
            <StyledPanelHeader {...props} isLaptop={isLaptop}>
                {!isLaptop && < LinkExtenderBox onClick={() =>  appStore.setShowPanel('hide')}>
                    <Glyph id={'caret-left'}
                           width={'16px'}
                           fill={'#677283'}
                    />
                </LinkExtenderBox>
                }
                {isLaptop && <PanelHeadingLabel as="h4" {...defaultHeaderRowFont} >{props.label}</PanelHeadingLabel>}

                {!props.hideControls && (
                    props.isEdit
                        ?
                        (
                            <Box>
                                <ActionLink
                                    id="button-cancel"
                                    type={'button'}
                                    onClick={() => props.handleCancelButton()}>
                                    Cancel
                                </ActionLink>
                                <ActionLink {...props}
                                            pl={20}
                                            data-testid={`${props.name}-save-button`}
                                            type={'submit'}
                                            id="button"
                                >
                                    Save
                                </ActionLink>
                            </Box>
                        )
                        :
                        <ActionLink id="button" data-testid={`${props.name}-edit-button`} type={'button'}
                                    disabled={appStore.isInternalUser?true:( isLaptop ? props.disableEdit : false)}
                                    onClick={() => props.handleEditButton()}>
                            Edit
                        </ActionLink>

                )
                }

            </StyledPanelHeader>
            {
                !isLaptop && <PanelHeadingLabel {...defaultHeaderRowFont} >{props.label}</PanelHeadingLabel>
            }
        </Box>
    )
};
export const ProfileUsageLink = styled(Flex)`
    flex-direction:column;
    align-items: baseline;
    
    @media ${device.laptop} {
        flex-direction: row;
        padding: 0;
    }
`;
export const HelpText = styled(Box)`
font-size: 12px;
 color: #bbc0c8;
`;

export const RequiredTextRow = styled(Text)`
    color: #677284;
    &:after {
    color: #677284;
    content: '*'
  }
`;
