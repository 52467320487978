import React from 'react';
import {a, Box, Button, Checkbox, Cta, Glyph, InputComponent, SelectComponent, Text, Primitives} from '@qc-modules/components';
import styled from 'styled-components';
import {colors} from "@qc-modules/styles"
import {getSupportEmail, trackCtaEvent} from "../../utils/Utils";
import {Link} from 'react-router-dom'
import {device} from "../../lib/breakpoints";
import {StyledPanelHeader} from "../layout/profile/ProfileSlidePanel";
import moment from 'moment'
import {PreferencesConfig, tabsConfig} from "./appConstants2";
import {array, bool, func, node, number, oneOfType, shape, string, arrayOf} from "prop-types"
import RadioSort from "./radio-sort";

export const defaultHeaderRowFont = {
    f: 5,
    lineHeight: 1
};

export const defaultPageHeaderFont = {
    f: 9,
    lineHeight: 1,
    weight: 100,
    mb: 48
};
export const globalHeader = {
    color: "#222222",
    f: 36, weight: 100
};

export const defaultParagraphStyle = {
    mb: 24,
    mt: 8,
    f: 3,
    color: 'body-grey'
};

export const defaultSubheaderStyle = {
    f: 6,
    lineHeight: 1,
    mb: 8
};

export const defaultTextHeaderStyle = {
    f: 3,
    lineHeight: 1,
    color: '#677284'
};


export const ProfileBox = styled(Box)`
    margin-bottom:96px;
`;

export const isValidImpersonation =(impersonationStatus)=>{
    try{
        return impersonationStatus && impersonationStatus.actingAs && impersonationStatus.impersonator && (new Date().getTime()< new Date(impersonationStatus.expires).getTime())
    }catch{
        return  false
    }
}

export const PrepareFormElements = ({fields, isEditPage}) => {

    let formFields = fields && fields.map((field, index) => {
        return <PrepareFormElement key={index} field={field} index={index} isEditPage={isEditPage}/>
    });

    return formFields;
};

export const PrepareFormElement = ({field, isEditPage = true, index = 0}) => {
    const Component = field.component;
    const fieldProps = {
        key: index,
        edit: (isEditPage && field.isFieldEditable),
        ...field
    };

    return (field.visible && Component) ? <Component {...fieldProps}/> : <div/>;
};


export const SelectComponentWrapper = (props) => {
    return (
        <Box w={1}>
            {props.options && <SelectComponent
                id={props.id || props.name}
                label={props.label}
                labelPosition={'placeholder'}
                onChange={((event) => props.actionCallBack(event.target.value, props))}
                value={props.value}
                options={props.options}
                placeholder={props.placeholder}
                width={1}
                error={props.isError}
                success={props.value && !props.isError}
            />}
        </Box>
    );
};

export const CheckBoxWrapper = (props) => {
    const CheckBoxLabel = props.checkBoxLabel;

    return (
        <Checkbox
            id={props.id || props.name}
            checkBoxWidth={props.width}
            isChecked={props.value}
            checkboxOnChange={((event) => props.actionCallBack(!props.value, props))}
            disabled={false}
            indeterminate={false}

            label={
                <CheckBoxLabel/>
            }
        />)
};

export const InputComponentWrapper = (props) => {
    return (
        <InputComponent
            id={props.id || props.name}
            error={props.isError}
            success={props.value && !props.isError}
            type={props.type}
            placeholder={props.placeholder}
            label={props.label}
            labelPosition={'placeholder'}
            onChange={((event) => props.actionCallBack && props.actionCallBack(event.target.value, props))}
            value={props.value || ''}
            options={props.options}
            width={1}
            bottomLabel={props.bottomLabel}
            onBlur={props.onBlur}
        />
    );
};


const prepareErrorMsg = (messagesArray, ruleObj, isValid) => {
    const index = messagesArray.findIndex(errMessage => errMessage === ruleObj.message);
    if(!isValid) {
        //if the validation fails , then build error message array from the respective validation rule
        // BJ: changed to only add a message if there are none already, to block multiples.
        if(ruleObj.message && "" !== ruleObj.message.trim() && index === -1 && messagesArray.length === 0) {
            messagesArray.push(ruleObj.message)
        }
    } else {
        //remove if any error msg already there
        const index = messagesArray.findIndex(errMsg => errMsg === ruleObj.message);
        index > -1 && messagesArray.splice(index, 1)
    }
};

export const performValidation = (value, validationRules, errorCallBack) => {
    if (validationRules && validationRules.length > 0 && (typeof errorCallBack === 'function')) {
        const errors = [];
        const isFieldValid = validationRules.map(obj => {
            //execute actual validation method
            let trimvalue = (value && typeof value == "string") ? value.trim() : value;
            let isValid = obj.method(trimvalue);
            //extract and insert error msg in Error object
            prepareErrorMsg(errors, obj, isValid);

            return isValid
        }).every((isValid) => {
            //return  true, if every element is true.This is to mark filed as error and then red border
            return isValid;
        });

        //setting set<Field>Error(true).
        errorCallBack(!isFieldValid);

        //return prepared error messages i.e. errors={firstName:['not empyty', 'should reach min length'],lastName:['','']}
        return errors;//return {errors,isFieldValid};
    } else {
        console.debug(`No validation rules or Error function Exist`)
    }
};


export const validateField = (value, fieldProps) => {
    const {validationRules, errorCallBack} = fieldProps;
    if (validationRules && validationRules.length > 0) {
        const errors = validationCallBack(value, validationRules, errorCallBack);
        return errors;
    }
};

export const validationCallBack = (value, validationRules, errorCallBack) => {

    //execute validation Rules and setError value to true/false
    return performValidation(value, validationRules, errorCallBack);
};

export const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/i;
    return !!(email && emailRegex.test(String(email).toLowerCase()))
};

export const validatePassword = (password) => {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    return !!(password && passwordRegex.test(String(password)));
};

export const htmlFieldMap = {
    input: InputComponentWrapper,
    checkbox: CheckBoxWrapper,
    select: SelectComponentWrapper
};

export const HtmlField = (props) => {
    const HtmlComponent = htmlFieldMap[props.htmlTag];
    return (
        HtmlComponent && <HtmlComponent {...props}/>
    )
};

export const defaultValueFont = {
    color: "body-grey",
    f: 3,
    lineHeight: 2
};

export const InfoText = styled(Text)`
   font-weight: 400;
   font-size: 16px;
   color: #677283;
   letter-spacing: .5px;
   
   & + & {
    margin-top: 16px;
   }
`;

export const InfoTextBold = styled(Text)`
   font-weight: bold;
   font-size: 16px;
   color: #677283;
`;

export const ErrorInfoText = styled(Box)`
   background: rgba(224,24,45,.1);
   font-size: 16px;
   color: #e71d32;
   margin-bottom: 16px;
   padding: 14px 30px 14px 30px;
`;

//updated to remove padding left, per TM
//leaving original ErrorInfoText for legacy until all can be updated
export const ErrorTextInline = styled(Text)`
   font-size: 14px;
   color: #e71d32;
   margin-bottom: 45px 0;
`;

//Need to agree and consolidate these
export const ErrorText = styled(Text)`
    color: #E0182D;
    font-size: 16px;
    margin-bottom:4px;
    margin-top: 2px;
    padding-left: 14px;
`;

export const StyledComponentTitle = styled(Text)`
    
  color: #222222;
      font-size: 24px;
    font-weight: 600;
  letter-spacing: .3px;
  line-height: 35px;
  margin-bottom: 26px;
  @media ${device.mobile} {
  font-size: 24px
  }
  @media ${device.tablet} {
  font-size: 32px
  }
   @media ${device.laptop} {
   font-size: 36px;
        letter-spacing: 0.0px;
          line-height: 35px;
    }
`;

export const StyledResponseComponentTitle = styled(Text)`
    
  width: 440px;
  color: #222222;
      font-size: 24px;
    font-weight: 600;
  letter-spacing: .3px;
  line-height: 35px;
  font-size: 24px
`;

export const StyledDivider = styled(Box)`
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid #D3D9DE;
  margin-top: 45px;
  margin-bottom: 45px;
`

const StyledComponentDescription = styled(Text)`
    font-size: 20px;
    letter-spacing: .3px;
    margin-bottom: 10px;
    margin-top: 20px;
    line-height: 30px;
    color: #222;
    word-wrap: break-word;

    @media ${device.tablet} {
        font-size: 26px;
        font-weight: 100;
        letter-spacing: .1px;
        margin-bottom: 10px;
    }
`;

const StyledComponentParagraph = styled(Text)`
    font-size: 16px;
    letter-spacing: .3px;
    margin-bottom: ${props=>props.mb?props.mb:'5px'};
    top: ${props=>props.mt?props.mt:'5px'};
    line-height: 24px;
    color: #677283;
    font-weight: 400;
    word-wrap: break-word;
    text-align: ${props=>props.align?props.align:''};
      
    @media ${device.tablet} {
    font-size: 16px;
    line-height: 30px;
    }
`;
export const StyledComponentItalicFooter = styled(Text)`
        text-align: center;
    font-style: italic;
    font-size: 14px;
    letter-spacing: .3px;
    margin-bottom: 5px;
    margin-top: 10px;
    line-height: 24px;
    color: #677283;
    font-weight: 400;
    word-wrap: break-word;
`;

export const StyledComponentParagraphLabel = styled(StyledComponentParagraph)`
    font-weight: bold;
    margin-right: 5px;
`;

const StyledEmailText = styled(Text)`
    font-size: 16px;
    letter-spacing: .5px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #677283;
    word-wrap: break-word;
    display: inline;
    font-weight: 400;
    line-height: 24px;
    
    @media ${device.tablet} {
    font-size: 18px;
    line-height: 27px;
    }
`;

const StyledEmail = styled(Text)`
    font-size: 18px;
    letter-spacing: .5px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #677283;
    word-wrap: break-word;
    font-weight: 600;
    display: inline;
    line-height: 27px;
`;

const StyledComponentLinkBox = styled.div`
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .3px;
    margin-bottom: 10px;
    margin-top: 40px;
    line-height: 30px;
    word-wrap: break-word;
    
    &:hover {
        color: #1C37A5;
    }
`;

export const StyledAccessBox = styled.div`
    margin-top: 14px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
`;

export const StyledCta = styled(Cta)`
    & p{
    height: 19px;
    width: 303px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.8px;
    line-height: 19px;
    }
`

export const ComponentTitle = ({text, testid = ''}) => {
    return (
        <StyledComponentTitle tag='h2' role='heading' aria-level='1' data-testid={testid}>
            {text}
        </StyledComponentTitle>
    )
};

export const ResponseComponentTitle = ({text, testid = ''}) => {
    return (
        <StyledResponseComponentTitle tag='h3' role='heading' aria-level='1' data-testid={testid}>
            {text}
        </StyledResponseComponentTitle>
    )
}

export const ComponentDescription = ({children, testid = ''}) => {
    return (
        <StyledComponentDescription tag='div' role='heading' aria-level='2' data-testid={testid}>
            {children}
        </StyledComponentDescription>
    )
};

export const ComponentParagraphText = ({children, testid = '', ...props}) => {
    return (
        <StyledComponentParagraph tag='p' mb={props.mb} mt={props.mt} align={props.align} data-testid={testid}>
            {children}
        </StyledComponentParagraph>
    )
};

export const ComponentLinkBox = ({children, testid = ''}) => {
    return (
        <StyledComponentLinkBox data-testid={testid}>
            {children}
        </StyledComponentLinkBox>
    )
};


export const EmailIdTextDescription = ({text, email, testid = ''}) => {
    return (
        <StyledEmailText tag='div' role='heading' aria-level='2' data-testid={testid}>
            {text} <StyledEmail>{email}</StyledEmail>.
        </StyledEmailText>
    )
};



export const LinkBox = styled(Link)`
font-size: 16px;
color: #3253dc;
line-height: 24px;
`;

export const SupportComponent = () => {
    return (
        <a href={`mailto:${getSupportEmail()}`}>{getSupportEmail()}</a>
    )
};

export const ErrorInfoComponent = ({text}) => {
    return (
        <ErrorInfoText>{text}</ErrorInfoText>
    )
};

export const ContactSupportComponent = () => {
    return (
        <InfoText as="p">Please contact us with any questions at <SupportComponent/></InfoText>
    )
};
export const ContactSupportComponent2 = () => {
    return (
      <InfoText as="p">If login fails after 2 minutes, Please contact <SupportComponent/> .</InfoText>
    )
};

export const LoginHrefComponent = () => {
    return (
        <Cta url={"/continue"} onClick={() => {
            trackCtaEvent('/continue', 'Login')
        }}> Login </Cta>
    )
};
export const getCompanyNameWithOrgId = (comapanyName, OrgId) => {
    return `${comapanyName} (${OrgId})`;
};
export const requestWrapper = (callbackFunction, loadingSetterMethod) => async () => {
    loadingSetterMethod && loadingSetterMethod(true);
    try {
        let data = await callbackFunction();

        loadingSetterMethod && loadingSetterMethod(false);
        return data
    } catch (error) {
        // setError(error.request.data);
        loadingSetterMethod && loadingSetterMethod(false);
    }
};

export const isActiveTab = (appStore, tabObj) => {
    const pathsOfTab = Object.keys(tabObj.MOBILE_VIEW).map(key => tabObj.MOBILE_VIEW[key]);
    return pathsOfTab.indexOf(appStore.profileTabPath) > -1;
};

export const prepareValidationSummary = (fields = [], result = []) => {
    fields && fields.forEach(field => {
        if(field.validationRules && field.validationRules.length > 0) {
            result.push({
                name: field.name,
                value: field.value,
                isError: field.isError,
                errorCallBack: field.errorCallBack,
                validationRules: field.validationRules,
                updateState: field.updateState

            })
        }
        if(field.children && field.children.length > 0) {
            //recursion
            prepareValidationSummary(field.children, result)
        }
    });

    return result;
};

export const LinkExtenderBox = styled(Box)`
    width: 75px;
    cursor: pointer;
`;

export const ActionLink = styled(Button)`
    font-size: 16px;
    cursor:${props => props.disabled ? "default" : "pointer"};
    color: ${props => props.disabled ? "#6f7aa9" : colors("blue", props)};
    &:hover {color:${props => props.disabled ? "#6f7aa9" : "#1C37A5"}};
    &:active {color: ${props => colors("blue", props)};
    &:visited {color: ${props => colors("blue", props)};
`;

export const PanelHeadingLabel = styled(Text)`
    font-size: 20px;
    font-weight: 600;
    line-height: 1.125;
`;

export const PanelHeader = (props) => {
    return (

        <Box>
            <StyledPanelHeader {...props}>
                {!props.isLaptop && < LinkExtenderBox onClick={() => {props.handleReset && props.handleReset(); props.appStore.setShowPanel('hide')} }>
                    <Glyph id={'caret-left'}
                           width={'16px'}
                           fill={'#677283'}
                    />
                </LinkExtenderBox>
                }
                {props.isLaptop &&
                <PanelHeadingLabel as="p" {...defaultHeaderRowFont} >{props.label}</PanelHeadingLabel>}

                {!props.hideControls && (
                    props.isEdit
                        ?
                        (
                            <Box>
                                <ActionLink
                                    id="button-cancel"
                                    type={'button'}
                                    onClick={() => props.handleCancelButton()}>
                                    Cancel
                                </ActionLink>
                                <ActionLink {...props}
                                            pl={20}
                                            data-testid={`${props.name}-save-button`}
                                            type={'submit'}
                                            id="button"
                                            disabled={props.disableSave}
                                            onClick={(event) => props.handleSaveButton(event)}>
                                    Save
                                </ActionLink>
                            </Box>
                        )
                        :
                        <ActionLink id="button" data-testid={`${props.name}-edit-button`}
                                    disabled={props.disableEdit}
                                    onClick={() => props.handleEditButton()}>
                            Edit
                        </ActionLink>

                )
                }

            </StyledPanelHeader>
            {
                !props.isLaptop && <PanelHeadingLabel {...defaultHeaderRowFont} >{props.label}</PanelHeadingLabel>
            }
        </Box>
    )
};

export const checkRoleAccess = (app, values, props) => {
    if(app.name === 'salescenter') {
        const selectedExportId = values.exportId;
        const selectedOrg = props.orgs.find(org => org.id.toString() === (selectedExportId && selectedExportId.toString()));
        // selectedOrg.tier = 'Tier 4';
        return (!!selectedOrg.tier && selectedOrg.tier !== 'Tier 4')
    }
};


export const WWW_APP = 'www';

export const SALES_CENTER_CONTACT_LINK = "https://assets.qualcomm.com/company-verification.html";
 export const formatDate =(dateString,format)=>{
     return dateString ?  moment(new Date(dateString)).utc().format(format):'-';
 };

export const checkAccess =(store, tabId)=>{
    return store.tabAccess[tabId]
};

export  const getTabs =(store)=>{
    const allowedTabs = tabsConfig.filter(ob => checkAccess(store, ob.id));
    return allowedTabs && allowedTabs.length? allowedTabs.sort((a,b)=>(a.order>b.order)?1:(a.order<b.order?-1:0)):[]

};
export const processPreferences = (apiResponse, types) => {
  const userPref = apiResponse.userOptedSubscriptions.data;
  const availableOptions = apiResponse.userAvailableSubscriptions;

  let result = {};
  console.log(availableOptions)
  let selectedItem;

  types.forEach(type => {
    let selectedItems = [];
    const userSubscriptions = userPref[type];
    let apiData = PreferencesConfig[type]['apiKey'];

      let userOptions = [];
      if(apiData === 'applicationAreas') {
          userOptions = availableOptions['applicationArea'] && availableOptions['applicationArea'].sort((a, b) => a.title.localeCompare(b.title));
      } else if(apiData === 'areasOfInterest') {
          userOptions = availableOptions['areaOfInterest'] && availableOptions['areaOfInterest'].sort((a, b) => a.title.localeCompare(b.title));
      } else {
          userOptions = availableOptions[apiData] && availableOptions[apiData].sort((a, b) => (a.order > b.order) ? 1 : -1);
      }

      userOptions.forEach((item, index) => {
          let row = 0;
          if(type === PreferencesConfig.updates) {
              row = index % 2 === 0 ? 0 : 1;
          }
        if(type === PreferencesConfig.newsLetters) {
          row = item.order<6?0:1;
        }
          selectedItem = {...item, checked: userSubscriptions && userSubscriptions.includes(item.id), row};
          selectedItems.push(selectedItem)
      });
      result[type] = selectedItems.sort((a, b) => (a.order > b.order) ? 1 : -1);;
  });


  return {...userPref, ...result};
};

export const processQnsProductPreferences = (apiResponse) => {
    const userSettings = apiResponse.userOptedQnsSettings;
    const userAvailableSettings = apiResponse.userAvailableQnsSettings;

    let frequencyResult = [];
    let docTypeResult = [];
    let docClassificationResult = [];
    let selectedItem;

    Object.keys(userAvailableSettings).forEach((key) => {
            userAvailableSettings[key].forEach((item) => {
                selectedItem = {...item,checked:  computeQnsSettingSelected(userSettings, item)}
                if(key==='qnsFrequencies') {
                    frequencyResult.push(selectedItem);
                } else if (key === 'qnsDocTypes') {
                    docTypeResult.push(selectedItem);
                } else if (key === 'qnsDocClassifications') {
                    docClassificationResult.push(selectedItem);
                }
            });
        }
    );

    return {
      qnsFrequencies: frequencyResult.sort((a,b) => a.order - b.order),
      qnsDocTypes: docTypeResult.sort((a,b) => a.order - b.order),
      qnsDocClassifications: docClassificationResult.sort((a,b) => a.order - b.order)
    };

}

function computeQnsSettingSelected(userSettings, item) {
    for (let key of Object.keys(userSettings)) {
        if(userSettings[key].includes(item.id)){
            return true;
        }
    }
    return false;
}

const defaultLabelFont = {
    color: "text-light-grey",
    fontSize: [1, 1, 0],
    fontWeight: 500,
    letterSpacing: ["1.8px", "1.8px", "1.5px"],
    lineHeight: [1, 1, 2],
    mb: 2
}

const defaultButtonFont = {
    color: "body-grey",
    direction: ["row-reverse", "initial"],
    fontSize: 1,
    fontWeight: 1,
    justifyContent: ["space-between", "initial"],
    letterSpacing: "0.43px",
    lineHeight: 3,
    mb: ["0px", "14px"],
    py: ["14px", "0px"]
}

const propTypes = {
    // Font parameters for each Radio Button
    buttonFont: shape({
        fontSize: oneOfType([string, number, array])
    }),
    disabled: bool,
    /** Label for the group */
    groupLabel: node,
    // Name of radio button group
    groupName: string,
    // Font parameters for group label
    labelFont: shape({
        fontSize: oneOfType([string, number, array])
    }),
    // The currently selected sort option
    selectedOption: string,
    /** sortOnChange event handler - used to process the event */
    sortOnChange: func,
    /** List of sort options, either array or object  */
    sortOptions: arrayOf(
        shape({
            disabled: bool,
            id: string,
            label: string,
            value: string
        })
    ),
    // Font parameters for tabs
    tabFont: shape({
        fontSize: oneOfType([string, number, array])
    }),
    /** Determines if tab bar should show on mobile instead of radio buttons */
    withTabBar: bool
}

const defaultProps = {
    buttonFont: defaultButtonFont,
    groupLabel: "SORT BY",
    groupName: "sort-controls",
    labelFont: defaultLabelFont
}

export const SortControls = ({
                                 buttonFont,
                                 disabled,
                                 groupLabel,
                                 groupName,
                                 labelFont,
                                 selectedOption,
                                 sortOnChange,
                                 sortOptions,
                                 ...props
                             }) => {
    return (
        <Primitives.Flex display="block" my={2} width={1} {...props} marginBottom={'48px'}>
            <RadioSort
                buttonFont={buttonFont}
                disabled={disabled}
                groupLabel={groupLabel}
                groupName={groupName}
                labelFont={labelFont}
                radioButtonOnChange={sortOnChange}
                selectedOption={selectedOption}
                sortOptions={sortOptions}
                {...props}
            />
        </Primitives.Flex>
    )
}

SortControls.propTypes = propTypes
SortControls.defaultProps = defaultProps
SortControls.displayName = "Modules.SortControls)"

export default SortControls


