import React, {useEffect, useRef} from 'react';
import styled from "styled-components"
import {Flex} from "@qc-modules/components";
import {ModalContainer} from "./modal";
import {ComponentParagraphText, SALES_CENTER_CONTACT_LINK, StyledCta} from "../../util/helper";
import {ESCAPE_KEY_CODE} from "./RegConstants";

const Modal = styled(Flex)`
    background-color: #FFF;
    flex-direction: column;
    align-items: center;
    width: 665px;
    height: 475px;
    border: 1px solid #677283;
    border-radius: 12px;
    padding: 60px 50px;
    min-width: 650px;
    z-index: 250;
    opacity: 1;
    justify-content: space-between;
`;

const StyledHeading = styled(Flex)`
  height: 90px;
  width: 452px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 30px;
  text-align: center;
  padding: 30px auto;
`;


const StyledText = styled.div`
    color: #677283;
    margin-top: 2px;
    margin-bottom: 15px;
    min-width: 450px;
    width: 600px;
    max-width: 650px;
    &:hover {cursor: pointer};
    text-align: center;
`;


const StyledLink = styled(StyledText)`
      height: 33px;
  width: 377px;
  color: #2F4DE0;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
`;

const LruWarningModal = ({closeModal}) => {

    const modalRef = useRef(null);

    useEffect(() => {
        modalRef.current.focus()
    }, [modalRef]);

    const escapeHandle = (e) => {
        if(e.keyCode === ESCAPE_KEY_CODE) {
            closeModal(e)
        }
    };

    const redirectToSalesHelp = () => {
        closeModal();
        window.location = SALES_CENTER_CONTACT_LINK
    };

    return (
        <>
            <ModalContainer onClick={(e) => closeModal(e)} ref={modalRef} tabIndex={0} onKeyUp={(e) => escapeHandle(e)}>
                <Modal data-testid='lru-warning-modal' onClick={(e) => e.stopPropagation()}>
                    <StyledHeading>Access to Qualcomm Sales Center requires an existing contract with Qualcomm
                        Technologies,
                        Inc.</StyledHeading>
                    <ComponentParagraphText align={'center'}>Based on the email address you entered, we cannot grant you
                        access to
                        Qualcomm Sales Center at this time.​</ComponentParagraphText>
                    <StyledCta onClick={(e) => closeModal(e)}>Continue to create your Qualcomm ID</StyledCta>
                    <StyledLink onClick={redirectToSalesHelp}>Contact sales to request access</StyledLink>
                </Modal>
            </ModalContainer>
        </>
    )
};

export default LruWarningModal;