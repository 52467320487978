import React, {useContext, useEffect, useState, useCallback} from "react";
import {Button, Checkbox, Cta, Flex, Glyph, InputDropdown, Text} from "@qc-modules/components";
import TooltipComponent from '../util/mirum-custom-comps/TooltipComponent'
import Loading, {LoadingIcon} from "../util/Loading";
import styled from "styled-components";
import {PreferencesConfig} from "../util/appConstants2";
import {useMediaQuery} from "react-responsive";
import {device} from "../../lib/breakpoints";
import AppContext from "../../context/AppContext";
import {addSubscription, deleteSubscription, fetchUserPdps, saveQnsSettings} from "../../api/profile-api";
import SortControls, {
    defaultParagraphStyle,
    defaultTextHeaderStyle,
    PanelHeader
} from "../util/helper";
import ErrorPage from "../common/ErrorPage";
import deleteImage from "../../images/delete.svg";
import debounce from 'lodash.debounce';
import {trackCtaEvent} from "../../utils/Utils";

const StyledSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledProductsSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    margin-right: 40px;
    @media ${device.mobile} {
        width: 100%;
    }
    @media ${device.laptop} {
        width: 50%;
    }
`;


const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledInternalCheckboxSection = styled.div`
    display: flex;
    @media ${device.mobile} {
        flex-direction: column;
    }
    @media ${device.laptop} {
        flex-direction: row;
    }
`;

const StyledSubSection = styled.div`
    display: flex;
`;

const StyledProductsListSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
`;

const SubscriptionsDocumentsSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 48px;
`;

const StyledSubTitle = styled.div`
    color: #BBC0C8;
    font-size: 12px;
    letter-spacing: 1.8px;
    margin-bottom: 10px;
`;

const StyledInternalSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

`;

const StyledInternalCheckboxEditSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    width: 50%

`;

const StyledInternalEditSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
`;

const StyledCheckbox = styled(Checkbox)`
    margin-bottom: 16px;
    color: "body-grey",
    margin-top: 16px;
    font-size: 16px;
`;

const ProductRowGrid = styled.div`
    flex-direction: row;
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #E9EBEE;
    @media ${device.mobile} {
        justify-content: space-between;
    }
    @media ${device.tablet} {
        justify-content: space-between ;
    }
    @media ${device.laptop} {
        justify-content: flex-start;
    }
    @media ${device.desktop} {
        justify-content: flex-start;
    }


`;

const StyledProductLabel = styled.div`
    width: 94%;
    height: 24px;

    color: #222222;

    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
`;

const ActionLink = styled(Button)`
    font-size: 14px;
    align-self: flex-start;
    margin-bottom: 12px;
    cursor: ${props => props.disabled ? "default" : "pointer"};
    color: #3253DC;
    line-height: 18px;
`;

const CheckboxWrapper = ({item, changeHandler, checkedProperty, disabledProperty}) => {
    return (
        <StyledCheckbox
            key={item.id}
            checkBoxWidth={'16px'}
            isChecked={checkedProperty}
            disabled={disabledProperty || false}
            label={item.name}
            checkboxOnChange={changeHandler}
            value={item.id}
        />
    );
};

const SubscriptionItemWrapper = ({item, removeHandler}) => {
    return (
        <ProductRowGrid>
            <StyledProductLabel>{item.title}</StyledProductLabel>
            <span onClick={removeHandler} style={{cursor: "pointer"}}><img src={deleteImage}
                                                                           alt={"Remove Subscription Icon"}/></span>
        </ProductRowGrid>
    );
}

const StyledCta = styled(Cta)`
    text-align: left;
    margin-top: 20px;
`;

const PreferencesHeading = (props) => {
    const isLaptop = useMediaQuery({query: device.laptop});
    const {appStore} = useContext(AppContext);
    return (
        <StyledSection>
            <PanelHeader
                handleEditButton={() => props.handleToggleEdit(!isLaptop ? true : props.type)}
                handleSaveButton={() => props.handleSave()}
                handleCancelButton={() => props.handleToggleEdit(!isLaptop ? false : '')}
                handleReset={() => props.handleReset()}

                isEdit={true}
                label={props.content.heading} appStore={appStore} isLaptop={isLaptop} {...props}
                section={'editQwaSub'}/>
            <Text {...defaultParagraphStyle} as="p">{props.content.descr}</Text>
        </StyledSection>
    )
};

export const ProductPreferencesData = (props) => {
    const allItems = props && props.selectedItems;
    const frequencyViewItems = props.selectedItems && props.selectedItems.qnsFrequencies && props.selectedItems.qnsFrequencies.filter(i => !!i.checked);
    const docTypeViewItems = props.selectedItems && props.selectedItems.qnsDocTypes && props.selectedItems.qnsDocTypes.filter(i => !!i.checked);
    const docClassificationViewItems = props.selectedItems && props.selectedItems.qnsDocClassifications && props.selectedItems.qnsDocClassifications.filter(i => !!i.checked);
    const subscriptions = props && props.subscriptions;
    const isAllDocTypes = allItems && docTypeViewItems && docTypeViewItems.length === allItems.qnsDocTypes.length;
    const isAllDocClassifications = allItems && docClassificationViewItems && docClassificationViewItems.length === allItems.qnsDocClassifications.length;
    const isLaptop = useMediaQuery({query: device.laptop});
    const [pdpSearchQuery, setPdpSearchQuery] = useState('');
    const [selectedPdp, setSelectedPdp] = useState();
    const [userPdps, setUserPdps] = useState([]);
    const [invalidPdp, setInvalidPdp] = useState(true);
    const [loadingUserPdps, setLoadingUserPdps] = useState(false)

    const addPdp = () => {
        setPdpSearchQuery('');
        setUserPdps([]);
        props.removeSubscriptionHandler(selectedPdp, true)
    };
    const getCookieValue = (name) => {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
    };

    const debounceSearch = useCallback(
        debounce((value, susbcrExisting) => loadData(value, susbcrExisting), 500)
        , [])
    const loadData = async (val, susbcrExisting) => {
        if (val && val.length > 1) {
            const qidSessionCookieName = ('prd' === process.env.REACT_APP_ENV_SN) ? 'QID_SESSION' : `QID_${process.env.REACT_APP_ENV_SN.toUpperCase()}_SESSION`;
            const qidSession = getCookieValue(qidSessionCookieName);
            setLoadingUserPdps(true)
            const resp = await fetchUserPdps(qidSession, val)
            setLoadingUserPdps(false)
            let responsePds = resp.data
            if (subscriptions) {
                const selectedSubscrs = susbcrExisting.filter(obj => obj.checked)
                if (selectedSubscrs) {
                    responsePds = resp.data.filter(obj => !(selectedSubscrs.find(s => s.id === obj.id)))
                }
            }
            ;
            setUserPdps(responsePds)
        } else {
            setLoadingUserPdps(false)
            setUserPdps([])
        }
    }
    useEffect(() => {

        debounceSearch(pdpSearchQuery, subscriptions)

// eslint-disable-next-line
    }, [pdpSearchQuery]);
    useEffect(() => {
        setInvalidPdp(!(userPdps.find(userPdp => userPdp.title === pdpSearchQuery)))
    }, [userPdps, pdpSearchQuery])
    return (
        <>
            {!props.isEdit &&
                <StyledSection>
                    <StyledSubSection align={'top'} width={1}>
                        <Text {...defaultTextHeaderStyle} width={1 / 3}>{props.content.subheading1}</Text>
                        <Text {...defaultParagraphStyle} mt={0} width={2 / 3} color={'#222222'}>

                            {frequencyViewItems && frequencyViewItems.length ? ((frequencyViewItems.map(ob => ob.label)).join(', ')) : '\u2013'}
                        </Text>
                    </StyledSubSection>

                    <StyledSubSection>
                        <Text {...defaultTextHeaderStyle} width={1 / 3}>{props.content.subheading2}</Text>
                        {(!isAllDocTypes || !isAllDocClassifications) &&
                            <StyledSection>
                                <StyledInternalSection>
                                    {((docTypeViewItems && docTypeViewItems.length === 0) || (docClassificationViewItems && docClassificationViewItems.length === 0))
                                        ?
                                        <>
                                            <Text f={3} color={'#e11529'}>No Documents, you have not chosen any document
                                                types</Text>
                                        </>
                                        :
                                        <>
                                            <StyledSubTitle>DOCUMENT TYPES</StyledSubTitle>
                                            {docTypeViewItems && docTypeViewItems.length ? ((docTypeViewItems.map(ob =>
                                                <Text color={'#222222'}
                                                      f={3}>{ob.name}</Text>))) : '\u2013'}
                                        </>
                                    }
                                </StyledInternalSection>

                                {docTypeViewItems && docTypeViewItems.length !== 0 && docClassificationViewItems && docClassificationViewItems.length !== 0 &&
                                    <StyledInternalSection>
                                        <StyledSubTitle>DOCUMENT CLASSIFICATIONS</StyledSubTitle>
                                        {docClassificationViewItems && docClassificationViewItems.length && docClassificationViewItems.map(ob => (
                                                ob.id === 'otherDocClassification'
                                                    ?
                                                    <span><Text color={'#222222'} f={3}>{ob.name}</Text>
                                         <TooltipComponent tipHeight={isLaptop ? '200px' : '240px'}
                                                           tipWidth={isLaptop ? '300px' : '200px'} isLarge={true}>
                                          <div style={{marginLeft: '10px', marginRight: '32px', marginBottom: '19px'}}>
                                              <div style={{fontSize: '12px'}}>All other document classifications does NOT include:
                                              <ul>
                                                  {allItems.qnsDocClassifications.filter(obj => obj.type !== 'custom').map(item => {
                                                      return <li
                                                          style={{
                                                              fontSize: '12px',
                                                              marginLeft: '16px'
                                                          }}>{item.name}</li>
                                                  })}
                                              </ul>
                                              </div>
                                          </div>
                                        </TooltipComponent>
                                         </span>
                                                    :
                                                    <Text color={'#222222'} f={3}>{ob.name}</Text>
                                            )
                                        )}
                                    </StyledInternalSection>}
                            </StyledSection>}

                        {isAllDocTypes && isAllDocClassifications &&
                            <StyledSection>
                                <StyledInternalSection>
                                    <Text color={'#222222'} f={3}>All documents</Text>
                                </StyledInternalSection>
                            </StyledSection>}
                    </StyledSubSection>

                    <StyledSubSection>
                        <Text {...defaultTextHeaderStyle} width={1 / 3}>{props.content.subheading3}</Text>
                        <StyledSection>
                            {subscriptions && subscriptions.length
                                ?
                                ((subscriptions.filter(item => !!item.checked).map(ob => <Text color={'#222222'}
                                                                                               f={3}>{ob.title}</Text>)))
                                :
                                <Text f={3} color={'#e11529'}>You do not have any product subscription</Text>}
                        </StyledSection>
                    </StyledSubSection>
                </StyledSection>
            }
            {props.isEdit &&
                <StyledSection>

                    <Text {...defaultTextHeaderStyle}>{props.content.subheading1}</Text>

                    <SortControls
                        buttonFont={{fontSize: 3, alignItems: 'center'}}
                        groupName={'qnsFrequencies'}
                        sortOptions={createSortOptionsForRadioButton(props.selectedItems && props.selectedItems.qnsFrequencies)}
                        selectedOption={props.selectedItems.qnsFrequencies.filter(i => !!i.checked).length === 1 ? props.selectedItems.qnsFrequencies.filter(i => !!i.checked)[0].id : props.selectedItems.qnsFrequencies.filter(i => i.default === 'Y')[0].id}
                        groupLabel={''}
                        sortOnChange={(e) => props.toggleRadioButton(e)}
                    />

                    <StyledSection>
                        <Text {...defaultTextHeaderStyle}>{props.content.subheading2}</Text>
                        <SubscriptionsDocumentsSection>

                            <CheckboxWrapper
                                item={{id: 'allOptions', name: 'All document updates'}}
                                disabledProperty={false}
                                changeHandler={() => props.handleAllDocumentsCheckbox(isAllDocTypes, isAllDocClassifications)}
                                checkedProperty={(isAllDocTypes && isAllDocClassifications)}/>

                            <StyledInternalEditSection>
                                <ActionLink
                                    id="button-doc-options"
                                    type={"button"}
                                    onClick={() => props.handleDocumentOptionsClick()}>
                                    Document options {props.moreOptions ?
                                    <Glyph id={'caret-up'} height={'12px'} width={'12px'} fill={'#3253DC'}/> :
                                    <Glyph id={'caret-down'} height={'12px'} width={'12px'} fill={'#3253DC'}
                                           style={{marginTop: '4px'}}/>}
                                </ActionLink>
                                {props.moreOptions &&
                                    <StyledInternalCheckboxSection>
                                        <StyledInternalCheckboxEditSection>
                                            <StyledSubTitle>DOCUMENT TYPES</StyledSubTitle>
                                            <CheckboxContainer>
                                                {props.selectedItems.qnsDocTypes.map(item => (
                                                    <CheckboxWrapper
                                                        key={item.id}
                                                        item={item}
                                                        disabledProperty={false}
                                                        changeHandler={() => props.toggleCheckbox(item, "qnsDocTypes")}
                                                        checkedProperty={item.checked}/>
                                                ))}
                                            </CheckboxContainer>
                                        </StyledInternalCheckboxEditSection>
                                        <StyledInternalCheckboxEditSection>
                                            <StyledSubTitle>DOCUMENT CLASSIFICATIONS</StyledSubTitle>
                                            <CheckboxContainer>
                                                {props.selectedItems.qnsDocClassifications.map(item => (
                                                    <CheckboxWrapper
                                                        key={item.id}
                                                        item={item}
                                                        disabledProperty={props.selectedItems.qnsDocTypes.filter(item => !!item.checked).length === 0}
                                                        changeHandler={() => props.toggleCheckbox(item, "qnsDocClassifications")}
                                                        checkedProperty={item.checked}/>
                                                ))}
                                            </CheckboxContainer>
                                        </StyledInternalCheckboxEditSection>
                                    </StyledInternalCheckboxSection>
                                }
                            </StyledInternalEditSection>
                        </SubscriptionsDocumentsSection>


                    </StyledSection>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <StyledProductsSection>

                            <Text {...defaultTextHeaderStyle}>{props.content.subheading3}</Text>
                            {subscriptions && subscriptions.length && subscriptions.filter(item => !!item.checked).length
                                ?
                                <StyledProductsListSection>

                                    {
                                        subscriptions.filter(item => !!item.checked).map(item => (
                                            <SubscriptionItemWrapper
                                                item={item}
                                                removeHandler={() => props.removeSubscriptionHandler(item)}/>
                                        ))}
                                </StyledProductsListSection>
                                :
                                <Text f={3} color={'#e11529'} style={{marginTop: '16px'}}>You do not have any product
                                    subscriptions</Text>
                            }
                        </StyledProductsSection>
                        <StyledProductsSection>
                            <Text {...defaultTextHeaderStyle}>Add product subscription</Text>
                            <StyledProductsListSection>
                                <InputDropdown
                                    height="46px"
                                    id="pdpSearch"
                                    onFocus={() => {
                                        if (!pdpSearchQuery) {
                                            setUserPdps([])
                                        }

                                    }}
                                    onChange={(e) => {
                                        setPdpSearchQuery(e.target.value)
                                    }}
                                    placeholder="e.g. QCA4020"
                                    type="text"
                                    containerProps={{justifyContent: (loadingUserPdps ? 'center' : 'inherit')}}
                                    value={pdpSearchQuery}
                                    renderDropdown={({toggleShowDropdown}) => (
                                        <Flex flexDirection="column">
                                            {!loadingUserPdps && userPdps.map(userpdp => {
                                                return <div style={{cursor: 'pointer', padding: "8px 0px 8px 14px"}}
                                                            onClick={() => {
                                                                setPdpSearchQuery(userpdp.title)
                                                                setSelectedPdp(userpdp)
                                                                toggleShowDropdown()
                                                            }}>{userpdp.title} </div>
                                            })}
                                            {loadingUserPdps &&
                                                <div style={{textAlign: 'center', padding: '10px 0px 10px 0px'}}>
                                                    <LoadingIcon/></div>}
                                        </Flex>
                                    )}
                                    width={1}
                                />
                                <StyledCta onClick={() => addPdp()} disabled={invalidPdp}> Add product </StyledCta>
                            </StyledProductsListSection>
                        </StyledProductsSection>
                    </div>
                </StyledSection>
            }
        </>

    )
};

function createSortOptionsForRadioButton(sortOptions) {
    let buttonGroup = [];
    sortOptions && sortOptions.forEach(item => {
        let buttonProperties = {id: item.id, label: item.label, value: item.id}
        buttonGroup.push(buttonProperties);
    })
    return buttonGroup;
}


export const ViewEditProductPreferences = (props) => {
    const {selectedItems, isDataLoading: loadingAllApis, subscriptions} = props
    return (
        <StyledSection>
            <PreferencesHeading {...props}/>
            {!!(selectedItems && selectedItems.qnsFrequencies && selectedItems.qnsFrequencies.length
                && selectedItems && selectedItems.qnsDocClassifications && selectedItems.qnsDocClassifications.length
                && selectedItems && selectedItems.qnsDocTypes && selectedItems.qnsDocTypes.length && subscriptions
                && !loadingAllApis) && <ProductPreferencesData {...props}/>}
        </StyledSection>
    )
};

export const ProductPreferencesSection = ({
                                              type,
                                              disableSave,
                                              isEdit,
                                              refetch,
                                              availableQnsSettings,
                                              userQnsSubscriptions,
                                              optedQnsSettings,
                                              userData,
                                              dataUpdatedAt,
                                              toggleEdit,
                                              isDataLoading
                                          }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState({qnsFrequencies: [], qnsDocTypes: [], qnsDocClassifications: []})
    const [products, setProducts] = useState();
    const [moreOptions, setMoreOptions] = useState(false);
    const [apiError, setApiError] = useState(false);

    // const previousPage = usePreviousPage();

    useEffect(() => {
        setSelectedItems(optedQnsSettings);
        if (userQnsSubscriptions) {
            setProducts(userQnsSubscriptions.map(product => (
                {...product, checked: true}
            )));
        }
    }, [optedQnsSettings, userQnsSubscriptions]);

    const toggleCheckbox = (event, type) => {
        console.log({event, type})
        const updatedItems = selectedItems[type].map((item) =>
            item.id === event.id ? {...item, checked: !item.checked} : item
        )
        const updatedSelectedItems = {...selectedItems, [type]: updatedItems};
        setSelectedItems(updatedSelectedItems);
        /*const updatedItems = selectedItems.map((item) =>
            item.id === event.id ? {...item, checked: !item.checked} : item
        )
        setSelectedItems(updatedItems)*/
    };

    const removeSubscriptionHandler = (item, flag = false) => {
        if (!flag) {
            const updatedProducts = products.map((product) =>
                product.id === item.id ? {...item, checked: false} : product
            );
            setProducts(updatedProducts)
        } else {
            setProducts(prevArr => [...prevArr, {...item, checked: true, type: 'new'}])
        }
    };


    const handleAllDocumentsCheckbox = (isAllDocTypes, isAllDocClassifications) => {
        console.log({isAllDocTypes, isAllDocClassifications});
        /*if(isAllDocTypes && isAllDocClassifications) {

            //make all doc types and classification not checked
        }
        const updatedSelectedItems = {...selectedItems}
        setSelectedItems(updatedSelectedItems)*/

        const updatedDocTypes = selectedItems.qnsDocTypes.map((item) =>
            isAllDocTypes && isAllDocClassifications ? {...item, checked: false} : {...item, checked: true}
        );
        const updatedDocClassifications = selectedItems.qnsDocClassifications.map((item) =>
            isAllDocTypes && isAllDocClassifications ? {...item, checked: false} : {...item, checked: true}
        );
        const updatedSelectedItems = {
            ...selectedItems,
            qnsDocTypes: updatedDocTypes,
            qnsDocClassifications: updatedDocClassifications
        }
        setSelectedItems(updatedSelectedItems);

    };

    const save = async () => {
        setIsLoading(true);
        let frequency = selectedItems && selectedItems.qnsFrequencies && selectedItems.qnsFrequencies.filter(ob => !!ob.checked).map(ob => ob.id);
        if (frequency.length === 0) {
            frequency = selectedItems && selectedItems.qnsFrequencies && selectedItems.qnsFrequencies.filter(ob => ob.default === 'Y').map(ob => ob.id);
        }
        const qnsDocTypes = selectedItems && selectedItems.qnsDocTypes && selectedItems.qnsDocTypes.filter(ob => !!ob.checked).map(ob => ob.id);
        const qnsDocClassifications = selectedItems && selectedItems.qnsDocClassifications
            && selectedItems.qnsDocTypes && selectedItems.qnsDocTypes.filter(ob => !!ob.checked).length !== 0 && selectedItems.qnsDocClassifications.filter(ob => !!ob.checked).map(ob => ob.id);
        const payload = {
            frequency: frequency[0],
            docTypes: qnsDocTypes ? qnsDocTypes : [],
            docClassifications: qnsDocClassifications ? qnsDocClassifications : []
        };
        try {
            trackCtaEvent('/www-qns-settings', 'Product Preferences')
            await saveQnsSettings(payload);

            const productsToDelete = products.filter(item => !item.checked && item.type !== 'new')
            const productsToAdd = products.filter(item => item.checked && item.type === 'new')
            for (const obj of productsToDelete) {
                await deleteSubscription(obj.id, obj.url)
            }
            for (const obj of productsToAdd) {
                await addSubscription(obj.id, obj.url)
            }
            // trackEventAdobe({'product_preferences_updated': 'product_preferences_updated'})
            // trackFormEvent('form submit', 'ProductPreferenceSection', 'Save', 'Profile')
        } catch (e) {
            setApiError(true)
            // trackFormEvent('form submit error', 'ProductPreferenceSection', e.message)
        }
        setIsLoading(false);
        refetch();
        toggleEdit(false);
    };

    const toggleRadioButton = (event) => {
        const frequencyUpdatedItems = selectedItems.qnsFrequencies.map((item) =>
            item.id === event.id ? {...item, checked: true} : {...item, checked: false}
        )
        const updatedSelectedItems = {...selectedItems, qnsFrequencies: frequencyUpdatedItems}
        setSelectedItems(updatedSelectedItems);
    };

    const handleToggleEdit = (val) => {
        trackCtaEvent('ProductPreferenceSection', val === '' ? 'Cancel' : 'Edit')
        setSelectedItems(optedQnsSettings)
        setMoreOptions(false)
        toggleEdit && toggleEdit(val)
    };

    const handleReset = () => {
        setSelectedItems(optedQnsSettings)
        setMoreOptions(false)
        toggleEdit(true)
    };

    const handleDocumentOptionsClick = () => {
        moreOptions ? setMoreOptions(false) : setMoreOptions(true);
    };

    const notesChange = () => {
        setSelectedItems(optedQnsSettings.map(ob => {
            return {...ob, checked: false}
        }));
    };

    return <>
        {apiError && <ErrorPage></ErrorPage>}

        {<Flex column>
            {isLoading && <Loading/>}
            <>
                <ViewEditProductPreferences type={type} content={PreferencesConfig[type]} isEdit={isEdit}
                                            selectedItems={selectedItems}
                                            toggleCheckbox={toggleCheckbox}
                                            notesChange={notesChange}
                                            handleReset={handleReset}
                                            disableSave={disableSave}
                                            handleSave={save}
                                            handleToggleEdit={handleToggleEdit}
                                            toggleRadioButton={toggleRadioButton}
                                            moreOptions={moreOptions}
                                            handleDocumentOptionsClick={handleDocumentOptionsClick}
                                            handleAllDocumentsCheckbox={handleAllDocumentsCheckbox}
                                            removeSubscriptionHandler={removeSubscriptionHandler}
                                            initialQnsSettings={optedQnsSettings}
                                            subscriptions={products}
                                            isDataLoading={isDataLoading}

                />
            </>
        </Flex>}
    </>
};

