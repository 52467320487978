import React from 'react';
import styled from "styled-components";
import {Flex, Text} from "@qc-modules/components";
import {device} from "../../../lib/breakpoints";

export const StyledFormFieldDiv = styled.div`
    margin: 20px 0;
`;

const StyledCheckboxLabel = styled(Text)`
    font-size: 14px;
    padding-left: 5px;
    @media ${device.tablet} {
        font-size: 16px;
    }
`;

const StyledLabelBox = styled(Flex)`
    padding: 2px 0;
    align-items: center;
`;

export const RegisterCheckboxLabels = ({children}) => (
    <StyledCheckboxLabel tag="p">{children}</StyledCheckboxLabel>
);

export const EmailHelpTextLabel = ()=> {
    return(
        <StyledLabelBox>
            Please enter your company email address
        </StyledLabelBox>
    )
};