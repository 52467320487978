import React, {Fragment, useContext, useEffect, useState} from "react";
import {Box} from "@qc-modules/components"
import Loading from "../util/Loading";
import {ProfileAccountDescription, ProfileHeaderRow} from "./ProfileControls";
import AppSwitcher from "./appSwitcher2/AppSwitcher";
import {CompanyInfoSection} from "./companyInfo/CompanyInfoSection";
import {PersonalInfoSection} from "./personalInfo/PersonalInfoSection";

import ErrorPage from "../common/ErrorPage";
import useProfile from "../../hooks/useProfile2";
import {EmailAndPasswordInfoSection} from "./emailAndPasswordInfo/EmailAndPasswordInfoSection";
import {isValidImpersonation} from "../util/helper";
import AppContext from "../../context/AppContext";

const Profile = React.memo((props) => {
    const profileStore = (props && props.store) ? props.store : useProfile();
    const {appStore} = useContext(AppContext);
    const previousPage = '/login';
    const impersonationStatus = appStore.impersonationStatus
    const [tracked, setTracked] = useState(false)

    useEffect(() => {
        let visitor = {}
        if (!tracked && profileStore.userData !== null && profileStore.userData !== undefined) {
            visitor = {
                'country_code': profileStore.userData.country_code ?? 'US',
                'org_id': profileStore.userData.org.orgId ?? '',
                'org_name': profileStore.userData.org.name ?? '',
                'qcguid': profileStore.userData.qcGuid ?? '',
                'user_name': profileStore.userData.username ?? '',
                'loggedIn': "logged_in",
                'user_domain': profileStore.userData.username && profileStore.userData.username.indexOf("@") > -1 ? profileStore.userData.username.slice(profileStore.userData.username.indexOf("@") + 1) : 'qualcomm.com'
            }
            if (isValidImpersonation(impersonationStatus)) {
                visitor['impersonating'] = true
                visitor['impersonator'] = impersonationStatus.impersonator
            }
            console.log(`Visitor = ${JSON.stringify(visitor)}`)
            window.pageChange('profile', 'Profile', previousPage, visitor, {})

            setTracked(true);
        }

    }, [profileStore, previousPage, impersonationStatus, tracked]);
    return (
        <Box>
            {profileStore &&
                <>
                    {profileStore.isLoading && <Loading/>}
                    {
                        profileStore.isProfileFailedToLoad
                            ?
                            <>
                                <ErrorPage/>
                            </>
                            :
                            (
                                <>
                                    {profileStore.userData && <Fragment>
                                        <ProfileHeaderRow label={'Profile'}/>
                                        <ProfileAccountDescription f={4} weight={2}/>
                                        <AppSwitcher userData={profileStore.userData}
                                                     applicationData={profileStore.userData.appDetails}/>
                                        <EmailAndPasswordInfoSection store={profileStore}/>
                                        <PersonalInfoSection store={profileStore}/>
                                        <CompanyInfoSection store={profileStore}/>
                                    </Fragment>
                                    }
                                </>
                            )
                    }
                </>
            }
        </Box>
    );
});

export default Profile;
