import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import AppContext from '../context/AppContext';
import authService from "../services/authService";
import Loading from "../components/util/Loading";
import {useMediaQuery} from "react-responsive";
import {device} from "../lib/breakpoints";


const ProtectedRoute = ({component: Component, ...rest}) => {
    const {auth, appStore} = useContext(AppContext);
    const isLaptop = useMediaQuery({query: device.laptop});

    const internalUserDefaultRoute = '/profile'
    const internalUserRestrictedRoutes = ['']

    const {setReturnUrl, redirectToTarget, isRedirect, internalRedirect, redirectObj} = {...rest};
    setReturnUrl && setReturnUrl(window.location.href);

    const forceToInternalUserPage = () => {
        const {path} = {...rest}
        const isUserLoggedIn = auth.user;
        if(isUserLoggedIn) {
            const isExternalUser = authService.isExternalUser(auth.user.username);
            return internalUserRestrictedRoutes.indexOf(path) > -1 && !isExternalUser
        }
    }
    return (
        <Route
            {...rest}
        render={props => {
            const activeTab = props.location.activeTab;
            if(activeTab) {
                if(isLaptop) {
                    appStore.setProfileTabPath(activeTab.desk);
                } else {
                    appStore.setProfileTabPath(activeTab.mobile);
                    appStore.setComponentPathInfo(activeTab.mobile);
                    appStore.setShowPanel('show')
                    appStore.setIsMobileNonHeaderView(true);
                }
                props.location.activeTab = null;
            }
                const token = auth.readTokenFromParams(props);
                if(token) {
                    auth.loginWithToken(token);

                    //set the search param bck to null or we will get into an endless loop
                    //because it will keep pulling the token off each time and keep going thru the process
                    props.location.search = null;
                    props.history.push(props.location);
                    if(forceToInternalUserPage()) {
                        return <Redirect
                            to={internalUserDefaultRoute}
                        />
                    }
                    if(isRedirect) {
                        redirectToTarget()
                        return <Loading {...props} />
                    } else if(internalRedirect) {
                        return redirectObj();
                    } else {
                        return <Component {...props} />;
                    }
                }

                if(auth.user) {
                    props.location.search = null;
                    if(forceToInternalUserPage()) {
                        return <Redirect
                            to={internalUserDefaultRoute}
                        />
                    }

                    if(isRedirect) {
                        redirectToTarget()
                        return <Loading {...props} />
                    } else if(internalRedirect) {
                        return redirectObj();
                    } else {
                        return <Component {...props} />;
                    }

                }

                return (
                    <Redirect
                        to={{
                            pathname: '/auth',
                            state: {from: props.location}
                        }}
                    />
                );
            }}
        />
    );
};

export default ProtectedRoute;
