import axios from "axios";

export const sendForgotPasswordEmail = async (props) => {
    let statusCode = "";

    try {
        const params = {
            method: 'post',
            url: '/api/password/reset',
            data: props
        };

        statusCode = await axios(params).then((response) => {
           // const success = response.data && response.data.email === props.email && response.status === 200;
            return response.status
        }).catch(error => {
            return (error && error.response && error.response.status) ? error.response.status : "SYSTEM_ERROR"

        });
    } catch (error) {
        statusCode = "SYSTEM_ERROR";
    }

    return statusCode
};

export const resetPassword = async (props) => {
    let statusCode = "";
    try {
        const postParams = {
            method: 'post',
            url: '/api/password/update',
            data: props
        };

        statusCode = await axios(postParams).then((response) => {
            const success = response.data && response.data.email === props.email && response.status === 200;
            return success ? 200 : "SYSTEM_ERROR";
        }).catch(function (error) {
            return (error.response && error.response.data && error.response.data.code) || (error.response && error.response.status) || "SYSTEM_ERROR"
        });
    } catch (error) {
        statusCode = "SYSTEM_ERROR";
    }


    return statusCode
};

export default {
    sendForgotPasswordEmail,
    resetPassword
}