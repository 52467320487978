import {useEffect, useState} from 'react';

import {requestWrapper} from '../components/util/helper'
import {qDomainRegex} from "../components/util/validations";

import {
    getCookie,
    getUrlParams,
    hexEncode,
    setCookie,
    setHashCookie,
    getUrlParameter, trackSignInCtaEvent
} from "../utils/Utils";
import {trackEventAdobe} from "../adobe/eventTrack";
// import history from "../context/History";

const useLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailDomainError, setEmailDomainError] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [tracked, setTracked] = useState(false);


    const loginFields = {email, password};
    const loginMethods = {setEmail, setPassword};
    const loginErrorFields = {emailError, passwordError, emailDomainError};
    const loginErrorMethods = {setEmailError, setPasswordError};


    useEffect(() => {
        const validateForm = () => {
            const isFormDirtyAndInvalid = [email, password].some(obj => {
                return obj === '';
            });

            if (isFormDirtyAndInvalid) {
                setIsFormInvalid(isFormDirtyAndInvalid)
            } else {
                setIsFormInvalid(Object.values(loginErrorFields).some(obj => {
                    return obj
                }))
            }
        };

        //validate the form each time the state values changes for form fields
        validateForm()
    }, [email, password, loginErrorFields]);

    const processErrorMessage = () => {
        const cookieName = "loginAttempted";
        const cookie = getCookie(cookieName);
        if (cookie) {
            if (!tracked) {
                trackEventAdobe({'event': 'account_failed_login'});
                setTracked(true);
            }
            setLoginFailed(cookie);
        }
    };

    useEffect(() => {
        processErrorMessage()
        // eslint-disable-next-line
    }, [emailDomainError, loginFailed]);

    const setEnvironmentBasedUrls = () => {
        let loginDomain;
        loginDomain = `${process.env.REACT_APP_SM_URL}`;
        document.getElementsByName("login")[0].setAttribute("action", loginDomain + "/stepuplogin/shim.fcc");
    };

    useEffect(() => {
        //validate the form each time the state values changes for form fields
        setEnvironmentBasedUrls()
    }, []);


    const login = requestWrapper(async () => {
        const params = getUrlParams();
        setTracked(false);
        trackSignInCtaEvent('Sign in', 'Login')
        if (params.TARGET) {
            setHiddenVariables(params);
            setCookie("loginAttempted", "1", "40");
            setHashCookie();
            // trackEventAdobe({'event': 'myaccount_login'})
            document.getElementsByName("login")[0].submit()
        } else {
            // trackEventAdobe({'event': 'myaccount_login'})
            window.location.href = '/profile';
            // history.push("/profile")

        }
    });


    const setHiddenVariables = (params) => {
        let info = {};
        info["Password"] = hexEncode(document.getElementById('PASSWORD_INPUT').value);
        document.getElementsByName('USER')[0].value = document.getElementById('email').value.toLowerCase();
        document.getElementsByName('PASSWORD')[0].value = JSON.stringify(info);
        document.getElementsByName('TARGET')[0].value = (params.TARGET);
        document.getElementsByName('smagentname')[0].value = decodeURIComponent(params.SMAGENTNAME);
    };

//?TARGET=$SM$HTTPS%3a%2f%2fmyaccount-tst%2equalcomm%2ecom%2fstatic%2fregistration%2fwelcome%2ehtml&SMAGENTNAME=$SM$U2HE%2fLuhsdXVq5U0BI1JpFjrRbnEGRSrMxu8pKL%2bk%2bVvJy4uyNdOYVrVx9iVswy3


    const verifyDomain = () => {
        const domainError = email && email.indexOf("@") > -1 ? qDomainRegex.test(email) : false;
        setEmailDomainError(domainError);
    };

    const updateStateAndValidateField = (value, fieldProps) => {

        const {updateState} = fieldProps;
        updateState(value);
        // const errors = validateField(value, fieldProps);
    };
    const getTargetUrl = () => {
        let target = ""
        let decodedUrl = decodeURIComponent(window.location.href)
        let openIdTarget = getUrlParameter(" redirect_uri", decodedUrl) || getUrlParameter("redirect_uri", decodedUrl)
        let smTarget = getUrlParameter("TARGET", decodedUrl) || getUrlParameter(" TARGET", decodedUrl)
        if (openIdTarget) {
            //consider its openId+SM
            openIdTarget = replaceAnyOpenIdJunk(openIdTarget)
            target = openIdTarget.replace(/--/g, "-").toLowerCase()
        } else if (smTarget) {
            //consider its openID . pick target
            target = smTarget.replace("$SM$", "").toLowerCase();
        }
        return target
    }
    const replaceAnyOpenIdJunk = (targeturl) => {
        let result = targeturl
        const openIdIndex = targeturl.indexOf("openid")
        if (openIdIndex > -1) {
            result = targeturl.substring(0, openIdIndex)
        }
        return result
    }
    return {
        ...loginFields, ...loginMethods, ...loginErrorFields, ...loginErrorMethods,
        isFormInvalid, verifyDomain, emailDomainError, loginFailed,
        actionCallBack: updateStateAndValidateField,
        login,
        targetUrl: getTargetUrl()
    }

};

export default useLogin;
