import styled from 'styled-components';
import {device} from '../../../lib/breakpoints'
import {Button} from "@qc-modules/components";
import {colors} from "@qc-modules/styles";
export const MobileShieldContainer = styled.div`
    grid-column: 2/3;
    grid-row: 3/5;
    
     > img {
        width: 48px;
     }
     
     @media ${device.tablet} {
        grid-column: 3/5;
     }
     
      @media ${device.laptop} {
          display: none;
      }
`;


export const MobileTitleContainer = styled.div`
    font-size: 22px;
    word-break: break-word;
    font-weight: 400;
    color: white;
    letter-spacing: 0.55px;
        grid-column: 3/7;
        grid-row: 3/4;
        order: 1;
        
     @media ${device.tablet} {
        grid-column: 3/8;
        margin-left: 80px;
     }
     
     @media ${device.laptop} {
       display:none;
     }
`;


export const MobileEmailContainer = styled.div`
    font-size: 14px;
    white-space: nowrap;
    font-weight: 400;
    color: #ffffff;
  
        grid-column: 3/7;
        grid-row: 4/5;
        
    @media ${device.tablet} {
        grid-column: 3/8;
        margin-left: 80px;
     }
     
     @media ${device.laptop} {
        display:none;
     }
`;


export const MobileLogoutButton = styled(Button)`
   color: ${colors("white")};
   cursor: pointer;
   font-size: 16px;
   line-height: 2;

    align-self: center;
    justify-self: end;
   
   grid-column: 7/8;
   grid-row: 1/2;
   
   width: max-content;
   
   @media ${device.tablet} {
   grid-column: 8/9;
   
   }
   
   @media ${device.laptop} {
  display: none;
   }
`;

export const MobileLogoContainer = styled.div`
    flex-basis: 100%;
    background-color: #3253DC;
    display: block;
    
    grid-column: 2/4;
    grid-row: 1/2;
    align-self: center;
    
     @media ${device.tablet} {
        grid-column: 3/5;
        
     }
    
    @media ${device.laptop} {
        display:none;
    }
`;
