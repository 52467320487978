import React from "react"
import styled from "styled-components"
import {bool, func, string} from "prop-types"
import {transitionEase} from "@qc-modules/styles"
import {Primitives} from "@qc-modules/components"
import {flexbox} from "styled-system"

import {defaultFontSize, getTextColor, fontPropType} from "./utils"

const cursor = ({disabled}) =>
    disabled ? `cursor: default;` : `cursor: pointer;`

const RadioLabel = styled(Primitives.Text)`
  ${cursor};
  ${({disabled}) => (disabled ? `opacity: 0.6;` : null)};
  position: relative;
  ${flexbox};
`

const RadioContainer = styled(Primitives.Flex)`
  vertical-align: baseline;
  ${transitionEase("0.3s", ["background", "border"])};
`

export const RadioSwitch = styled(Primitives.Box)``

const RadioInput = styled(Primitives.Input)`
  ${cursor};
  opacity: 0;
  position: absolute;
`

const propTypes = {
    /** Bool to set radio to disabled */
    disabled: bool,
    /** Bool to display as error */
    error: bool,
    /** Object to set font settings */
    fontObject: fontPropType,
    /** String name of radio group */
    groupName: string,
    /** String name of radio id */
    id: string,
    /** Bool to set as checked */
    isChecked: bool,
    /** String for radio label */
    label: string,
    /** Function for on change handler */
    onChange: func,
    /** Bool to display as success */
    success: bool,
    /** String for radio value */
    value: string
}

const RadioButton = ({
                         isChecked,
                         disabled,
                         error,
                         fontObject = defaultFontSize,
                         id,
                         onChange,
                         label,
                         groupName,
                         success,
                         value
                     }) => (
    <RadioLabel
        color={getTextColor({error, success})}
        disabled={disabled}
        display="flex"
        htmlFor={id}
        mr={'48px'}
        tag="label"
        {...fontObject}
    >
        <RadioContainer
            alignItems="center"
            backgroundColor={isChecked ? null : "background"}
            border="1px solid"
            borderColor={isChecked && !disabled ? "background-selected" : "border"}
            borderRadius="50%"
            display="inline-flex"
            flex="0 0 auto"
            height={16}
            justifyContent="center"
            mr={1}
            width={16}
        >
            {isChecked ? (
                <RadioSwitch
                    backgroundColor={
                        disabled ? "background-light" : "background-selected"
                    }
                    borderRadius="50%"
                    height={10}
                    width={10}
                />
            ) : null}
        </RadioContainer>
        <RadioInput
            checked={isChecked}
            disabled={disabled}
            id={id}
            name={groupName}
            onChange={onChange}
            type="radio"
            value={value}
        />
        {label && (
            <Primitives.Box as="span" display="inline-block">
                {label}
            </Primitives.Box>
        )}
    </RadioLabel>
)

RadioButton.propTypes = propTypes
RadioButton.displayName = "Modules.RadioButton"

export default RadioButton
