import React, {Fragment, useContext} from 'react';
import ApplicationSlider from "./ApplicationSlider";
import {trackCtaEvent} from "../../../utils/Utils";
import AppContext from "../../../context/AppContext";
import {isValidImpersonation} from "../../util/helper";

const AppSwitcher = ({
                         userData, applicationData, gridHandler = () => {
    }
                     }) => {
    const {appStore} = useContext(AppContext);


    const afterChangeHandler = (current) => {
        gridHandler(current, applicationData.length)
    };

    const clickHandler = (url, app) => {
        if (!isValidImpersonation(appStore.impersonationStatus)) {
            trackCtaEvent(url, app)
            window.open(url);
        }

    };

    return (<Fragment>

        {applicationData && <ApplicationSlider items={applicationData} clickHandler={clickHandler}
                                               afterChangeHandler={afterChangeHandler}/>}
    </Fragment>)
};

export default AppSwitcher;
