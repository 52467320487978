import React from 'react';
import {
    defaultLabelFont,
    emptyCheck,
    ProfileDataRowFlex,
    ProfileMultiRowFlex,
    StyledText,
    SubTextRow,
    TextRow
} from "../ProfileControls";
import {Box, Text} from "@qc-modules/components";
import {defaultValueFont} from "../../util/helper";
import {ErrorMessage, Field} from "formik";
import {WrappedFormComponents} from "../../util/FormComponents/FormikWrappedComponents";
import {MessageWrapper} from "../../util/validation/ValidationStyles";
import {ValidationFunctions} from "../../util/validation/ValidationFunctions";

const PersonalInfoFormFields = ({...props}) => {
    const {isEditMode, isFormSubmitted} = props;
    return (
        <>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}>First name</TextRow>
                </Box>

                <Box width={0.55}>
                    <StyledText data-testid={'firstName-label'} {...defaultValueFont}>
                        {emptyCheck(props.values.firstName)}
                    </StyledText>
                </Box>
            </ProfileDataRowFlex>


            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}>Last name</TextRow>
                </Box>

                <Box width={0.55}>
                    <StyledText data-testid={'lastName-label'} {...defaultValueFont}>
                        {emptyCheck(props.values.lastName)}
                    </StyledText>
                </Box>
            </ProfileDataRowFlex>


            <ProfileMultiRowFlex>
                <Box mr={2} width={3.5 / 8}>
                    <Text color="#677284" {...defaultLabelFont} mr={2}>Phone numbers(s)</Text>
                </Box>
                <Box>
                    <ProfileDataRowFlex>
                        <Box width={0.45}>
                            <SubTextRow as={"p"} {...defaultLabelFont}
                                        required={props.requiredAsterisk}>Mobile:
                            </SubTextRow>
                        </Box>

                        <Box width={0.55}>
                            {isEditMode ? (<> <Field name="mobileNumber"
                                                     id="mobileNumber"
                                                     placeholder="Mobile Phone"
                                                     label='Mobile Phone'
                                                     labelPosition='placeholder'
                                                     type='text'
                                                     width={1}
                                                     component={WrappedFormComponents.InputWrapper}
                                                     validate={ValidationFunctions.validateMobileNum}
                                    />

                                        {isFormSubmitted &&
                                        <MessageWrapper><ErrorMessage name="mobileNumber"/></MessageWrapper>}
                                    </>

                                )
                                : <StyledText data-testid={'mobileNumber-label'} {...defaultValueFont}>
                                    {emptyCheck(props.values.mobileNumber)}
                                </StyledText>
                            }

                        </Box>
                    </ProfileDataRowFlex>
                    <ProfileDataRowFlex>
                        <Box width={0.45}>
                            <SubTextRow as={"p"} {...defaultLabelFont}
                                        required={props.requiredAsterisk}>Office:
                            </SubTextRow>
                        </Box>

                        <Box width={0.55}>
                            {isEditMode ? (<><Field name="telephoneNumber"
                                                    id="telephoneNumber"
                                                    placeholder="Office Phone"
                                                    label='Office Phone'
                                                    labelPosition='placeholder'
                                                    type='text'
                                                    width={1}
                                                    component={WrappedFormComponents.InputWrapper}
                                                    validate={ValidationFunctions.validateOfficeNum}
                                    />

                                        {isFormSubmitted &&
                                        <MessageWrapper><ErrorMessage name="telephoneNumber"/></MessageWrapper>}
                                    </>

                                )
                                : <StyledText data-testid={'officePhone-label'} {...defaultValueFont}>
                                    {emptyCheck(props.values.telephoneNumber)}
                                </StyledText>}

                        </Box>
                    </ProfileDataRowFlex>
                </Box>
            </ProfileMultiRowFlex>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}>Job title</TextRow>
                </Box>

                <Box width={0.55}>
                    {isEditMode ? (<> <Field name="jobTitle"
                                             id="jobTitle"
                                             placeholder="Job Title"
                                             label='Job Title'
                                             labelPosition='placeholder'
                                             type='text'
                                             width={1}
                                             component={WrappedFormComponents.InputWrapper}
                                             validate={ValidationFunctions.validateJobTitle}
                            />

                                {isFormSubmitted && <MessageWrapper><ErrorMessage name="jobTitle"/></MessageWrapper>}
                            </>

                        )
                        : <StyledText data-testid={'jobTitle-label'} {...defaultValueFont}>
                            {emptyCheck(props.values.jobTitle)}
                        </StyledText>}
                </Box>
            </ProfileDataRowFlex>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}>Job function</TextRow>
                </Box>

                <Box width={0.55}>
                    {isEditMode ? <Field name="jobFunction"
                                         id="jobFunction"
                                         placeholder="Job function"
                                         label='Job function'
                                         labelPosition='placeholder'
                                         type='text'
                                         options={props.jobFunctions}
                                         width={1}
                                         component={WrappedFormComponents.SelectWrapper}

                    /> : <StyledText data-testid={'jobFunction-label-old'} {...defaultValueFont}>
                        {emptyCheck(props.values.jobFunction)}
                    </StyledText>}

                </Box>
            </ProfileDataRowFlex>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}>Forum alias</TextRow>
                </Box>

                <Box width={0.55}>
                    {isEditMode ? (<><Field name="forumAlias"
                                            id="forumAlias"
                                            placeholder="Forum alias"
                                            label='Forum alias'
                                            labelPosition='placeholder'
                                            type='text'
                                            width={1}
                                            component={WrappedFormComponents.InputWrapper}
                                            validate={ValidationFunctions.validateForumAlias}
                            />
                                {isFormSubmitted && <MessageWrapper><ErrorMessage name="forumAlias"/></MessageWrapper>}
                            </>

                        )
                        :
                        <StyledText data-testid={'forumAlias-label'} {...defaultValueFont}>
                            {emptyCheck(props.values.forumAlias)}
                        </StyledText>
                    }

                </Box>
            </ProfileDataRowFlex>
        </>
    )
};

export default PersonalInfoFormFields;
