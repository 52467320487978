import styled from 'styled-components';
import {Flex, Text} from "@qc-modules/components";
import {device} from "../../../lib/breakpoints";

export const FooterLinkContainer = styled(Flex)`
    justify-content: flex-start;
    margin-bottom: 10px;
    color: #677284 !important;
    
    @media ${device.mobile} {
        max-width: 550px;
    }
    
    @media ${device.laptop} {
        grid-column: 2/4;
    }
    
    @media ${device.tablet} {
        grid-column: 2/8;
        grid-row: 2/3;
    }
`;

export const StyledFooterCopyrightContainer = styled.div`
    color: #BBC0C8;
    opacity: 1;    
    font-size: 12px;
    margin-bottom: 10px;
`;


export const FooterLink = styled(Text)`
    text-decoration: none;
    opacity: ${props => props.opacity}
    color: #677284 !important;
    
    &:visited {
        color: #677284 !important;
        text-decoration: none;
    };
    
    &:hover {
        color: #222 !important;
    };
    
    &:not(:last-child) {
        margin-right: 10px;
    }
    
    font-size: 12px;
        
    @media ${device.mobile} {
        &:not(:last-child) {
            margin-right: 24px;
        }
    }
    
    @media ${device.laptop} { 
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
`;
