import React from 'react';
import {
    defaultLabelFont,
    emptyCheck,
    ProfileDataRowFlex,
    ProfileEmailDescription,
    StyledText,
    TextRow
} from "../ProfileControls";
import {Box,} from "@qc-modules/components";
import {defaultValueFont, LinkBox} from "../../util/helper";
import {HelpText, ProfileUsageLink} from "../HelperComponents";
import {ErrorMessage, Field} from "formik";
import {WrappedFormComponents} from "../../util/FormComponents/FormikWrappedComponents";
import {MessageWrapper, StyledErrorMessage} from "../../util/validation/ValidationStyles";
import {trackCtaEvent} from "../../../utils/Utils";

const EmailAndPasswordInfoFormFields = ({...props}) => {
    const {isEditMode} = props;
    return (
        <>
            {isEditMode && <Box><ProfileEmailDescription/></Box>}

            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}>Email</TextRow>
                </Box>

                <Box width={0.55}>
                    <StyledText data-testid={'email-label'} {...defaultValueFont}>
                        {emptyCheck(props.formValues.email)}
                    </StyledText>
                </Box>
            </ProfileDataRowFlex>

            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <TextRow align={'left'} {...defaultLabelFont}>Password</TextRow>
                </Box>
                <Box width={0.55}>
                    {isEditMode ? <><Field name="currentPassword"
                                           id="currentPassword"
                                           placeholder="Current password"
                                           label='Current Password'
                                           labelPosition='placeholder'
                                           type='password'
                                           onChange={(e) => {
                                               props.handleChange(e);
                                               props.setStatus({isEditMode: true})
                                           }}
                                           width={1}
                                           component={WrappedFormComponents.InputWrapper}

                        />
                            <ProfileUsageLink align='center' mt={20} mb={10}>
                                <Box/>
                                <LinkBox width={0.55} to={'/forgotPassword'} onClick={() => {
                                    trackCtaEvent('/resetPassword', 'Forgot password?')
                                }}> {'Forgot password?'}</LinkBox>
                            </ProfileUsageLink>
                        </>
                        : <StyledText type='password' data-testid={'jobFunction-label'} {...defaultValueFont}>
                            {emptyCheck("********")}
                        </StyledText>}
                    {<MessageWrapper>
                        <StyledErrorMessage>
                            <ErrorMessage name="currentPassword"/>
                        </StyledErrorMessage>
                    </MessageWrapper>}

                </Box>
            </ProfileDataRowFlex>
            {isEditMode &&

                <ProfileDataRowFlex>
                    <Box width={0.45}>
                    </Box>
                    <Box width={0.55}>
                        <><Field name="newPassword"
                                 id="newPassword"
                                 placeholder="New password"
                                 label='New Password'
                                 labelPosition='placeholder'
                                 type='password'
                                 onChange={(e) => {
                                     props.handleChange(e);
                                     props.setStatus({isEditMode: true})
                                 }}
                                 width={1}
                                 component={WrappedFormComponents.InputWrapper}
                        />
                            <HelpText tag={'span'}>
                                Password must be at least 8 characters, contain at least one uppercase letter, contain
                                at
                                least one
                                lowercase letter and at least one number.
                            </HelpText>
                            {<MessageWrapper>
                                <StyledErrorMessage>
                                    <ErrorMessage name="newPassword"/>
                                </StyledErrorMessage>
                            </MessageWrapper>}

                        </>

                    </Box>
                </ProfileDataRowFlex>}
            {isEditMode && <ProfileDataRowFlex>
                <Box width={0.45}>
                </Box>
                <Box width={0.55}>
                    <><Field name="confirmPassword"
                             id="confirmPassword"
                             placeholder="Verify Password"
                             label='Verify Password'
                             labelPosition='placeholder'
                             type='password'
                             width={1}
                             onChange={(e) => {
                                 props.handleChange(e);
                                 props.setStatus({isEditMode: true})
                             }}
                             component={WrappedFormComponents.InputWrapper}
                    />
                        {<MessageWrapper>
                            <StyledErrorMessage>
                                <ErrorMessage name="confirmPassword"/>
                            </StyledErrorMessage>
                        </MessageWrapper>}

                    </>

                </Box>
            </ProfileDataRowFlex>}

        </>
    )
};

export default EmailAndPasswordInfoFormFields;
