import React from 'react';
import TokenRequestForm from "./TokenRequestForm";
import {ComponentTitle} from "../../util/helper";
import {getUrlParams} from "../../../utils/Utils";


const TokenRequest = () => {
    const urlParams = () => {
        const params = getUrlParams();

        return {
            app: params.app || '',
            targetUrl: params.target || '',
            email: params.email || ''
        }
    };

    const assembleHeading = () => {
        return (
            <ComponentTitle testid='registration-header'
                            text={`Your registration request has expired, please request a new confirmation email so we can reset your registration request.​`}/>
        )
    };

    return (
        <>
            {assembleHeading()}
            <TokenRequestForm app={urlParams().app} email={urlParams().email} targetUrl={urlParams().targetUrl}/>
        </>
    )
};

export default TokenRequest;