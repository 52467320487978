import React from 'react';
import styled from 'styled-components';
import {Text} from "@qc-modules/components";
import {
    colors,
    themeTransparentize,
    transitionEase
} from "@qc-modules/styles";
import {device} from "../../../lib/breakpoints";

const StyledCard = styled.div`
    border-radius: 12px;
    cursor: pointer;
    ${transitionEase("0.7s", ["box-shadow", "transform"])};
    
    background-color: ${props => colors("background", props)};
    
    box-shadow: rgba(74, 90, 117, 0.08) 0px 4px 16px 0px, rgba(74, 90, 117, 0.16) 0px 2px 4px 0px;
    
    &:hover {
        box-shadow: rgba(74, 90, 117, 0.08) 0px 2px 10px 0px, rgba(74, 90, 117, 0.24) 0px 20px 20px -12px;
        ${props => themeTransparentize(0.6, "border")(props)};
    }
    
    transform-style: preserve-3d;
    backface-visibility: hidden;
    position: relative;
    z-index: 1;
    padding: 10px;
    
    height: 208px;
    max-height: 300px;
    min-width: 238px;
    margin-bottom: 24px;
    margin-top: 24px;
    margin-right: 12px;
    margin-left: 12px;
    
    @media ${device.tablet} {
        height: 240px;
        max-width: 360px;
     }
    
    @media ${device.laptop} {
        height: 280px;
        max-width: 300px;
    }
    
    @media ${device.jumbo} {
        height: 220px;
    }
`;


const CardTitle = styled(Text)`
    color: ${props => colors("blue", props)};
    letter-spacing: 0.3px;
    margin-bottom: 8px;
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 32px;
    @media ${device.mobile} {
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;

    }
    @media ${device.laptop} {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;

    }
`;

const CardDescription = styled(Text)`
    color: ${props => colors("body-grey", props)};
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 18px;
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 32px;
`;

const Card = ({title, description, onClick}) => {
    return (

        <StyledCard onClick={onClick}
                    align="flex-start"
                    direction="column"
                    justify="flex-start"
        >
            <CardTitle as="h5">{title}</CardTitle>
            <CardDescription as="p">{description}</CardDescription>
        </StyledCard>
    )
};

export default Card;
