import React from 'react';
import {validations} from "./ValidationSchema";
import {PASSWORD_VALIDATION_MESSAGES} from "./PasswordValidationConstants";
import {getInvalidGlyph, getValidGlyph, StyledErrorMessage, StyledValidMessage,} from "./ValidationStyles";

const validateEmail = (value) => {
    const data = {email: value};

    try {
        validations.emailSchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
};

const validateTermsOfService = (value) => {
    const data = {termsOfService: value};

    try {
        validations.termsOfServiceSchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
};

const validateFirstName = (value) => {
    const data = {firstName: value};

    try {
        validations.firstNameSchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
};

const validateLastName = (value) => {
    const data = {lastName: value};

    try {
        validations.lastNameSchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
};

const validateCountry = (value) => {
    const data = {country: value};

    try {
        validations.countrySchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
};

const validateExportId = (value) => {
    const data = {exportId: value};

    try {
        validations.exportIdSchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
};

const validatePassword = (value) => {
    const data = {password: value};

    try {
        validations.passwordSchema.validateSync(data, {abortEarly: false});
    } catch (err) {
        let validMessages = [];
        if (value) {
            const validFields = PASSWORD_VALIDATION_MESSAGES.filter((messageItem) => {
                    return err.errors.indexOf(messageItem.name) < 0 && messageItem.valid !== ''
                }
            );

            validMessages = validFields.map((validMessage) => {
                return (
                    <StyledValidMessage key={validMessage.name}
                                        order={validMessage.order}>{getValidGlyph()} {validMessage.valid}</StyledValidMessage>
                )
            });
        }

        const invalidMessages = err.errors.map((errorMessage) => {
            const invalidMessage = PASSWORD_VALIDATION_MESSAGES.find((messageItem) => {
                return messageItem.name === errorMessage
            });

            return (
                <StyledErrorMessage key={invalidMessage.name}
                                    order={invalidMessage.order}>{getInvalidGlyph()} {invalidMessage.invalid}</StyledErrorMessage>
            )
        });

        const validationMessages = validMessages.concat(invalidMessages);
        return validationMessages.sort((a, b) => (a.props.order > b.props.order) ? 1 : -1);
    }
};

export const validateInternalEmailDomains = (value) => {
    const internalDomains = ["quicinc.com", "qualcomm.com", "qti.qualcomm.com", "qca.qualcomm.com"];
    return !internalDomains.some(domain => value.toLowerCase().includes(domain.toLowerCase()));
};

export const validateDomainsListForPartnerDomains = (value, domainsList) => {
    return !domainsList.some(domain => new RegExp(domain, 'i').test(value));
};

const validateAddressLineTwo = (value) => {
    const data = {addressLine2: value};

    try {
        validations.validateAddressLineTwoSchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validateAddressLineOne = (value, props) => {
    const data = {addressLine1: value};

    try {
        validations.validateAddressLineOneSchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validateCity = (city) => {
    try {
        validations.validateCountrySchema.validateSync({city});
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validateUsState = (state) => {
    try {
        validations.validateUsStateSchema.validateSync({state});
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validateNonUsState = (state) => {
    try {
        validations.validateNonUsStateSchema.validateSync({state});
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validatePostalCode = (postalCode) => {
    try {
        validations.validatePostalCodeSchema.validateSync({postalCode});
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validateMobileNum = (mobileNumber) => {
    try {
        validations.validateMobileSchema.validateSync({mobileNumber});
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validateOfficeNum = (telephoneNumber) => {
    try {
        validations.validateOfficeSchema.validateSync({telephoneNumber});
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validateForumAlias = (forumAlias) => {
    try {
        validations.validateForumAliasSchema.validateSync({forumAlias});
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validateJobTitle = (value) => {
    const data = {jobTitle: value};
    try {
        validations.validateJobTitleSchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

const validateCompany = (value) => {
    const data = {orgExportId: value};
    try {
        validations.validateCompanySchema.validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{err.message}</StyledErrorMessage>
    }
};

export const ValidationFunctions = {
    validateEmail: validateEmail,
    validateTermsOfService: validateTermsOfService,
    validateFirstName: validateFirstName,
    validateLastName: validateLastName,
    validateCountry: validateCountry,
    validateExportId: validateExportId,
    validatePassword: validatePassword,

    //company info
    validateAddressLineOne,
    validateAddressLineTwo,
    validateCity,
    validateUsState,
    validateNonUsState,
    validatePostalCode,

    //personal info
    validateMobileNum,
    validateOfficeNum,
    validateJobTitle,
    validateForumAlias,
    validateCompany
};