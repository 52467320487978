import React, {useEffect, useState} from 'react'
import {Box} from "@qc-modules/components";
import {LoginView} from './LoginView'
import {LoginFields} from './LoginFields'
import WebUiForm from "../util/WebUiForm";
import {addExtScript, getAdobeLaunchLibUrl, preHideAdobe} from "../../utils/Utils";
import usePreviousPage from "../../hooks/usePreviousPage";


const Login = () => {
    const blockData = LoginFields().loginFields;
    const selector = "adobescriptlogin";
    const previousPage = usePreviousPage();
    // eslint-disable-next-line
    let [cookie, setCookie] = useState(document.cookie.match(/^(?:.*;)?\s*OptanonConsent\s*=\s*([^;]+)(?:.*)?$/))

    useEffect(() => {
            preHideAdobe();
            const script = addExtScript(getAdobeLaunchLibUrl(), false, `#${selector}`, true, null);
            // trackPageLoadEvent('login', 'login', previousPage, {}, {})
            window.pageChange('login', 'login', previousPage, {}, {})
            // trackFormEvent('form view', 'Login', 2)
            return () => {
                if (document.querySelector(`#${selector}`)) {
                    document.querySelector(`#${selector}`).removeChild(script);
                }
            }

    }, [previousPage]);

    return (
        <Box>
            <div id={selector}/>
            <WebUiForm component={LoginView} blockData={blockData}/>
        </Box>
    )
};

export default Login

