import React from 'react';
import {defaultLabelFont, ProfileDataRowFlex} from "../ProfileControls";
import {Box, Text} from "@qc-modules/components";
import {ErrorMessage, Field} from "formik";
import {WrappedFormComponents} from "../../util/FormComponents/FormikWrappedComponents";
import {MessageWrapper, StyledErrorMessage} from "../../util/validation/ValidationStyles";
import {LinkBox} from "../../util/helper";
import {HelpText, ProfileUsageLink, RequiredTextRow} from '../HelperComponents';


const PasswordInfoFormFields = ({...props}) => {
    return (
        <>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <RequiredTextRow required align={'left'} {...defaultLabelFont}>Current password</RequiredTextRow>
                </Box>
                <Box width={0.55}>
                    {<><Field name="currentPassword"
                              id="currentPassword"
                              placeholder="Current password"
                              label='Current Password'
                              labelPosition='placeholder'
                              type='password'
                              onChange={(e) => {
                                  props.handleChange(e);
                                  props.setStatus({isEditMode: true})
                              }}
                              width={1}
                              component={WrappedFormComponents.InputWrapper}
                    />
                        {<MessageWrapper>
                            <StyledErrorMessage>
                                <ErrorMessage name="currentPassword"/>
                            </StyledErrorMessage>
                        </MessageWrapper>}
                    </>
                    }

                </Box>
            </ProfileDataRowFlex>
            <ProfileUsageLink align='center' mt={20} mb={10}>
                <Box width={0.45}></Box>
                <LinkBox width={0.55} to={'/forgotPassword'}> {'Forgot password?'}</LinkBox>
            </ProfileUsageLink>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <RequiredTextRow required align={'left'} {...defaultLabelFont}>New password</RequiredTextRow>
                </Box>
                <Box width={0.55}>
                    {<><Field name="newPassword"
                              id="newPassword"
                              placeholder="New password"
                              label='New Password'
                              labelPosition='placeholder'
                              type='password'
                              onChange={(e) => {
                                  props.handleChange(e);
                                  props.setStatus({isEditMode: true})
                              }}
                              width={1}
                              component={WrappedFormComponents.InputWrapper}
                    />
                        <HelpText tag={'span'}>
                            Password must be at least 8 characters, contain at least one uppercase letter, contain at
                            least one
                            lowercase letter and at least one number.
                        </HelpText>
                        {<MessageWrapper>
                            <StyledErrorMessage>
                                <ErrorMessage name="newPassword"/>
                            </StyledErrorMessage>
                        </MessageWrapper>}

                    </>}

                </Box>
            </ProfileDataRowFlex>
            <ProfileDataRowFlex>
                <Box width={0.45}>
                    <Text align={'left'} {...defaultLabelFont}>Verify password </Text>
                </Box>
                <Box width={0.55}>
                    {<><Field name="confirmPassword"
                              id="confirmPassword"
                              placeholder="Verify password"
                              label='Verify password'
                              labelPosition='placeholder'
                              type='password'
                              width={1}
                              onChange={(e) => {
                                  props.handleChange(e);
                                  props.setStatus({isEditMode: true})
                              }}
                              component={WrappedFormComponents.InputWrapper}
                    />
                        {<MessageWrapper>
                            <StyledErrorMessage>
                                <ErrorMessage name="confirmPassword"/>
                            </StyledErrorMessage>
                        </MessageWrapper>}

                    </>}

                </Box>
            </ProfileDataRowFlex>
        </>
    )
};

export default PasswordInfoFormFields;