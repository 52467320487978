import {useState} from "react";
import {profileConstants} from "../components/util/appConstants2";


const useAppStore = () => {
  const [profileTabPath, setProfileTabPath] = useState(profileConstants.APP_TABS_STATE.PROFILE_TAB.TAB_PATH);
  const [isMobileNonHeaderView, setIsMobileNonHeaderView] = useState(false);
  const [showPanel, setShowPanel] = useState('hide');
  const [componentPathInfo, setComponentPathInfo] = useState();
  const [disableEdit, setDisableEdit] = useState(false);//shared variable for all the sections
  const[isInternalUser, setIsInternalUser] = useState(false);
  const[prevLocation, setPrevLocation] = useState('');
  const[impersonationStatus, setImpersonationStatus] = useState();
  const [tabAccess, setTabAccess] = useState({
    [profileConstants.APP_TABS_STATE.PROFILE_TAB.ID]: true,
    [profileConstants.APP_TABS_STATE.ALERTS_TAB.ID]: true,
    [profileConstants.APP_TABS_STATE.PRODUCTS_TAB.ID]: false,
    [profileConstants.APP_TABS_STATE.PREFERENCES_TAB.ID]: true,
    [profileConstants.APP_TABS_STATE.DEACTIVATE_TAB.ID]: true,
    [profileConstants.APP_TABS_STATE.LOGOUT_TAB.ID]: true
  });



  return {
    profileTabPath, setProfileTabPath,
    isMobileNonHeaderView, setIsMobileNonHeaderView,
    showPanel, setShowPanel,
    componentPathInfo, setComponentPathInfo,
    tabAccess, setTabAccess,
    setDisableEdit,
    disableEdit,
    setIsInternalUser,
    isInternalUser,
    prevLocation, setPrevLocation,
    setImpersonationStatus,
    impersonationStatus

  }
};

export default useAppStore;
